import { AppUuid } from "./AppTypes";
import BaseResource from './BaseResource';
import { Paginated } from './common.types';
import { FavoriteUuid, IFavorite } from './Favorites.types';

export default class Favorites extends BaseResource {
    public readonly basePath = '/api/favorites';

    public fetch(): Promise<Paginated<IFavorite>> {
        return this.get<Paginated<IFavorite>>({ url: `/`, shouldAuthenticate: true });
    }

    public add(data: { app: AppUuid }): Promise<IFavorite<AppUuid>> {
        return this.post<IFavorite<AppUuid>>({ url: `/`, shouldAuthenticate: true, data });
    }

    public remove(favoriteUuid: FavoriteUuid): Promise<void> {
        return this.delete<void>({ url: `/${favoriteUuid}/`, shouldAuthenticate: true });
    }
}
