import {
    AppClient,
    BioLibAuthenticationError,
    BioLibPermissionError,
    BioLibSingleton,
    IAccountAsAdmin,
    IFilesDict,
    IJob,
    IJobPost,
    Inquiry,
    IUser,
    JobState,
    JobUuid,
} from '@biolibtech/biolib-js';
import { deviceDetect } from 'react-device-detect';
import { call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import config from '../../config';
import { eventChannel } from '../../sagas/initializationSaga';
import notificationActions from '../../state/notification/notificationActions';
import { IRootState } from '../../state/rootReducer';
import userConstants from '../../state/user/userConstants';
import { handleError } from '@utils';
import appRunSlice from './appRunSlice';
import {
    ErrorReportSendingState,
    IJobWrapper,
    JobStatus,
} from './types';
import getSortedAppVersion from './utils/getSortedAppVersion';
import prepareParametersAndFiles from './utils/prepareParametersAndFiles';
import { Action } from 'redux';
import { getIntrinsicAccount } from '../../state';
import { stringifyLogMessages } from './utils';

function* watchOpenApp() {
    yield takeLatest(appRunSlice.actions.openApp.type, function* (action) {
        if (appRunSlice.actions.openApp.match(action)) {
            try {
                const { app, selectedAppVersion, openInModal, license_token } = action.payload;
                const isSignedIn: boolean = yield select((state: IRootState) => state.user.isSignedIn);
                const hasAcceptedTerms: boolean =
                    yield select((state: IRootState) => state.appRun.hasGuestUserAcceptedTerms);

                if (!isSignedIn && !hasAcceptedTerms && !config.isTeamSubdomainPage()) {
                    yield put(appRunSlice.actions.openAppAwaitingAcceptTerms(action.payload));

                    yield race([
                        take(appRunSlice.actions.setHasGuestAcceptedTermsToTrue),
                        take(userConstants.SIGN_IN_SUCCESS),
                    ]);
                }

                const biolib = BioLibSingleton.get();
                const jobPostData: IJobPost = {
                    app_resource_name_prefix: app.resource_name_prefix,
                    app_version_id: selectedAppVersion.public_id,
                    client_type: 'biolib-frontend',
                    client_version: config.frontendVersion,
                };
                const job: IJob = yield biolib.job.create(jobPostData);

                yield put(appRunSlice.actions.openAppSuccess({
                    openInModal,
                    jobWrapper: {
                        app,
                        error: null,
                        errorReportSendingState: ErrorReportSendingState.notInitiated,
                        job: { ...job, app_version: getSortedAppVersion(job.app_version) },
                        logMessages: [],
                        streamedOutput: '',
                        progressCompute: 0,
                        progressInitialization: 0,
                        status: JobStatus.input,
                    }
                }));

                if (!isSignedIn && !hasAcceptedTerms) {
                    yield put(appRunSlice.actions.quitAppAwaitingAcceptTerms());
                }

            } catch (error) {
                if (error instanceof BioLibAuthenticationError || error instanceof BioLibPermissionError) {
                    yield call(handleError, {
                        error: new Error('You do not have a valid license to run this application.'),
                        actionToDispatch: appRunSlice.actions.openAppFailure,
                        friendlyErrorMessageToPrepend: '',
                        showToast: true,
                    });
                } else {
                    yield call(handleError, {
                        error,
                        actionToDispatch: appRunSlice.actions.openAppFailure,
                        friendlyErrorMessageToPrepend: 'Unable to open application',
                        showToast: true,
                    });
                }
            }
        }
    });
}

function updateJobStateNonBlocking(jobId: JobUuid, state: JobState) {
    const biolib = BioLibSingleton.get();
    biolib.job.update(jobId, { state }).then(() => {
        // no operation
    }).catch(() => {
        // no operation
    });
}

function* watchRunJob() {
    yield takeLatest(appRunSlice.actions.runJob.type, function* (action) {
        if (appRunSlice.actions.runJob.match(action)) {
            const { jobId, settingValues } = action.payload;
            try {
                const jobWrapper: IJobWrapper = yield select((state: IRootState) => state.appRun.jobWrapperDict[jobId]);
                const appVersion = jobWrapper.job.app_version;

                const files: IFilesDict = {};
                const parameters: string[] = [];
                yield prepareParametersAndFiles(appVersion.settings, settingValues, files, parameters);

                const addLogMessage = (message: string) => {
                    eventChannel.put(appRunSlice.actions.addLogMessage({ jobId, message }));
                };

                const addStreamingOutputMessage = (streamingOutputMessage: string) => {
                    eventChannel.put(appRunSlice.actions.addStreamingOutputMessage(
                        {
                            jobId,
                            streamingOutputMessage
                        }));
                };

                const setProgressInitialization = (progress: number) => {
                    if (progress >= 0 && progress <= 100) {
                        eventChannel.put(appRunSlice.actions.setProgressInitialization({
                            jobId,
                            progressInitialization: progress / 100,
                        }));
                    }
                };

                const setProgressCompute = (progress: number) => {
                    if (progress >= 0 && progress <= 100) {
                        eventChannel.put(appRunSlice.actions.setProgressCompute({
                            jobId,
                            progressCompute: progress / 100,
                        }));
                    }
                };

                const { quitJobAction, runJobResult } = yield race({
                    quitJobAction: take((action: Action) =>
                        appRunSlice.actions.quitJob.match(action) && action.payload.jobId === jobId
                    ),
                    runJobResult: call([AppClient, AppClient.runJob],
                        jobWrapper.job,
                        { files, parameters },
                        {
                            addLogMessage,
                            addStreamingOutputMessage,
                            setProgressCompute,
                            setProgressInitialization,
                        }
                    ),
                });

                if (runJobResult) {
                    yield put(appRunSlice.actions.runJobSuccess({ jobId }));
                } else if (quitJobAction) {
                    updateJobStateNonBlocking(jobId, JobState.cancelled);
                }
            } catch (error) {
                yield put(appRunSlice.actions.runJobFailure({
                    jobId,
                    error,
                    status: JobStatus.error,
                }));
            }
        }
    });
}

function* watchSendErrorReport() {
    const { actions } = appRunSlice;
    yield takeLatest(actions.sendErrorReport, function* (action) {
        if (actions.sendErrorReport.match(action)) {
            const { jobId } = action.payload;
            try {
                const biolib = BioLibSingleton.get();

                const account: IAccountAsAdmin | undefined = yield select(getIntrinsicAccount);
                const user: IUser | undefined = yield select((state: IRootState) => state.user.user);

                const { logMessages, error, app }: IJobWrapper = yield select(
                    (state: IRootState) => state.appRun.jobWrapperDict[jobId]
                );
                const jobLog = stringifyLogMessages(logMessages);
                // TODO: fix biolib errors such that toString() always should be used
                const errorMessage = error === null ? 'NULL' : error.stack ? error.stack : error.toString();

                const message = `[bl-error-report]

${location.origin}/${app.account_handle}/${app.name}/

${JSON.stringify(deviceDetect(undefined))}

____BEGIN_STACK_TRACE____

${errorMessage}

____END_STACK_TRACE____

____BEGIN_APPLICATION_LOG____

${jobLog}

____END_APPLICATION_LOG____`.replaceAll(location.origin, 'biolib_stack_base_url'); // Some email servers do not like links in text

                const errorReportData: Inquiry = {
                    message,
                    name: !account ? 'ANONYMOUS_USER' : account.account_handle,
                };

                if (user) {
                    errorReportData.email = user.email;
                }

                yield biolib.inquires.send(errorReportData);
                yield put(actions.sendErrorReportSuccess({ jobId }));
            } catch (error) {
                yield put(actions.sendErrorReportFailure({ jobId }));
                yield put(notificationActions.addError(`Failed to send error report\n${error.toString()}`));
            }
        }
    });
}

export default function* appRunSaga() {
    yield fork(watchOpenApp);
    yield fork(watchRunJob);
    yield fork(watchSendErrorReport);
}
