import { IJob } from '../BioLib';

export interface IRemoteOptions {
    biolibApiBaseUrl: string;
    job: IJob;
    moduleName: string;
    refreshToken?: string;
}

export interface IComputeNodeStatusUpdate {
    log_message?: string;
    progress?: number;
}

export interface IComputeNodeStatusResponse {
    status_updates: IComputeNodeStatusUpdate[];
    error_code?: number;
    stdout_and_stderr_packages_b64?: string[];
}

export const computeNodeSystemExceptionDict: Record<number, string> = {
    [1]: 'Failed to init compute process variables',
    [2]: 'Failed to connect to worker thread socket',
    [3]: 'Failed to start sender or receiver thread',
    [4]: 'Failed to get attestation document',
    [5]: 'Failed to create docker networks',
    [6]: 'Failed to start remote host proxies',
    [7]: 'Failed to redirect enclave traffic to proxies',
    [8]: 'Failed to create proxy container',
    [9]: 'Failed to configure allowed remote host',
    [10]: 'Failed to send status update',
    [11]: 'Failed to get required data for compute',
    [12]: 'Failed to start runtime zip download thread',
    [13]: 'Failed to download runtime zip',
    [14]: 'Failed to contact backend to create job',
    [15]: 'Failed to create new job',
    [16]: 'Failed to start image pulling thread',
    [17]: 'Failed to pull docker image',
    [18]: 'Failed to start compute container',
    [19]: 'Failed to copy input files to compute container',
    [20]: 'Failed to copy runtime files to compute container',
    [21]: 'Failed to run compute container',
    [22]: 'Failed to retrieve and map output files',
    [23]: 'Failed to serialize and send module output',
    [24]: 'Failed to deserialize job',
    [25]: 'Unknown Compute Process Error',
    [26]: 'Failed to initialize worker thread',
    [27]: 'Failed to handle package in worker thread',
    [28]: 'Job exceeded max run time',
    [29]: 'Container ran out of memory',
    [30]: 'Job was cancelled by the user',
};
