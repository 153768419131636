import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountUuid } from '@biolibtech/biolib-js';

export interface IAppCreateState {
    appCreateIsLoading: boolean;
}

const initialState: IAppCreateState = {
    appCreateIsLoading: false,
}

const appCreateSlice = createSlice({
    name: 'appCreate',
    initialState,
    reducers: {
        createApp(state, action: PayloadAction<{ uri: string; }>) {
            state.appCreateIsLoading = true;
        },
        createAppSuccess(state) {
            state.appCreateIsLoading = false;
        },
        createAppFailure(state, action: PayloadAction<string>) {
            state.appCreateIsLoading = false;
        },
    },
})

export default appCreateSlice;
