import { IFilesDict } from '../types';
import BioLibBinaryFormatBaseWithFiles from './BioLibBinaryFormatBaseWithFiles';
import CustomDataView from './CustomDataView';

export interface IModuleInput {
    files: IFilesDict;
    parameters: string[];
}

export default class ModuleInput extends BioLibBinaryFormatBaseWithFiles implements IModuleInput {
    protected readonly TYPE = 1;

    public files: IFilesDict;
    public parameters: string[];
    public stdin: Uint8Array;

    constructor(data: IModuleInput | Uint8Array) {
        super();
        const { files, parameters } = data instanceof Uint8Array ? this.deserialize(data) : data;
        this.files = files;
        this.stdin = new Uint8Array(0);
        this.parameters = parameters;
    }

    public getAttributes(): IModuleInput {
        return {
            files: this.files,
            parameters: this.parameters,
        };
    }

    public serialize(): Uint8Array {
        const argumentsSerialized: Uint8Array[] = [];

        let argumentDataByteLength = 0;
        for (const argument of this.parameters) {
            const argAsBytes = this.textEncoder.encode(argument);

            const argWithLength = new Uint8Array(2 + argAsBytes.length);
            new CustomDataView(argWithLength.buffer).setUint16(0, argAsBytes.length);
            argWithLength.set(argAsBytes, 2);
            argumentDataByteLength += argWithLength.length;

            argumentsSerialized.push(argWithLength);
        }

        const filesByteLength = this.getFilesSerializedLength(this.files);

        const headerByteLength = 1 + 1 + 8 + 4 + 8;
        const fullByteLength =
            headerByteLength + this.stdin.length + argumentDataByteLength + filesByteLength;

        const serializedData = new Uint8Array(fullByteLength);
        const dataView = new CustomDataView(serializedData.buffer);

        dataView.setUint8(0, this.VERSION);
        dataView.setUint8(1, this.TYPE);
        dataView.setBigUint64(2, BigInt(this.stdin.length));
        dataView.setUint32(10, argumentDataByteLength);
        dataView.setBigUint64(14, BigInt(filesByteLength));

        serializedData.set(this.stdin, 22);

        let argBytesPosition = headerByteLength + this.stdin.length;

        for (const arg of argumentsSerialized) {
            serializedData.set(arg, argBytesPosition);
            argBytesPosition += arg.length;
        }

        const filesStartPosition = headerByteLength + this.stdin.length + argumentDataByteLength;
        this.serializeFilesIntoByteArray(this.files, serializedData, filesStartPosition);
        return serializedData;
    }

    private deserialize(serialized: Uint8Array): IModuleInput {
        const dataView = new CustomDataView(serialized.buffer, serialized.byteOffset, serialized.byteLength);
        this.assertVersionAndType(dataView);

        let pointer = 2;
        const stdinLength = Number(dataView.getBigUint64(pointer));
        pointer += 8;

        const parameterDataByteLength = dataView.getUint32(pointer);
        pointer += 4;

        const filesDataByteLength = Number(dataView.getBigUint64(pointer));
        pointer += 8;

        const stdin = serialized.slice(pointer, pointer + stdinLength);
        pointer += stdinLength;

        const parameters: string[] = [];

        const parametersEndLocation = pointer + parameterDataByteLength;

        while (pointer < parametersEndLocation) {
            const parameterLength = dataView.getUint16(pointer);
            pointer += 2;
            const parameter = this.textDecoder.decode(serialized.slice(pointer, pointer + parameterLength));
            parameters.push(parameter);
            pointer += parameterLength;
        }

        const files = this.deserializeFiles(serialized, pointer, filesDataByteLength);

        return { parameters, files };
    }
}
