import { Reducer } from "redux";
import { RootActions } from "../rootActions";
import contactConstants from "./contactConstants";

export interface IContactState {
    isSubmitting: boolean;
    isSubmitted: boolean;
}

const defaultState: IContactState = {
    isSubmitting: false,
    isSubmitted: false
};

export const contactReducer: Reducer<IContactState> = (state = defaultState, action: RootActions) => {
    switch (action.type) {
        case contactConstants.SUBMIT_FORM:
            return {
                ...state,
                isSubmitting: true
            };
        case contactConstants.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                isSubmitted: true,
                isSubmitting: false,
            };
        case contactConstants.SUBMIT_FORM_FAILED:
            return {
                ...state,
                isSubmitting: false,
                isSubmitted: false
            };
        case contactConstants.CLEAR:
            return defaultState;
        default:
            return state;
    }
};
