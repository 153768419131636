import ModuleInput, { IModuleInput } from './biolibBinaryFormat/ModuleInput';
import { IJobUtils } from './types';
import { BioLib,BioLibSingleton, IJob } from '../BioLib';
import ModuleOutputV2 from './biolibBinaryFormat/ModuleOutputV2';
import RemoteExecutorNew from './RemoteExecutorNew';

export default class AppClient {
    private static apiClient: BioLib;

    public static setApiClient(biolib: BioLib) {
        AppClient.apiClient = biolib;
    }

    public static async runJob(
        job: IJob,
        inputData: IModuleInput | Uint8Array,
        utils: IJobUtils,
    ): Promise<ModuleOutputV2> {
        const moduleInput = new ModuleInput(inputData);
        const inputSerialized = moduleInput.serialize();

        if (moduleInput.parameters.length > 0) {
            utils.addLogMessage(
                `Running job ${job.public_id} with arguments: ${JSON.stringify(moduleInput.parameters)}. \
Got ${moduleInput.stdin.length} bytes of stdin data.`);
        } else {
            utils.addLogMessage(`Running job without arguments. Got ${moduleInput.stdin.length} bytes of stdin data.`);
        }

        let computeError: null | Error = null;
        try {
            await RemoteExecutorNew.runJobBiolibCloud(
                utils,
                {
                    job,
                    moduleName: 'main',
                    biolibApiBaseUrl: this.apiClient.httpClient.baseUrl,
                    refreshToken: this.apiClient.httpClient.getRefreshToken(),
                },
                inputSerialized,
            );
            utils.addLogMessage(`Job with id ${job.public_id} finished computing`);
        } catch (error) {
            console.error('Hit error waiting for cloud job: ', error);
            computeError = error;
        }

        try {
            // TODO: Get state of job from backend and wait if not done
            const moduleOutput = await this.getUnencryptedModuleOutput(job);
            await moduleOutput.getExitCode(); // Get some data to catch potential 404 here
            return moduleOutput
        } catch (error) {
            // TODO: Nicer handling
            console.error('Failed to get module output from job: ', error);
            if (computeError) {
                throw computeError;
            }
            throw error;
        }
    }

    private static async getUnencryptedModuleOutput(job: IJob): Promise<ModuleOutputV2> {
        const jobResultUrlResponse = await BioLibSingleton.get().httpClient.axios.get(
            `/api/jobs/${job.public_id}/storage/results/download/`,
            { headers: { 'Job-Auth-Token': job.auth_token } },
        )
        const { presigned_download_url } = jobResultUrlResponse.data;
        const urlObject = new URL(presigned_download_url);
        const downloadUrl = `${location.origin}${urlObject.pathname}${urlObject.search}`;
        return new ModuleOutputV2(downloadUrl);
    }
}
