import * as jwtDecode from 'jwt-decode';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AccessToken, RefreshToken } from './BioLib';
import { BioLibAuthenticationError } from './errors';
import { axiosResponseRejectedHandler } from './utils';

export default class HttpClient {
    public readonly axios: AxiosInstance;
    public readonly baseUrl: string;
    private accessTokenPath: string;
    private accessToken: AccessToken | undefined;
    private refreshToken: RefreshToken | undefined;

    constructor(options: { baseURL: string, accessTokenPath?: string, refreshToken?: string }) {
        this.baseUrl = options.baseURL;
        this.accessTokenPath = options.accessTokenPath ?? '/api/user/token/refresh/';
        this.refreshToken = options.refreshToken;

        this.axios = this.axios = axios.create({ baseURL: this.baseUrl });
        this.axios.interceptors.request.use(this.interceptRequest, (error) => Promise.reject(error));
        this.axios.interceptors.response.use((response) => response, axiosResponseRejectedHandler);
    }

    public setRefreshToken(refreshToken: RefreshToken): void {
        this.refreshToken = refreshToken;
    }

    public setAccessToken(accessToken: AccessToken): void {
        this.accessToken = accessToken;
    }

    public getAccessToken(): AccessToken {
        if (!this.accessToken) {
            throw new Error('HttpClient: Failed to get access token it was undefined');
        }
        return this.accessToken;
    }

    public getRefreshToken(): RefreshToken | undefined {
        return this.refreshToken;
    }

    public clearTokens(): void {
        this.accessToken = undefined;
        this.refreshToken = undefined;
    }

    public getSingedInUserId(): string | undefined {
        if (this.refreshToken === undefined) {
            return undefined;
        }
        const { public_id } = jwtDecode(this.refreshToken) as any;
        return public_id;
    }

    public async fetchNewAccessToken(): Promise<string> {
        this.accessToken = await this.axios.post<{ access: string }>(
            this.accessTokenPath, { refresh: this.refreshToken }
        ).then((response) => response.data.access);

        return this.accessToken as string;
    }

    public async getAccessTokenAndFetchIfExpired(): Promise<string> {
        if (!this.refreshToken) {
            throw new Error('HttpClient: Refresh token was undefined');
        }

        if (HttpClient.isJwtExpired(this.refreshToken)) {
            throw new BioLibAuthenticationError('The session has expired');
        }

        if (!this.accessToken || HttpClient.isJwtExpired(this.accessToken)) {
            try {
                this.accessToken = await this.fetchNewAccessToken();
            } catch (error) {
                throw new BioLibAuthenticationError(error?.response?.data?.detail);
            }
        }

        return this.accessToken;
    }

    private interceptRequest = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        return new Promise<AxiosRequestConfig>(async (resolve, reject) => {
            if (config?.headers?.Authorization) {
                if (!this.refreshToken) {
                    // If not signed in, then skip authorization
                    delete config.headers.Authorization;
                    return resolve(config);
                }
                let accessToken: string;
                try {
                    accessToken = await this.getAccessTokenAndFetchIfExpired();
                } catch (error) {
                    return reject(error);
                }
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            config.headers['client-type'] = 'biolib-frontend';
            resolve(config);
        });
    };

    private static isJwtExpired(token: AccessToken | RefreshToken): boolean {
        const { exp } = jwtDecode(token) as any;
        return exp * 1000 - Date.now() <= 0;
    }
}
