import {
    BioLibApiError,
    BioLibSingleton,
    BioLibValidationError,
    IAccount,
    ISlimApp, 
    Paginated
} from "@biolibtech/biolib-js";
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getAccountByHandle, IPaginationState } from "../state";
import {
    AccountAppsChangePageAction,
    AccountCreateAction,
    AccountDeleteMemberAction,
    AccountFetchAction,
    AccountFetchAppsAction,
    AccountFetchMembersAction,
    AccountInviteAction,
    accountsActions,
    AccountUpdateMemberRoleAction,
} from "../state/accounts/accountsActions";
import { AccountsConstants } from "../state/accounts/accountsConstants";
import { notificationActions } from "../state/notification/notificationActions";
import { NotificationTypes } from "../state/notification/notificationActionTypes";
import { IRootState } from "../state/rootReducer";
import { navigateWindow } from "../utils";
import { isEmail } from '../components/common';

function* watchFetchAccount() {
    yield takeLatest(AccountsConstants.ACCOUNT_FETCH, function* (action: AccountFetchAction) {
        const { account_handle } = action.payload;
        try {
            const biolib = BioLibSingleton.get();
            const account = yield biolib.account.fetch(account_handle);
            yield put(accountsActions.accountFetchSuccess(account));

            yield call(fetchAccountApps, account);

        } catch (error) {
            if (error instanceof BioLibApiError) {
                yield put(accountsActions.accountFetchFailure(
                    `Failed to fetch account ${account_handle}:\n${error.toString()}`
                ))
            } else {
                yield put(accountsActions.accountFetchFailure(
                    `Failed to fetch account ${account_handle}. Unknown error.`
                ))
            }
        }
    })
}

function* fetchAccountApps(account: IAccount) {
    try {
        const biolib = BioLibSingleton.get();
        const { account_handle, public_id } = account;
        const page_size = 3;

        const paginatedApps: Paginated<ISlimApp> = yield biolib.app.fetchMultiple({
            account_handle,
            page_size,
        });

        const accountApps: IPaginationState<ISlimApp> = {
            countPages: Math.ceil(paginatedApps.count / page_size),
            countPerPage: page_size,
            countTotal: paginatedApps.count,
            currentPage: 0,
            pages: {
                [0]: paginatedApps.results,
            }
        };
        yield put(accountsActions.fetchAppsSuccess(public_id, accountApps));
    } catch (error) {
        if (error instanceof BioLibApiError) {
            yield put(accountsActions.fetchAppsFailure(
                `Failed to fetch account applications:\n${error.toString()}`
            ))
        }
    }
}

function* watchFetchAccountApps() {
    yield takeEvery(AccountsConstants.FETCH_APPS, function* (action: AccountFetchAppsAction) {
        const state: IRootState = yield select();
        const account = getAccountByHandle(action.payload.account_handle, state.accounts.accountsDict);

        if (account) {
            yield call(fetchAccountApps, account);
        } else {
            yield put(accountsActions.fetchAppsFailure(`Failed to get account for fetching applications`));
        }
    })
}

function* watchChangePageAccountApps() {
    yield takeLatest(AccountsConstants.APPS_CHANGE_PAGE, function* (action: AccountAppsChangePageAction) {
        const { accountId, newPageId } = action.payload;
        try {
            const biolib = BioLibSingleton.get();
            const state: IRootState = yield select();
            const { account_handle } = state.accounts.accountsDict[accountId];
            const accountApps = state.accounts.accountsAppsDict[accountId];

            if (newPageId < 0 || newPageId >= accountApps.countPages) {
                return yield put(accountsActions.appsChangePageFailure(`Invalid page change`));
            }

            if (newPageId in accountApps.pages) {
                yield put(accountsActions.appsChangePageSuccess(accountId, newPageId));
            } else {
                const paginatedApps: Paginated<ISlimApp> = yield biolib.app.fetchMultiple({
                    account_handle,
                    page: newPageId + 1,
                    page_size: accountApps.countPerPage,
                });
                yield put(accountsActions.appsChangePageSuccess(accountId, newPageId, paginatedApps.results));
            }

        } catch (error) {
            if (error instanceof BioLibApiError) {
                yield put(accountsActions.appsChangePageFailure(
                    `Changing page to ${newPageId} failed: \n${error.toString()}`
                ))
            } else {
                yield put(accountsActions.appsChangePageFailure(
                    `Changing page to ${newPageId} failed: \n${error.message}`
                ))
            }
        }
    })
}

function* watchCreateOrganization() {
    yield takeLatest(AccountsConstants.CREATE, function* (action: AccountCreateAction) {
        try {
            const account = yield BioLibSingleton.get().account.create(action.payload.account);
            yield put(accountsActions.createSuccess(account));
            yield call(fetchAccountApps, account);
            yield navigateWindow(`/${account.account_handle}/`);
        } catch (error) {
            if (error instanceof BioLibValidationError) {
                yield put(notificationActions.addError(error.toString(), NotificationTypes.USER));
                yield put(accountsActions.createFailure(error.toString()));
            } else {
                yield put(notificationActions.addError(
                    `Team Creation Failed. Unknown error`,
                    NotificationTypes.USER
                ));
                yield put(accountsActions.createFailure(`Team Creation Failed. Unknown error`));
            }
        }
    });
}

function* watchAccountInvite() {
    yield takeLatest(AccountsConstants.INVITE, function* (action: AccountInviteAction) {
        const { teamAccountHandle, emailOrAccountHandleToInvite, role } = action.payload;
        try {
            const data: any = { role };
            if (isEmail(emailOrAccountHandleToInvite)) {
                data.email = emailOrAccountHandleToInvite;
            } else {
                data.account_handle = emailOrAccountHandleToInvite;
            }
            yield BioLibSingleton.get().account.inviteMember(teamAccountHandle, data);
            yield put(accountsActions.inviteSuccess());
            yield put(notificationActions.addSuccess(
                `Successfully invited ${emailOrAccountHandleToInvite} as ${role}`,
                NotificationTypes.USER,
            ));
        } catch (error) {
            if (error instanceof BioLibValidationError) {
                yield put(accountsActions.inviteFailure(
                    `Failed to invite ${emailOrAccountHandleToInvite} to ${teamAccountHandle}:\n${error.toString()}`
                ));
            } else {
                yield put(accountsActions.inviteFailure(
                    `Failed to invite ${emailOrAccountHandleToInvite} to ${teamAccountHandle}:\n${error.message}`
                ));
            }
        }
    })
}

function* watchFetchMembers() {
    yield takeLatest(AccountsConstants.FETCH_MEMBERS, function* (action: AccountFetchMembersAction) {
        const { org_account_handle } = action.payload;
        try {
            const memberAccounts = yield BioLibSingleton.get().account.fetchMembers(org_account_handle);
            yield put(accountsActions.fetchMembersSuccess(org_account_handle, memberAccounts));
        } catch (error) {
            if (error instanceof BioLibValidationError) {
                yield put(accountsActions.fetchMembersFailure(
                    `Failed to fetch members of ${org_account_handle}:\n${error.toString()}`
                ));
            } else {
                yield put(accountsActions.fetchMembersFailure(
                    `Failed to fetch members of ${org_account_handle}:\n${error.message}`
                ));
            }
        }
    })
}

function* watchDeleteMember() {
    yield takeEvery(AccountsConstants.DELETE_MEMBER, function* (action: AccountDeleteMemberAction) {
        try {
            const { org_account_handle, member_account_handle } = action.payload;
            yield BioLibSingleton.get().account.deleteMember(org_account_handle, member_account_handle);
            yield put(accountsActions.deleteMemberSuccess());

            yield put(accountsActions.fetchMembers(org_account_handle));

        } catch (error) {
            const { org_account_handle, member_account_handle } = action.payload;
            let errorMessage = '';
            if (error instanceof BioLibValidationError) {
                errorMessage = `Failed to delete member ${member_account_handle} from ${org_account_handle}:
                    ${error.toString()}`
            } else {
                errorMessage = `Failed to delete member ${member_account_handle} from ${org_account_handle}:
                    ${error.message}`
            }

            // TODO: Figure out a standard pattern for this kind of error handling
            yield put(accountsActions.deleteMemberFailure(errorMessage));
            yield put(notificationActions.addError(errorMessage, NotificationTypes.USER));
        }
    })
}

function* watchUpdateMemberRole() {
    yield takeEvery(AccountsConstants.UPDATE_MEMBER_ROLE, function* (action: AccountUpdateMemberRoleAction) {
        try {
            const { org_account_handle, member_account_handle, role } = action.payload;
            yield BioLibSingleton.get().account.updateMemberRole(org_account_handle, member_account_handle, { role });
            yield put(accountsActions.updateMemberRoleSuccess());

            yield put(accountsActions.fetchMembers(org_account_handle));

        } catch (error) {
            const { org_account_handle, member_account_handle, role } = action.payload;
            let errorMessage = '';
            if (error instanceof BioLibValidationError) {
                errorMessage = `Failed to update role to ${role} for ${member_account_handle} in ${org_account_handle}:
                    ${error.toString()}`
            } else {
                errorMessage = `Failed to update role to ${role} for ${member_account_handle} in ${org_account_handle}:
                    ${error.message}`
            }

            // TODO: Figure out a standard pattern for this kind of error handling
            yield put(accountsActions.updateMemberRoleFailure(errorMessage));
            yield put(notificationActions.addError(errorMessage, NotificationTypes.USER));
        }
    })
}

export default function* accountSaga() {
    yield fork(watchAccountInvite);
    yield fork(watchChangePageAccountApps);
    yield fork(watchCreateOrganization);
    yield fork(watchFetchAccount);
    yield fork(watchFetchAccountApps);
    yield fork(watchFetchMembers);
    yield fork(watchDeleteMember);
    yield fork(watchUpdateMemberRole);
}
