import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadingState, LoadingState } from '../../utils';
import { ISettingValues } from './components/tabs/InputTab/types';
import { JobUuid } from '@biolibtech/biolib-js';
import {
    IJobWrapper,
    JobStatus,
    IJobDataWrapperDict,
    IJobWrapperDict,
    IOpenAppPayload,
    ErrorReportSendingState,
} from './types';
import { processStreamedOutput } from './utils';

export interface IAppRunState {
    hasGuestUserAcceptedTerms: boolean;
    jobDataWrapperDict: IJobDataWrapperDict;
    jobWrapperDict: IJobWrapperDict;
    openAppLoadingState: ILoadingState;
    openAppPayloadAwaitingAcceptTerms: IOpenAppPayload | null;
    jobIdOpenInPage: JobUuid | null;
    jobIdOpenInModal: JobUuid | null;
}

const initialState: IAppRunState = {
    hasGuestUserAcceptedTerms: false,
    jobDataWrapperDict: {},
    jobIdOpenInPage: null,
    jobIdOpenInModal: null,
    jobWrapperDict: {},
    openAppLoadingState: LoadingState.default,
    openAppPayloadAwaitingAcceptTerms: null,
};

const appRunSlice = createSlice({
    name: 'appRun',
    initialState,
    reducers: {
        openApp(state, action: PayloadAction<IOpenAppPayload>) {
            state.openAppLoadingState = LoadingState.begin;
        },
        openAppSuccess(state, action: PayloadAction<{ jobWrapper: IJobWrapper; openInModal?: boolean; }>) {
            const { jobWrapper, openInModal } = action.payload;
            const jobId = jobWrapper.job.public_id;
            state.jobWrapperDict[jobId] = jobWrapper;
            state.openAppLoadingState = LoadingState.success;
            if (openInModal) {
                state.jobIdOpenInModal = jobId;
            } else {
                state.jobIdOpenInPage = jobId;
            }
        },
        openAppFailure(state, action: PayloadAction<string>) {
            state.openAppLoadingState = LoadingState.failure(action.payload);
        },
        openAppAwaitingAcceptTerms(state, action: PayloadAction<IOpenAppPayload>) {
            state.openAppLoadingState = LoadingState.default;
            state.openAppPayloadAwaitingAcceptTerms = action.payload;
        },
        quitAppAwaitingAcceptTerms(state) {
            state.openAppPayloadAwaitingAcceptTerms = null;
        },
        quitJob(state, action: PayloadAction<{ jobId: JobUuid; }>) {
            const { jobId } = action.payload;
            if (state.jobIdOpenInModal === jobId) {
                state.jobIdOpenInModal = null;
            }
            if (state.jobIdOpenInPage === jobId) {
                state.jobIdOpenInPage = null;
            }
            if (state.jobWrapperDict[jobId]) {
                delete state.jobWrapperDict[jobId];
            }
            if (state.jobDataWrapperDict[jobId]) {
                delete state.jobDataWrapperDict[jobId];
            }
        },
        setJobIdOpenInPage(state, action: PayloadAction<{ jobIdOpenInPage: JobUuid | null }>) {
            state.jobIdOpenInPage = action.payload.jobIdOpenInPage;
        },
        setJobIdOpenInModal(state, action: PayloadAction<{ jobIdOpenInModal: JobUuid | null }>) {
            state.jobIdOpenInModal = action.payload.jobIdOpenInModal;
        },
        setHasGuestAcceptedTermsToTrue(state) {
            state.hasGuestUserAcceptedTerms = true;
        },
        runJob(state, action: PayloadAction<{ jobId: string; settingValues: ISettingValues; }>) {
            const { jobId, settingValues } = action.payload;
            state.jobWrapperDict[jobId].status = JobStatus.computing;
            state.jobDataWrapperDict[jobId] = { settingValues };
        },
        runJobSuccess(state, action: PayloadAction<{ jobId: string; }>) {
            const { jobId } = action.payload;
            state.jobWrapperDict[jobId].status = JobStatus.finished;
            state.jobWrapperDict[jobId].progressInitialization = 1;
            state.jobWrapperDict[jobId].progressCompute = 1;
        },
        runJobFailure(state, action: PayloadAction<{ jobId: string; error: Error; status: JobStatus; }>) {
            const { jobId, error, status } = action.payload;
            state.jobWrapperDict[jobId].error = error;
            state.jobWrapperDict[jobId].status = status;
        },
        setProgressInitialization(state, action: PayloadAction<{ jobId: JobUuid; progressInitialization: number; }>) {
            const { jobId, progressInitialization } = action.payload;
            if (state.jobWrapperDict[jobId]) {
                state.jobWrapperDict[jobId].progressInitialization = progressInitialization;
            }
        },
        setProgressCompute(state, action: PayloadAction<{ jobId: JobUuid; progressCompute: number; }>) {
            const { jobId, progressCompute } = action.payload;
            if (state.jobWrapperDict[jobId]) {
                state.jobWrapperDict[jobId].progressCompute = progressCompute;
            }
        },
        addLogMessage(state, action: PayloadAction<{ jobId: JobUuid; message: string; }>) {
            const { jobId, message } = action.payload;
            if (state.jobWrapperDict[jobId]) {
                state.jobWrapperDict[jobId].logMessages.push({ message, timestamp: Date.now().toString() });
            }
        },
        addStreamingOutputMessage(state, action: PayloadAction<{ jobId: JobUuid; streamingOutputMessage: string; }>) {
            const { jobId, streamingOutputMessage } = action.payload;
            const jobWrapper = state.jobWrapperDict[jobId];
            if (jobWrapper) {
                jobWrapper.streamedOutput = processStreamedOutput(jobWrapper.streamedOutput + streamingOutputMessage);
            }
        },
        sendErrorReport(state, action: PayloadAction<{ jobId: string; }>) {
            state.jobWrapperDict[action.payload.jobId].errorReportSendingState = ErrorReportSendingState.processing;
        },
        sendErrorReportSuccess(state, action: PayloadAction<{ jobId: string; }>) {
            state.jobWrapperDict[action.payload.jobId].errorReportSendingState = ErrorReportSendingState.success;
        },
        sendErrorReportFailure(state, action: PayloadAction<{ jobId: string; }>) {
            state.jobWrapperDict[action.payload.jobId].errorReportSendingState = ErrorReportSendingState.failed;
        }
    }
})

export default appRunSlice;
