import SignUp from '../../../components/SignUp';
import userActions from '../../../state/user/userActions';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../state/rootReducer';
import userFlowSlice from '../userFlowSlice';

const mapStateToProps = (state: IRootState) => ({
    gitHubSignInLoadingState: state.userFlow.gitHubSignInLoadingState,
    isSignedIn: state.user.isSignedIn,
    createUserLoadingState: state.user.createUserLoadingState,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        clearCreateAccountErrors:  userActions.clearCreateAccountErrors,
        createUser: userActions.createUser,
        gitHubSignIn: userFlowSlice.actions.gitHubSignIn,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
