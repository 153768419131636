import {
    AccountRole,
    AccountUuid,
    BioLibApiError,
    BioLibAuthenticationError,
    BioLibServerError,
    BioLibSingleton,
    BioLibValidationError,
    IAccountAsAdmin,
    IAccountAsMember,
    IUser,
} from "@biolibtech/biolib-js";
import { navigate } from "gatsby";
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { notificationActions } from "../state/notification/notificationActions";
import { NotificationTypes } from "../state/notification/notificationActionTypes";
import { IRootState } from "../state/rootReducer";
import { userActions } from "../state/user/userActions";
import {
    AuthenticationRequiredAction,
    ChangeUserEmailAction,
    CreateUserAction,
    IUserWithAccountsDict,
    ResetPasswordAction,
    ResetPasswordConfirmAction,
    SignInAction,
    UpdateAccountAction,
    VerifyEmailAction,
} from "../state/user/userActions.types";
import userConstants from "../state/user/userConstants";
import { fetchFavorites } from "./appsSaga";
import userFlowSlice from '../features/userFlow/userFlowSlice';
import AuthCookie from '../features/userFlow/utils/AuthCookie';
import { authApi } from '../features/auth/authApi';

function* watchSignIn() {
    yield takeLatest(userConstants.SIGN_IN, handleSignIn);
}

function* handleSignIn(action: SignInAction) {
    try {
        const biolib = BioLibSingleton.get();
        const { email, password } = action.payload;
        const { user, refreshToken } = yield biolib.user.signInAndFetch({ email, password });
        AuthCookie.set(refreshToken);
        yield put(userActions.signInSuccess(getUserWithAccountDict(user)));
    } catch (error) {
        // TODO: Improve error messages
        if (error instanceof BioLibAuthenticationError) {
            yield put(userActions.signInFailed(
                `Wrong email or password. Try again or click "Forgot password"`
            ));
        } else if (error instanceof BioLibServerError) {
            yield put(userActions.signInFailed(
                'Sign in failed. Something is wrong at our end. Please contact us if the error persists.'
            ));
        } else if (error instanceof BioLibApiError) {
            yield put(userActions.signInFailed(`Sign in failed: ${error.toString()}`));
        } else {
            yield put(userActions.signInFailed(`Sign in failed with unknown error`));
        }
    }
}

export function getUserWithAccountDict(user: IUser): IUserWithAccountsDict {
    return {
        user,
        organizationIds: user.accounts.reduce((accumulator: AccountUuid[], account) => {
            return account.role !== AccountRole.intrinsic ? [...accumulator, account.public_id] : accumulator;
        }, []),
        accountsDict: user.accounts.reduce((accumulator, account) =>
            ({ ...accumulator, [account.public_id]: account }), {}),
        intrinsicAccountId: user.accounts.find(account => account.role === 'intrinsic')?.public_id,
    }
}

export function* signInWithRefreshToken() {
    // The the BioLibSingleton has been initialized with the refreshToken in the initializationSaga
    try {
        const biolib = BioLibSingleton.get();
        // The validity of the refreshToken is checked by fetching the user's profile
        // If the api call fails an error is thrown which is caught below
        const user = yield biolib.user.fetch();
        yield put(userActions.signInSuccess(getUserWithAccountDict(user)));
    } catch (error) {
        // If sign in with refresh token fails then sign the user out
        yield put(authApi.endpoints.logout.initiate() as any);
    } finally {
        yield put(userFlowSlice.actions.initialized());
    }
}

function* watchSignInSuccess() {
    yield takeLatest(userConstants.SIGN_IN_SUCCESS, function* () {
        yield call(fetchFavorites);
    })
}

function* createUser(action: CreateUserAction) {
    try {
        const biolib = BioLibSingleton.get();
        const user: IUser = yield biolib.user.create(action.payload);

        const userWithAccountDict = getUserWithAccountDict(user);
        yield put(userActions.createAccountSuccess(userWithAccountDict));
        yield put(userActions.signIn(user.email, action.payload.password));
    } catch (error) {
        // TODO: Improve error messages
        if (error instanceof BioLibServerError) {
            yield put(userActions.createAccountFailed(
                'Creating user failed. Something is wrong at our end. Please contact us if the error persists.'
            ));
        } else if (error instanceof BioLibApiError) {
            const errorMessage = error.toString();
            if (errorMessage.includes("user with this email address already exists.")) {
                yield put(userActions.createAccountFailed(
                    `Creating user failed: A BioLib account with this email already exists. Please sign-in instead.`));
            } else {
                yield put(userActions.createAccountFailed(`Creating user failed: ${errorMessage}`));
            }
        }
    }
}

function* watchCreateUser() {
    yield takeEvery(userConstants.CREATE_USER, createUser);
}

function* handleAuthenticationRequired(action: AuthenticationRequiredAction) {
    yield navigate("/sign-in/");
    yield put(notificationActions.addInfo(action.payload.message, NotificationTypes.USER, {
        onClick: () => navigate("/sign-up/"),
        text: "Create Account",
        icon: "user",
    }));
}

function* watchAuthenticationRequired() {
    yield takeEvery(userConstants.AUTHENTICATION_REQUIRED, handleAuthenticationRequired);
}

function* resetPassword(action: ResetPasswordAction) {
    try {
        const biolib = BioLibSingleton.get();
        yield biolib.user.resetPassword(action.payload);
        yield put(userActions.resetPasswordSuccess());
    } catch (error) {
        // TODO: Improve error messages
        if (error instanceof BioLibApiError) {
            yield put(userActions.resetPasswordFailed(
                `Failed to request password reset: ${error.toString()}`
            ));
        } else {
            yield put(userActions.resetPasswordFailed(
                `Failed to request password reset.`
            ));
        }
    }
}

function* watchResetPassword() {
    yield takeLatest(userConstants.RESET_PASSWORD, resetPassword);
}

function* resetPasswordConfirm(action: ResetPasswordConfirmAction) {
    try {
        const biolib = BioLibSingleton.get();
        yield biolib.user.resetPasswordConfirm(action.payload);
        yield put(userActions.resetPasswordConfirmSuccess());
    } catch (error) {
        // TODO: Improve error messages depending on error type
        if (error instanceof BioLibValidationError) {
            yield put(userActions.resetPasswordConfirmFailed(
                `Changing password failed: ${error.toString()}`
            ));
        } else {
            yield put(userActions.resetPasswordConfirmFailed(
                `Changing password failed. Please try requesting a new reset link.`
            ));
        }
    }
}

function* watchResetPasswordConfirm() {
    yield takeLatest(userConstants.RESET_PASSWORD_CONFIRM, resetPasswordConfirm);
}

function* updateAccount(action: UpdateAccountAction) {
    try {
        const biolib = BioLibSingleton.get();
        const { account_handle, accountUpdate } = action.payload;
        const account: IAccountAsMember | IAccountAsAdmin = yield biolib.account.update(account_handle, accountUpdate);
        yield put(userActions.updateAccountSuccess(account));
        yield put(notificationActions.addSuccess('Account updated', NotificationTypes.USER));
        if (account.role !== AccountRole.intrinsic) {
            yield navigate(`/teams/${account.account_handle}/edit/`);
        }
        yield call(reFetchUser);
    } catch (error) {
        if (error instanceof BioLibValidationError) {
            yield put(userActions.updateAccountFailure(''));
        }
        yield put(notificationActions.addError(
            `Failed to update account\n${error instanceof BioLibValidationError ? error.toString() : error.message}`
        ));
    }
}

function* watchUpdateAccount() {
    yield takeLatest(userConstants.UPDATE_ACCOUNT, updateAccount);
}

function* changeUserEmail(action: ChangeUserEmailAction) {
    try {
        const state: IRootState = yield select();
        const userId = state.user.user?.public_id;
        if (!userId) {
            return yield put(userActions.changeUserEmailFailure(`Must be signed in`));
        }
        const user = yield BioLibSingleton.get().user.changeEmail(userId, action.payload);
        yield put(userActions.changeUserEmailSuccess(getUserWithAccountDict(user)));
        yield put(notificationActions.addSuccess(
            `Successfully Changed Email Address to:\n${user.email}`,
            NotificationTypes.USER
        ));
    } catch (error) {
        if (error instanceof BioLibValidationError) {
            yield put(userActions.changeUserEmailFailure(error.toString()));
        } else {
            yield put(userActions.changeUserEmailFailure(`Changing email failed. Please try again.`));
        }
    }
}

function* watchChangeUserEmail() {
    yield takeLatest(userConstants.CHANGE_EMAIL, changeUserEmail)
}

export function* reFetchUser() {
    const state: IRootState = yield select();
    if (state.user.isSignedIn && state.user.user) {
        const user: IUser = yield BioLibSingleton.get().user.fetch(state.user.user.public_id);
        yield put(userActions.setUserWithAccountsDict(getUserWithAccountDict(user)));
    }
}

function* verifyEmail(action: VerifyEmailAction) {
    try {
        const biolib = BioLibSingleton.get();
        yield biolib.user.verifyEmail(action.payload);
        yield put(notificationActions.addSuccess("Successfully Verified Email Address", NotificationTypes.USER));
        yield call(reFetchUser);
    } catch (error) {
        if (error instanceof BioLibApiError) {
            yield put(notificationActions.addError(error.toString(), NotificationTypes.USER));
        } else {
            yield put(notificationActions.addError("Failed to verify email address", NotificationTypes.USER));
        }
    }
}

function* watchVerifyEmail() {
    yield takeLatest(userConstants.VERIFY_EMAIL, verifyEmail);
}


function* requestVerificationEmail() {
    try {
        const { email } = yield BioLibSingleton.get().user.requestVerificationEmail();
        yield put(notificationActions.addInfo(
            `Verification email sent to:\n${email}`,
            NotificationTypes.USER
        ));
    } catch (error) {
        if (error instanceof BioLibApiError) {
            yield put(notificationActions.addError(error.toString(), NotificationTypes.USER));
        } else {
            yield put(notificationActions.addError(
                `Unable to request verification email`,
                NotificationTypes.USER
            ));
        }
    }
}

function* watchRequestVerificationEmail() {
    yield takeLatest(userConstants.REQUEST_VERIFICATION_EMAIL, requestVerificationEmail);
}

export default function* userSaga() {
    yield fork(watchAuthenticationRequired);
    yield fork(watchChangeUserEmail);
    yield fork(watchCreateUser);
    yield fork(watchRequestVerificationEmail);
    yield fork(watchResetPassword);
    yield fork(watchResetPasswordConfirm);
    yield fork(watchSignIn);
    yield fork(watchSignInSuccess);
    yield fork(watchUpdateAccount);
    yield fork(watchVerifyEmail);
}
