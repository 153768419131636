import BioLib from "./BioLib";
import { BioLibApiError } from "./errors";

interface IConfig {
    baseUrl: string;
    refreshToken?: string;
}

export default class BioLibSingleton {
    private static config: IConfig;
    private static instance: BioLib;

    public static setConfig(config: IConfig): void {
        this.config = config;
    }

    public static get(): BioLib {
        if (!this.instance) {
            if (!this.config) {
                throw new BioLibApiError(`Config not set for BioLib API`);
            }
            const { baseUrl, refreshToken } = this.config;
            this.instance = new BioLib(baseUrl, refreshToken);
        }
        return this.instance;
    }
}
