import { ISetting } from '@biolibtech/biolib-js';
import { SettingValue } from '../types';

export async function validateSequencesAndReturnErrorMessage(settingValue: SettingValue): Promise<string | null> {
    try {
        if (typeof settingValue === 'string') {
            assertSequencesAreValid(settingValue);
        } else if (settingValue instanceof FileList) {
            if (settingValue.length !== 1) {
                return 'Expected one file';
            } else {
                const file = settingValue[0];
                if (file.size < 50_000_000) {
                    const data = await file.text();
                    assertSequencesAreValid(data);
                } else {
                    // skip validation of large files
                }
            }
        }
    } catch (error) {
        if (error instanceof ValidationError) {
            return error.message;
        } else {
            return `Failed to validate sequence. Hit error: ${error.message}`;
        }
    }

    return null;
}

export interface ISequenceRecord {
    sequence: string;
    sequenceId: string;
    description: string;
}

class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ValidationError';
    }
}

function findInvalidSequenceIdCharacters(sequence: string): string[] {
    const allowedSequenceIdChars = new Set(
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789|-_.:*#'.split('')
    );
    return Array.from(sequence).filter((char) => !allowedSequenceIdChars.has(char));
}

function findInvalidSequenceCharacters(sequence: string): string[] {
    const allowedSequenceChars = new Set(
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    );
    return Array.from(sequence).filter((char) => !allowedSequenceChars.has(char));
}

function assertSequencesAreValid(data: string): void {
    if (data.includes('>')) {
        parseFasta(data);
    } else {
        data.split('\n').forEach((line, index) => {
            const invalidChars = findInvalidSequenceIdCharacters(line.trim());
            if (invalidChars.length > 0) {
                throw new ValidationError(`Invalid character ("${invalidChars[0]}") found in sequence on line ${index + 1}`);
            }
        });
    }
}

export function parseFasta(data: string): ISequenceRecord[] {
    const sequences: string[] = [];
    let currentSequence: string[] = [];
    data.split('\n').forEach((line) => {
        if (line.startsWith('>')) {
            if (currentSequence.length) {
                sequences.push(currentSequence.join('\n'));
            }
            currentSequence = [`${line.substring(1).trim()}\n`];
        } else {
            const trimmedLine = line.trim();
            if (trimmedLine) {
                currentSequence.push(`${trimmedLine}\n`);
            }
        }
    });
    if (currentSequence.length) {
        sequences.push(currentSequence.join(''));
    }

    const parsedSequences: ISequenceRecord[] = [];
    for (const sequenceData of sequences) {
        const lines = sequenceData.trim().split('\n');
        const headerLine = lines.shift()!.trim().split(/\s+/); // Assert non-null with `!`
        const sequenceId = headerLine[0];
        const description = headerLine.slice(1).join(' ');
        const sequence = lines.map((seq) => seq.trim().toUpperCase()).join('');

        const invalidSequenceIdChars = findInvalidSequenceIdCharacters(sequenceId);
        if (invalidSequenceIdChars.length > 0) {
            throw new ValidationError(`Invalid character ("${invalidSequenceIdChars[0]}") found in ID of sequence ${sequenceId}`);
        }

        const invalidSequenceChars = findInvalidSequenceCharacters(sequence);
        if (invalidSequenceChars.length > 0) {
            throw new ValidationError(`Invalid character ("${invalidSequenceChars[0]}") found in sequence ${sequenceId}`);
        }

        if (sequence.length === 0) {
            throw new ValidationError(`No sequence found for entry "${sequenceId}"`);
        }

        parsedSequences.push({ description, sequence, sequenceId });
    }

    return parsedSequences;
}

export function validateSequenceInputRows(rows: Record<number, Record<number, string>>, setting: ISetting): null | string {
    const nameToRowNumber: Record<string, number> = {};
    let isEmpty = true;
    for (const [rowIndex, columns] of Object.entries(rows)) {
        const rowNumber = Number(rowIndex) + 1;
        const name = columns[0].trim().replace('>', '') || `sequence_${rowNumber.toString()}`;
        const sequence = columns[1].trim();
        if (sequence) {
            if (/\s/.test(name)) {
                return `Row "${name}" cannot have spaces in the name.`;
            }
            if (/\s/.test(sequence)) {
                return `Row "${name}" cannot have spaces in the sequence.`;
            }

            if (name in nameToRowNumber) {
                return `Row "${name}" must be unique across rows.`;
            }
            nameToRowNumber[name] = rowNumber;
        }
        if (name && sequence) {
            isEmpty = false;
        }
    }
    if (isEmpty && setting.required && !setting.default_value) {
        return 'Please fill out at least one row.';
    }
    return null;
}
