import { ImgproxyUrl } from './common.types';
import { IRemoteHost } from './AppTypes';

export type AccountUuid = string;

export type AccountHandle = string;

export enum AccountRole {
    intrinsic = 'intrinsic',
    admin = 'admin',
    member = 'member',
}

export enum OrganizationRole {
    admin = 'admin',
    member = 'member',
}

export enum SubscriptionPlan {
    basic = 'basic',
    enterprise = 'enterprise',
}

export interface IAccount {
    account_handle: AccountHandle;
    bio: string;
    display_name: string;
    profile_picture: ImgproxyUrl;
    profile_picture_200x200: ImgproxyUrl;
    public_email: string;
    public_id: AccountUuid;
    uuid: AccountUuid;
    url: string;
}

export interface IAccountAsMember extends IAccount {
    role: AccountRole;
    subscription_plan: SubscriptionPlan;
}

export interface IAccountAsAdmin extends IAccountAsMember {
    block_all_non_custom_remote_hosts: boolean;
    block_biolib_cloud_compute: boolean;
    custom_compute_node_url: string;
}

export interface IAccountUpdate {
    account_handle?: AccountHandle;
    bio?: string;
    block_all_non_custom_remote_hosts?: boolean;
    block_biolib_cloud_compute?: boolean;
    custom_compute_node_url?: string;
    display_name?: string;
    profile_picture?: File;
    public_email?: string;
    url?: string;
}

export interface IAccountMemberResponse {
    org_account_handle: AccountHandle;
    member_account_handle: AccountHandle;
    role: OrganizationRole;
}

export interface IRemoteHostResponse extends IRemoteHost {
    public_id: string;
}

export interface ISaml2SsoConnection {
    attribute_mapping_yaml: string;
    identity_provider_entity_id: string;
    identity_provider_metadata_xml: string;
}

export interface ISsoDomainRequestData {
    domain: string;
}

export interface ISsoDomainResponse {
    domain: string;
    public_id: string;
    state: 'processing' | 'approved';
}

export interface ISsoConnectionRequestData {
    saml2_sso_connection: ISaml2SsoConnection;
}

export interface ISsoConnectionResponse {
    public_id: string;
    saml2_sso_connection?: ISaml2SsoConnection;
    sso_domains: ISsoDomainResponse[];
}
