import { FieldInputProps } from 'formik';
import React, { useEffect, useRef } from 'react';

interface IProps extends FieldInputProps<string> {
    disabled?: boolean;
    placeholder?: string;
}

export default function TextAreaInput(props: IProps) {
    const textareaRef = useRef<HTMLTextAreaElement>();

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            const currentHeight = textarea.offsetHeight;
            const newHeight = Math.min(textarea.scrollHeight, 320);

            if (newHeight > currentHeight) {
                textarea.style.height = `${newHeight}px`;
            }
        }
    }, [props.value]);

    return (
        <textarea
            {...props}
            ref={textareaRef}
            className='w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-800 sm:text-sm sm:leading-6'
        />
    );
}
