import { FieldProps } from 'formik';
import React from 'react';
import clsx from 'clsx';
import { saveFileListToFormikField } from './utils';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import TextAreaInput from '../TextAreaInput';

interface IProps extends FieldProps {
    defaultValue: string;
}

const buttonStyle = 'w-full rounded bg-white py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 flex justify-center disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none';

export default function TextFileInput(props: IProps) {
    const { field, form, defaultValue } = props;
    const fileList = typeof field.value === 'object' ? field.value as FileList : null;
    const firstFile = fileList ? fileList.item(0) : null;

    const [isDrag, setIsDrag] = React.useState<boolean>(false);
    const fileInputRef = React.createRef() as React.RefObject<HTMLInputElement>;

    const handleOpenFileSelector = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleClearFile = () => {
        form.setFieldValue(field.name, '');
        // Clear the hidden input field so next onChange will work for same file
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDrag(false);
        const files = event.dataTransfer.files;
        saveFileListToFormikField(files, field, form, false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        setIsDrag(true);
        event.dataTransfer.dropEffect = 'copy';
    };

    return (
        <div className='flex flex-col md:flex-row w-full md:space-y-0'>
            <div
                className={clsx('flex-auto mr-1.5', { 'border-2 rounded-md border-emerald-500': isDrag })}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={() => setIsDrag(false)}
            >
                <TextAreaInput
                    {...field}
                    disabled={!!fileList}
                    value={fileList ? '' : field.value}
                    placeholder={fileList && firstFile ?
                        `[Selected file: ${firstFile.name}]` : !defaultValue ? 'Choose file or write text...' :
                            `[Using default file: ${defaultValue}. To change, choose file or write text.]`
                    }
                />
            </div>
            <div className='md:w-40 lg:w-36'>
                <input
                    alt='Select Text File'
                    hidden={true}
                    multiple={false}
                    name='text-file-input'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        saveFileListToFormikField(event.currentTarget.files, field, form, false)
                    }
                    ref={fileInputRef}
                    type='file'
                />
                <div className='justify-between space-y-2'>
                    <button
                        className={buttonStyle}
                        onClick={handleOpenFileSelector}>
                        <DocumentIcon className='h-4 w-4 my-auto mr-1' aria-hidden='true'/>
                        Select File
                    </button>
                    <button
                        className={buttonStyle}
                        onClick={handleClearFile}
                        disabled={!firstFile}>
                        <TrashIcon className='h-4 w-4 my-auto mr-1' aria-hidden='true'/>
                        Clear File
                    </button>
                </div>
            </div>
        </div>
    );
};
