import * as React from "react";
import { Reducer } from "redux";
import { RootActions } from "../rootActions";
import { INotification } from "./notificationActionTypes";
import notificationConstants from "./notificationConstants";
import { Intent, IToastProps } from "@blueprintjs/core";

export interface INotificationState {
    notifications: INotification[];
    toasts: IToastProps[];
}

const defaultState: INotificationState = {
    notifications: [],
    toasts: []
};

export const notificationReducer: Reducer<INotificationState> = (state = defaultState, action: RootActions) => {
    switch (action.type) {
        case notificationConstants.ADD: {
            const toast: IToastProps = {
                message: (
                    <>{action.payload.notification.message.split('\n')
                        .map((line, index) => <p key={index}>{line}</p>)
                    }</>
                ),
                intent: action.payload.notification.intent,
                icon: intentToIcon(action.payload.notification.intent),
                action: action.payload.notification.action,
                timeout: action.payload.notification.timeout,
            };
            return {
                ...state,
                notifications: [action.payload.notification, ...state.notifications],
                toasts: [...state.toasts, toast]
            };
        }

        case notificationConstants.REMOVE:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (value, index) => index !== action.payload.notificationIndex
                )
            };
        case notificationConstants.CLEAR_TOASTS:
            return {
                ...state,
                toasts: []
            };
        default:
            return state;
    }
};

const intentToIcon = (intent: Intent) => {
    switch (intent) {
        case Intent.SUCCESS:
            return "tick";
        case Intent.DANGER:
            return "warning-sign";
        default:
            return "info-sign";
    }
};
