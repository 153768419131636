import axios from 'axios';
import BaseResource from './BaseResource';
import { ICloudJob, ICloudJobFull, ICloudJobPost, IJob, IJobPatch, IJobPost, IMultipartMetadata } from './Job.types';
import { sleep } from '../../AppClient/utils';
import { BioLibApiError, BioLibServerMaximumAttemptsReached, BioLibServerUnavailableError } from '../errors';
import { AddLogMessage, SetProgress } from '../../AppClient';

export default class Job extends BaseResource {
    protected readonly basePath = '/api/jobs';

    public create(data: IJobPost): Promise<IJob> {
        return this.post<IJob>({ url: `/`, data, shouldAuthenticate: true });
    }

    public update(jobId: string, data: IJobPatch): Promise<IJobPatch> {
        return this.patch<IJobPatch>({ url: `/${jobId}/`, data, shouldAuthenticate: true });
    }

    public async createCloudJob(
        data: ICloudJobPost,
        logMessage: AddLogMessage,
        setProgressCompute: SetProgress,
    ): Promise<ICloudJobFull> {
        let cloudJob: ICloudJob | ICloudJobFull | null = null;

        for (let retryCount = 0; retryCount < 5; retryCount += 1) {
            try {
                cloudJob = await this.post<ICloudJob | ICloudJobFull>({
                    data,
                    url: `/cloud/`,
                    shouldAuthenticate: true,
                });
                break;
            } catch (error) {
                if (error instanceof BioLibServerUnavailableError) {
                    await sleep(1000);
                } else {
                    throw error;
                }
            }
        }
        if (cloudJob === null) {
            throw new BioLibServerMaximumAttemptsReached('Reached retry limit for cloud job creation');
        }

        if (cloudJob.is_compute_node_ready) {
            if (cloudJob.compute_node_info === null) {
                throw new BioLibApiError('Compute node info was null');
            }
            return cloudJob as ICloudJobFull;
        }

        for (let retryCount = 1; retryCount < 750; retryCount += 1) {
            await sleep(Math.min(10, retryCount) * 1000);
            cloudJob = await this.get<ICloudJob | ICloudJobFull>({
                url: `/cloud/${cloudJob.public_id}/status/`,
                shouldAuthenticate: true,
            });

            if (cloudJob.is_compute_node_ready) {
                if (cloudJob.compute_node_info === null) {
                    throw new BioLibApiError('Compute node info was null');
                }
                return cloudJob as ICloudJobFull;
            }

            if (retryCount === 1) {
                logMessage(`Cloud: Starting a compute node, this might take a few minutes.`);
                // Set progress to 5 to indicate that we are waiting for a compute node
                setProgressCompute(5);
            } else {
                logMessage(`Cloud: Server capacity is being allocated. Please wait...`);
            }
        }
        throw new BioLibServerMaximumAttemptsReached('Reached timeout for compute node initialization');
    }

    public async uploadModuleInput(job: IJob, moduleInputSerialized: Uint8Array): Promise<void> {
        const headers = { 'Job-Auth-Token': job.auth_token };

        await this.retryFunction({
            actionName: 'start multipart upload',
            function: async () => {
                await this.post({
                    data: {},
                    headers,
                    shouldAuthenticate: true,
                    url: `/${job.public_id}/storage/input/start_upload/`,
                });
            },
        });

        const chunkSize = 50_000_000;  // 50 MB
        const partCount = Math.ceil(moduleInputSerialized.byteLength / chunkSize);
        const parts: IMultipartMetadata[] = [];
        console.debug(`Uploading multipart input of size ${moduleInputSerialized.byteLength} in ${partCount} parts`);
        for (let i = 0; i < partCount; i += 1) {
            const part_number = i + 1;

            const presigned_upload_url = await this.retryFunction<string>({
                actionName: 'get multipart upload URL',
                function: async () => {
                    const { presigned_upload_url } = await this.get<{ presigned_upload_url: string; }>({
                        headers,
                        shouldAuthenticate: true,
                        params: { part_number },
                        url: `/${job.public_id}/storage/input/presigned_upload_url/`,
                    });
                    const urlObject = new URL(presigned_upload_url);
                    return `${location.origin}${urlObject.pathname}${urlObject.search}`;
                },
            });

            const start = i * chunkSize;
            const end = start + chunkSize;
            const data = moduleInputSerialized.slice(start, end);

            const ETag = await this.retryFunction<string>({
                actionName: 'upload multipart data',
                function: async () => {
                    const response = await axios.put(
                        presigned_upload_url,
                        data,
                        {
                            headers: {
                                'BioLib-Client': 'biolib-frontend',
                                'Content-Type': '',
                            },
                        },
                    );
                    return response.headers.etag;
                },
            });

            parts.push({ ETag, PartNumber: part_number });
        }

        await this.retryFunction({
            actionName: 'complete multipart upload',
            function: async () => {
                await this.post({
                    headers,
                    data: { parts, size_bytes: moduleInputSerialized.byteLength },
                    shouldAuthenticate: true,
                    url: `/${job.public_id}/storage/input/complete_upload/`,
                });
            },
        });
    }

    private async retryFunction<T = void>(args: { function: () => Promise<T>; actionName: string }): Promise<T> {
        const maxRetries = 3;
        for (let i = 0; i < maxRetries; i += 1) {
            try {
                if (i !== 0) {
                    const secondsToWait = 3;
                    console.log(`Retrying ${args.actionName} in ${secondsToWait}...`);
                    await sleep(secondsToWait * 1000);
                }
                return await args.function();
            } catch (error) {
                console.error(`Failed to ${args.actionName} got error: `, error);
                if (i === maxRetries - 1) {
                    throw new Error(`Exceeded retry limit when trying to ${args.actionName}. Got error: ${error}`);
                }
            }
        }
        throw new Error(`Exceeded retry limit when trying to ${args.actionName}`);
    }
}
