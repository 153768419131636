export const allowedFileExtensions = [
    '3line',
    'aln',
    'bai',
    'bin',
    'cif',
    'csv',
    'ct',
    'dat',
    'eps',
    'fasta',
    'fsa',
    'gff3',
    'gif',
    'hmm',
    'jpeg',
    'jpg',
    'json',
    'md',
    'mmcif',
    'pdb',
    'pdf',
    'pfs',
    'png',
    'pptx',
    'ps',
    'svg',
    'txt',
    'xlsx',
    'yml',
    'zip',
];

export function isoTimeStampToHumanReadable(timestamp: string) {
    const padLeft = (num: number): string => {
        if (num < 10) {
            return `0${num.toString()}`;
        }
        return num.toString();
    };
    const date = new Date(parseInt(timestamp, 10));
    const year = date.getFullYear();
    const month = padLeft(date.getMonth() + 1); // Add 1 as getMonth returns 0-indexed months
    const day = padLeft(date.getDate());
    const hours = padLeft(date.getHours());
    const minutes = padLeft(date.getMinutes());
    const seconds = padLeft(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
