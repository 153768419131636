import { AccountUuid, IAccount, IAccountAsAdmin, IAccountAsMember, ISlimApp } from "@biolibtech/biolib-js";
import { Reducer } from "redux";
import { ILoadingState, LoadingState } from '../../utils';
import { EmptyPaginationState, IPaginationState } from "../common.types";
import { RootActions } from "../rootActions";
import userConstants from "../user/userConstants";
import { AccountsConstants } from "./accountsConstants";

export interface IAccountsAppsDict {
    [account_id: string]: IPaginationState<ISlimApp>;
}

export interface IAccountsDict {
    [account_id: string]: IAccount | IAccountAsMember | IAccountAsAdmin;
}

export interface IOrganizationMemberAccounts {
    // TODO: Figure out if account_id is better for this
    [org_account_handle: string]: (IAccountAsMember | IAccountAsAdmin)[];
}

export interface IAccountsReducer {
    accountsAppsDict: IAccountsAppsDict;
    accountsDict: IAccountsDict;
    appFeed: IPaginationState<ISlimApp>;
    appFeedAccountHandles: string[];
    organizationMemberAccounts: IOrganizationMemberAccounts;
    profileAccountId: AccountUuid | undefined;
    accountInviteLoadingState: ILoadingState;
    createAccountLoadingState: ILoadingState;
    accountLoadingState: ILoadingState;
    accountDeleteMemberLoadingState: ILoadingState;
    accountUpdateMemberRoleLoadingState: ILoadingState;
    accountListMembersLoadingState: ILoadingState;
}

const defaultState: IAccountsReducer = {
    accountsAppsDict: {},
    accountsDict: {},
    appFeed: EmptyPaginationState,
    appFeedAccountHandles: [],
    organizationMemberAccounts: {},
    profileAccountId: undefined,

    accountInviteLoadingState: LoadingState.default,
    createAccountLoadingState: LoadingState.default,
    accountLoadingState: LoadingState.default,
    accountDeleteMemberLoadingState: LoadingState.default,
    accountListMembersLoadingState: LoadingState.default,
    accountUpdateMemberRoleLoadingState: LoadingState.default,
};

export const accountsReducer: Reducer<IAccountsReducer, RootActions> = (
    state = defaultState,
    action
): IAccountsReducer => {
    switch (action.type) {

        case userConstants.SIGN_OUT:
        case userConstants.CLEAR_USER_STATE: {
            return {
                ...state,
                appFeedAccountHandles: [],
                appFeed: EmptyPaginationState,
            }
        }

        case userConstants.SET_USER:
        case userConstants.CREATE_USER_SUCCESS:
        case userConstants.SIGN_IN_SUCCESS: {
            return {
                ...state,
                accountsDict: action.payload.accountsDict,
            }
        }

        case userConstants.UPDATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                accountsDict: {
                    ...state.accountsDict,
                    [action.payload.account.public_id]: action.payload.account,
                }
            }
        }

        case AccountsConstants.FETCH_APPS_SUCCESS: {
            return {
                ...state,
                accountsAppsDict: {
                    ...state.accountsAppsDict,
                    [action.payload.accountId]: action.payload.accountApps,
                },
            }
        }

        case AccountsConstants.APPS_CHANGE_PAGE_SUCCESS: {
            return {
                ...state,
                accountsAppsDict: {
                    ...state.accountsAppsDict,
                    [action.payload.accountId]: {
                        ...state.accountsAppsDict[action.payload.accountId],
                        currentPage: action.payload.newPageId,
                        pages: action.payload.newPage ? {
                            ...state.accountsAppsDict[action.payload.accountId].pages,
                            [action.payload.newPageId]: action.payload.newPage,
                        } : state.accountsAppsDict[action.payload.accountId].pages,
                    }
                }
            }
        }

        case AccountsConstants.APP_FEED_FETCH_SUCCESS: {
            return {
                ...state,
                appFeed: action.payload.appFeed,
                appFeedAccountHandles: action.payload.accountHandles,
            }
        }

        case AccountsConstants.APP_FEED_CHANGE_PAGE_SUCCESS: {
            return {
                ...state,
                appFeed: {
                    ...state.appFeed,
                    currentPage: action.payload.newPageId,
                    pages: !action.payload.newPage ? state.appFeed.pages : {
                        ...state.appFeed.pages,
                        [action.payload.newPageId]: action.payload.newPage,
                    },
                }
            }
        }

        case AccountsConstants.CREATE: {
            return {
                ...state,
                createAccountLoadingState: LoadingState.begin,
            }
        }

        case AccountsConstants.CREATE_SUCCESS: {
            return {
                ...state,
                createAccountLoadingState: LoadingState.success,
                appFeedAccountHandles: [...state.appFeedAccountHandles, action.payload.account.account_handle],
                accountsDict: {
                    ...state.accountsDict,
                    [action.payload.account.public_id]: action.payload.account,
                }
            }
        }

        case AccountsConstants.CREATE_FAILURE: {
            return {
                ...state,
                createAccountLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case userConstants.CHANGE_EMAIL_SUCCESS: {
            return {
                ...state,
                accountsDict: action.payload.accountsDict,
            }
        }

        case AccountsConstants.DELETE_MEMBER: {
            return {
                ...state,
                accountDeleteMemberLoadingState: LoadingState.begin,
            }
        }

        case AccountsConstants.DELETE_MEMBER_SUCCESS: {
            return {
                ...state,
                accountDeleteMemberLoadingState: LoadingState.success,
            }
        }

        case AccountsConstants.DELETE_MEMBER_FAILURE: {
            return {
                ...state,
                accountDeleteMemberLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case AccountsConstants.UPDATE_MEMBER_ROLE: {
            return {
                ...state,
                accountUpdateMemberRoleLoadingState: LoadingState.begin,
            }
        }

        case AccountsConstants.UPDATE_MEMBER_ROLE_SUCCESS: {
            return {
                ...state,
                accountUpdateMemberRoleLoadingState: LoadingState.success,
            }
        }

        case AccountsConstants.UPDATE_MEMBER_ROLE_FAILURE: {
            return {
                ...state,
                accountUpdateMemberRoleLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case AccountsConstants.FETCH_MEMBERS: {
            return {
                ...state,
                accountListMembersLoadingState: LoadingState.begin,
            }
        }

        case AccountsConstants.FETCH_MEMBERS_SUCCESS: {
            return {
                ...state,
                accountListMembersLoadingState: LoadingState.success,
                organizationMemberAccounts: {
                    ...state.organizationMemberAccounts,
                    [action.payload.org_account_handle]: action.payload.member_accounts,
                }
            }
        }

        case AccountsConstants.FETCH_MEMBERS_FAILURE: {
            return {
                ...state,
                accountListMembersLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case AccountsConstants.INVITE: {
            return {
                ...state,
                accountInviteLoadingState: LoadingState.begin,
            }
        }

        case AccountsConstants.INVITE_SUCCESS: {
            return {
                ...state,
                accountInviteLoadingState: LoadingState.success,
            }
        }

        case AccountsConstants.INVITE_FAILURE: {
            return {
                ...state,
                accountInviteLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case AccountsConstants.INVITE_CLEAR_LOADING_STATE: {
            return {
                ...state,
                accountInviteLoadingState: LoadingState.default,
            }
        }

        case AccountsConstants.ACCOUNT_FETCH: {
            return {
                ...state,
                accountLoadingState: LoadingState.begin,
            }
        }

        case AccountsConstants.ACCOUNT_FETCH_SUCCESS: {
            return {
                ...state,
                accountsDict: {
                    ...state.accountsDict,
                    [action.payload.account.public_id]: action.payload.account,
                },
                accountLoadingState: LoadingState.success,
                profileAccountId: action.payload.account.public_id,
            }
        }

        case AccountsConstants.ACCOUNT_FETCH_FAILURE: {
            return {
                ...state,
                accountLoadingState: LoadingState.failure(action.errorMessage),
                profileAccountId: undefined,
            }
        }

        case AccountsConstants.CLEAR_ACCOUNT_LOADING_STATE: {
            return {
                ...state,
                accountLoadingState: LoadingState.default,
                profileAccountId: undefined,
            }
        }

        default:
            return {
                ...state,
            }

    }
};
