import * as React from 'react';
import { Col, Row } from './common';

const HrWithOr: React.FC = () => (
    <Row className='is-mobile is-vcentered is-centered has-text-centered' style={{ marginBottom: 0 }}>
        <Col style={{ paddingBottom: 0 }}>
            <hr/>
        </Col>
        <Col className='is-1' style={{ paddingBottom: 0 }}>
            <b>or</b>
        </Col>
        <Col style={{ paddingBottom: 0 }}>
            <hr/>
        </Col>
    </Row>
)

export default HrWithOr;
