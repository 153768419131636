import * as React from 'react';
import { Button, Intent, Pre, ProgressBar } from '@blueprintjs/core';
import { IJobWrapper } from '../../../types';
import AppLog from '../../AppLog';
import ReportErrorButton from './ReportErrorButton';
import {
    ComputeLimitExceededPleaseContactBioLibCallout,
    ComputeLimitExceededPleaseSignInCallout,
    ComputeNodeConnectionLostCallout
} from './warningCallouts';
import {
    ComputeContainerRanOutOfMemory,
    ComputeLimitExceededPleaseContactBioLib,
    ComputeLimitExceededPleaseSignIn,
    CustomComputeNodeConnectionLost,
    JobExceededMaxRuntime,
} from '@biolibtech/biolib-js';
import { ContainerRanOutOfMemory, JobRuntimeLimitExceeded } from './remoteErrorCallouts';

interface IProps {
    jobWrapper: IJobWrapper;
    onQuit?: () => void;
}

const ErrorTab: React.FC<IProps> = (props) => {
    const { jobWrapper, onQuit } = props;
    return (
        <div className='content'>
            <div className='body'>
                <Body jobWrapper={jobWrapper}/>
            </div>
            {!onQuit ? null :
                <footer className='card-footer'>
                    <div className='card-footer-item'>
                        <Button intent={Intent.DANGER} large={true} onClick={onQuit}>Quit</Button>
                    </div>
                </footer>
            }
        </div>
    )
}

export default ErrorTab;

const Body: React.FC<IProps> = ({ jobWrapper }) => {
    const { error } = jobWrapper;
    if (error == null) {
        return null;
    }

    if (error.name === new CustomComputeNodeConnectionLost('').name) {
        return (<ComputeNodeConnectionLostCallout computeNodeUrl={jobWrapper.job.custom_compute_node_url}/>);
    }

    if (error.name === new ComputeLimitExceededPleaseSignIn('').name) {
        return (<ComputeLimitExceededPleaseSignInCallout/>);
    }

    if (error.name === new ComputeLimitExceededPleaseContactBioLib('').name) {
        return (<ComputeLimitExceededPleaseContactBioLibCallout/>);
    }

    if (error.name === new JobExceededMaxRuntime('').name) {
        return (<JobRuntimeLimitExceeded/>);
    }

    if (error.name === new ComputeContainerRanOutOfMemory('').name) {
        return (<ContainerRanOutOfMemory/>);
    }

    return (
        <>
            <h3>Compute Error</h3>
            <ProgressBar
                intent={Intent.DANGER}
                animate={false}
                value={jobWrapper.progressCompute === 0 ? 1 : jobWrapper.progressCompute}
            />
            <div className='mt-2 flex flex-row justify-between space-x-4 items-center'>
                <p>The following error occurred. If this was unexpected, please report the error.</p>
                <div className='w-36'>
                    <ReportErrorButton
                        errorReportSendingState={jobWrapper.errorReportSendingState}
                        jobId={jobWrapper.job.public_id}
                    />
                </div>
            </div>
            {/* TODO: fix biolib errors such that toString() always should be used */}
            <Pre>{error.stack ? error.stack : error.toString()}</Pre>
            <br/>
            <AppLog logMessages={jobWrapper.logMessages} isOpenByDefault={true}/>
        </>
    );
}
