import { ISlimApp } from "@biolibtech/biolib-js";
import { Reducer } from "redux";
import { RootActions } from "../rootActions";
import userConstants from "../user/userConstants";
import { IAppIdToFavoriteId } from "./app.types";
import appsConstants from "./appsConstants";

export interface IAppsState {
    appIdToFavoriteId: IAppIdToFavoriteId;
    favoriteApps: ISlimApp[];
    trendingApps: ISlimApp[];
}

const defaultState: IAppsState = {
    appIdToFavoriteId: {},
    favoriteApps: [],
    trendingApps: [],
};

export const appsReducer: Reducer<IAppsState> = (state = defaultState, action: RootActions) => {
    switch (action.type) {
        case appsConstants.TRENDING_FETCH_SUCCESS: {
            return {
                ...state,
                trendingApps: action.payload.trendingApps,
            };
        }

        case appsConstants.FAVORITES_FETCH_SUCCESS: {
            return {
                ...state,
                favoriteApps: action.payload.favoriteApps,
                appIdToFavoriteId: action.payload.appIdToFavoriteId,
            };
        }

        case userConstants.SIGN_OUT: {
            return {
                ...defaultState,
                trendingApps: state.trendingApps,
            };
        }

        default:
            return state;
    }
};
