import SignIn from '../../../components/SignIn';
import userActions from '../../../state/user/userActions';
import userFlowSlice from '../userFlowSlice';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../../state/rootReducer';

const mapStateToProps = (state: IRootState) => ({
    gitHubSignInLoadingState: state.userFlow.gitHubSignInLoadingState,
    isSignedIn: state.user.isSignedIn,
    isSignInPasswordFieldShown: state.userFlow.isSignInPasswordFieldShown,
    signInLoadingState: state.user.signInLoadingState,
    enterpriseSignInLoadingState: state.userFlow.enterpriseSignInLoadingState,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        clearSignInErrors: userActions.clearSignInErrors,
        gitHubSignIn: userFlowSlice.actions.gitHubSignIn,
        onResetPassword: userActions.resetPassword,
        signIn: userActions.signIn,
        enterpriseSignIn: userFlowSlice.actions.enterpriseSignIn,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
