import BaseResource from './BaseResource';
import { IDeprecatedTag } from './AppTypes';
import { Paginated } from './common.types';

export default class DeprecatedTag extends BaseResource {
    protected readonly basePath = '/api/deprecated_tags';

    public fetch(tagId: string, params?: { page?: number, page_size?: number }): Promise<IDeprecatedTag> {
        return this.get({ url: `/${tagId}/`, params });
    }

    public fetchAll(): Promise<Paginated<IDeprecatedTag>> {
        return this.get<Paginated<IDeprecatedTag>>({ url: '/' });
    }
}
