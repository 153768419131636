import BaseResource from './BaseResource';
import { Inquiry } from './Inquiries.types';

export default class Inquiries extends BaseResource {
    protected readonly basePath = '/api/system/inquiries';

    public async send(data: Inquiry): Promise<void> {
        return this.post({ url: `/`, data });
    }
}
