import { AxiosRequestConfig, ResponseType } from 'axios';
import HttpClient from '../HttpClient';

interface IBaseRequest {
    headers?: Record<string, string>;
    params?: object;
    responseType?: ResponseType;
    shouldAuthenticate?: boolean;
    timeout?: number;
    url: string;
}

interface IPostOrPatch extends IBaseRequest {
    data: object;
    shouldSendAsFormData?: boolean;
}

export default abstract class BaseResource {
    protected basePath = '';
    protected readonly httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    protected post<T>(options: IPostOrPatch): Promise<T> {
        const { url, data, params, shouldAuthenticate, shouldSendAsFormData, responseType, headers } = options;
        const config = BaseResource.getAxiosRequestConfig({ url, shouldAuthenticate, params, responseType, headers });
        const dataToPost = shouldSendAsFormData ? BaseResource.createFormData(data) : data;
        return this.httpClient.axios.post<T>(this.getFullUrl(url), dataToPost, config)
            .then(response => response.data);
    }

    protected get<T>(options: IBaseRequest): Promise<T> {
        const { url, params, shouldAuthenticate, responseType, headers } = options;
        const config = BaseResource.getAxiosRequestConfig({ url, shouldAuthenticate, params, responseType, headers });
        return this.httpClient.axios.get<T>(this.getFullUrl(url), config)
            .then(response => response.data);
    }

    protected patch<T>(options: IPostOrPatch): Promise<T> {
        const { url, data, params, shouldAuthenticate, shouldSendAsFormData, responseType, headers } = options;
        const config = BaseResource.getAxiosRequestConfig({ url, shouldAuthenticate, params, responseType, headers });
        const dataToPatch = shouldSendAsFormData ? BaseResource.createFormData(data) : data;
        return this.httpClient.axios.patch<T>(this.getFullUrl(url), dataToPatch, config)
            .then(response => response.data);
    }

    protected delete<T>(options: IBaseRequest): Promise<T> {
        const { url, params, shouldAuthenticate, responseType, headers } = options;
        const config = BaseResource.getAxiosRequestConfig({ url, shouldAuthenticate, params, responseType, headers });
        return this.httpClient.axios.delete<T>(this.getFullUrl(url), config)
            .then(response => response.data);
    }

    private getFullUrl(url: string): string {
        return this.basePath !== '' ? `${this.basePath}${url}` : url;
    }

    private static getAxiosRequestConfig(options: IBaseRequest): AxiosRequestConfig {
        const { shouldAuthenticate, params, timeout, responseType, headers } = options;
        const config: AxiosRequestConfig = { headers: headers ?? {} };
        if (shouldAuthenticate) {
            // The access token will be added by the axios request interceptor
            config.headers = { ...config.headers, Authorization: true };
        }
        if (params) {
            config.params = params;
        }
        if (timeout) {
            config.timeout = timeout;
        }
        if (responseType) {
            config.responseType = responseType;
        }
        return config;
    }

    public static createFormData(object: { [property: string]: any }, form?: FormData, namespace?: string): FormData {
        const formData = form || new FormData();
        for (const property in object) {
            if (!object.hasOwnProperty(property) || object[property] === undefined) {
                continue;
            }
            const formKey = namespace ? `${namespace}[${property}]` : property;
            const isPropertyFile = object[property] instanceof Blob;

            if (object[property] instanceof Date) {
                formData.append(formKey, object[property].toISOString());
            } else if (typeof object[property] === 'object' && !isPropertyFile) {
                this.createFormData(object[property], formData, formKey);
            } else {
                formData.append(formKey, object[property]);
            }
        }
        return formData;
    }
}
