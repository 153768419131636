import {
    Button,
    FileInput,
    FormGroup,
    Icon,
    IconName,
    InputGroup,
    Intent,
    NumericInput,
    Radio,
    RadioGroup,
    Switch,
    Tab,
    Tabs,
    TextArea,
} from "@blueprintjs/core";
import { MaybeElement } from "@blueprintjs/core/src/common/props";
import { FieldProps, useField } from "formik";
import * as React from "react";
import RenderMarkdown from '../../RenderMarkdown';

export enum LabelInfoStates { none, optional, required}

function labelInfoReactNode(labelInfoState: LabelInfoStates | undefined): React.ReactNode {
    switch (labelInfoState) {
        case LabelInfoStates.required:
            return <span className={"has-text-danger"}>*</span>;
        case LabelInfoStates.optional:
            return <span className={"has-text-grey"}>(optional)</span>;
        default:
            return <></>;
    }
}

interface ICustomInputProps {
    autocompleteDisabled?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    helperText?: string | MaybeElement;
    label?: React.ReactNode;
    labelInfo?: string;
    labelInfoState?: LabelInfoStates;
    leftIcon?: IconName | MaybeElement;
    placeholder?: string;
    required?: boolean;
    rightElement?: JSX.Element;
    type?: string;
    isSmall?: boolean;
}

// eslint-disable-next-line react/display-name
export const CustomInput = (customProps: ICustomInputProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;

    const {
        autocompleteDisabled,
        autoFocus,
        disabled,
        helperText,
        label,
        labelInfo,
        labelInfoState,
        leftIcon,
        placeholder,
        required,
        rightElement,
        type,
    } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : helperText ? helperText : ''}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfo ? labelInfo : labelInfoReactNode(labelInfoState)}
        >
            <InputGroup
                {...field}
                autoComplete={!autocompleteDisabled ? '' : type === 'password' ? 'new-password' : 'off'}
                autoFocus={autoFocus}
                disabled={disabled}
                intent={isError ? Intent.DANGER : Intent.NONE}
                large={!customProps.isSmall}
                leftIcon={leftIcon}
                placeholder={placeholder}
                required={required}
                rightElement={rightElement}
                type={type}
            />
        </FormGroup>
    )
};

interface ICustomNumberInputProps extends ICustomInputProps {
    min?: number;
    max?: number;
    minorStepSize?: number;
}

// eslint-disable-next-line react/display-name
export const CustomNumberInput = (customProps: ICustomNumberInputProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;

    const {
        autoFocus,
        disabled,
        helperText,
        label,
        labelInfoState,
        leftIcon,
        max,
        min,
        minorStepSize,
        placeholder,
        required,
        rightElement,
        type,
    } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : helperText ? helperText : ''}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <NumericInput
                {...field}
                onValueChange={(valueAsNumber) => props.form.setFieldValue(field.name, valueAsNumber)}
                autoFocus={autoFocus}
                disabled={disabled}
                intent={isError ? Intent.DANGER : Intent.NONE}
                large={!customProps.isSmall}
                leftIcon={leftIcon}
                max={max}
                min={min}
                minorStepSize={minorStepSize}
                placeholder={placeholder}
                required={required}
                rightElement={rightElement}
                type={type}
                fill={true}
            />
        </FormGroup>
    )
};

interface ICustomTextAreaProps {
    label?: React.ReactNode;
    placeholder?: string;
    required?: boolean;
    labelInfoState?: LabelInfoStates;
    minHeight?: string;
    maxHeight?: string;
}

// eslint-disable-next-line react/display-name
export const CustomTextArea = (customProps: ICustomTextAreaProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;
    const { label, placeholder, required, labelInfoState, minHeight, maxHeight } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : ""}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <TextArea
                {...field}
                fill={true}
                growVertically={true}
                intent={isError ? Intent.DANGER : Intent.NONE}
                large={true}
                placeholder={placeholder}
                required={required}
                style={{ minHeight, maxHeight }}
            />
        </FormGroup>
    )
};

interface IMarkdownTextEditorProps extends ICustomTextAreaProps {
    allowLinks?: boolean;
}

// eslint-disable-next-line react/display-name
export const MarkdownTextEditor = (customProps: IMarkdownTextEditorProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;
    const { label, placeholder, required, labelInfoState, minHeight, maxHeight, allowLinks } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : ""}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <Tabs
                animate={true}
                vertical={false}
                large={true}
            >
                <Tab
                    id='edit'
                    title='Edit'
                    panel={
                        <TextArea
                            {...field}
                            fill={true}
                            growVertically={true}
                            intent={isError ? Intent.DANGER : Intent.NONE}
                            large={true}
                            placeholder={placeholder}
                            required={required}
                            style={{ minHeight, maxHeight }}
                        />}
                />
                <Tab
                    id='preview'
                    title='Preview'
                    panel={
                        <div style={{ width: '100%', height: '100%', minHeight, maxHeight }} className='bp4-input'>
                            <div style={{ padding: '10px' }}>
                                <RenderMarkdown string={field.value}/>
                            </div>
                        </div>
                    }
                />
            </Tabs>
        </FormGroup>
    )
};

export const EmailInput: React.FC<FieldProps> = CustomInput({
    leftIcon: "envelope",
    placeholder: "Email",
    required: true,
    type: "email",
    autoFocus: true,
});

interface IPasswordInputProps {
    autocompleteDisabled?: boolean;
    autoFocus?: boolean;
    label?: React.ReactNode;
    placeholder?: string;
}

export const CustomPasswordInput = (customProps: IPasswordInputProps) => (props: FieldProps) => {
    const { label, placeholder, autocompleteDisabled, autoFocus } = customProps;

    return CustomInput({
        autocompleteDisabled,
        autoFocus,
        label,
        leftIcon: "key",
        placeholder,
        required: true,
        type: "password",
    })(props)
};

interface ISubmitButtonProps {
    className?: string
    errorMessage?: string;
    extraLarge?: boolean;
    fill?: boolean;
    icon?: IconName;
    intent?: Intent;
    isSubmitting: boolean;
    rightIcon?: IconName;
    text: string;
    disabled?: boolean;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = (props) => {
    const isError = props.errorMessage !== "";
    return (
        <FormGroup
            helperText={isError ? props.errorMessage : ""}
            intent={isError ? Intent.DANGER : Intent.NONE}
            className={props.className}
        >
            <Button
                disabled={props.disabled}
                fill={props.fill}
                icon={props.icon}
                intent={props.intent ? props.intent : Intent.SUCCESS}
                large={true}
                loading={props.isSubmitting}
                rightIcon={props.rightIcon}
                type={"submit"}
            >
                {props.extraLarge ?
                    <div style={{ padding: "1rem 2.5rem" }}>
                        <b>{props.text}</b>
                    </div> : <b>{props.text}</b>}
            </Button>
        </FormGroup>
    )
};

export interface ISelectOption {
    text: string;
    value: any;
    icon?: IconName | MaybeElement;
    description?: string;
    disabled?: boolean;
    isTextNotBold?: boolean;
}

export interface IStringDict {
    [extension: string]: string
}

export function selectOptionsFromDictionary(stringDict: IStringDict): ISelectOption[] {
    return Object.keys(stringDict).reduce((accumulator: ISelectOption[], key) => {
        return [...accumulator, { value: key, text: stringDict[key] }]
    }, []);
}

interface CustomSelectProps {
    label?: string;
    options: ISelectOption[];
    placeholder?: string;
    required?: boolean;
    labelInfoState?: LabelInfoStates;
    fill?: boolean;
    isSmall?: boolean;
}

// eslint-disable-next-line react/display-name
export const CustomSelect = (customProps: CustomSelectProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;

    const { label, options, placeholder, required, labelInfoState, fill } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : ''}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <div
                className={`bp4-select ${customProps.isSmall ? '' : 'bp4-large'}`}
                style={fill ? { width: "100%" } : {}}
            >
                <select {...field} placeholder={placeholder} required={required}>
                    {options.map((option, i: number) => (
                        <option key={i} value={option.value} disabled={option.disabled}>{option.text}</option>
                    ))}
                </select>
            </div>
        </FormGroup>
    )
};

interface CustomRadioProps {
    inline?: boolean;
    label?: string;
    options: ISelectOption[];
    labelInfoState?: LabelInfoStates;
}

// eslint-disable-next-line react/display-name
export const CustomRadio = (customProps: CustomRadioProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;

    const { inline, label, options, labelInfoState } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : ""}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <RadioGroup
                {...field}
                selectedValue={field.value}
                inline={inline}
            >
                {options.map((option, i: number) => (
                    <Radio key={i} value={option.value} disabled={option.disabled}>
                        <span className={`is-size-3 ${option.value === field.value ? "has-text-primary" : ""}`}>
                            <Icon icon={option.icon}/>
                        </span>
                        <span style={{ display: "inline-block", marginLeft: "10px" }}>
                            <span className={option.isTextNotBold ? '' : "has-text-weight-bold"}>{option.text}</span>
                            <br/>
                            <span>{option.description}</span>
                        </span>
                    </Radio>
                ))}
            </RadioGroup>
        </FormGroup>
    )
};

interface CustomSwitchProps {
    label?: string;
    innerLabelChecked?: string;
    innerLabel?: string;
    inline?: boolean;
    labelInfoState?: LabelInfoStates;
    helperText?: string;
}

// eslint-disable-next-line react/display-name
export const CustomSwitch = (customProps: CustomSwitchProps) => (props: FieldProps) => {
    const [field, meta] = useField(props.field);
    const isError = meta.touched && meta.error;

    const { label, innerLabel, innerLabelChecked, inline, labelInfoState, helperText } = customProps;

    return (
        <FormGroup
            helperText={isError ? meta.error : helperText ? helperText : ''}
            inline={inline}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={<b>{label}</b>}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <Switch
                {...field}
                checked={field.value}
                className={inline ? "is-marginless" : ""}
                innerLabel={innerLabel}
                innerLabelChecked={innerLabelChecked}
                large={true}
            />
        </FormGroup>
    )
};

// eslint-disable-next-line react/display-name
export const SlimCustomSwitch = (customProps: CustomSwitchProps) => (props: FieldProps) => {
    const [field] = useField(props.field);
    const { innerLabel, innerLabelChecked, inline } = customProps;

    return (
        <Switch
            {...field}
            checked={field.value}
            className={inline ? "is-marginless" : ""}
            innerLabel={innerLabel}
            innerLabelChecked={innerLabelChecked}
            large={true}
        />
    )
};

interface CustomFileInput {
    errorMessage: string | undefined;
    file: File | undefined;
    fill?: boolean;
    handleFileChange: (files: FileList | null) => void;
    label?: string;
    large?: boolean;
    labelInfoState?: LabelInfoStates;
}

export const CustomFileInput: React.FC<CustomFileInput> = (props) => {
    const { label, file, errorMessage, handleFileChange, fill, large, labelInfoState } = props;
    const isError = errorMessage !== undefined && errorMessage !== "";

    return (
        <FormGroup
            label={label ? <b>{label}</b> : ""}
            helperText={isError ? errorMessage : ""}
            intent={isError ? Intent.DANGER : Intent.NONE}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <FileInput
                text={file !== undefined && file !== null ? file.name : "Choose a file..."}
                hasSelection={file !== undefined && file !== null}
                onInputChange={event => handleFileChange(event.currentTarget.files)}
                fill={fill}
                large={large}
                className={isError ? "bp4-intent-danger" : ""}
            />
        </FormGroup>
    )
};

interface SimpleFileInput {
    fill?: boolean;
    label?: string;
    labelInfoState?: LabelInfoStates;
    large?: boolean;
    placeholder?: string;
    accept?: string;
    helperText?: string;
}

// eslint-disable-next-line react/display-name
export const SimpleFileInput = (customProps: SimpleFileInput) => (props: FieldProps) => {
    const { setFieldValue, setFieldTouched } = props.form;

    const [field, meta] = useField(props.field);
    const { value, name } = field;
    const isError = meta.touched && meta.error;

    const { label, labelInfoState, large, fill, placeholder, accept, helperText } = customProps;

    const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
        setFieldTouched(name);
        const files = event.currentTarget.files;
        if (files !== undefined && files !== null && files[0] !== undefined) {
            setFieldValue(name, files[0], true);
        } else {
            setFieldValue(name, undefined, true);
        }
    };

    return (
        <FormGroup
            helperText={isError ? meta.error : helperText}
            intent={isError ? Intent.DANGER : Intent.NONE}
            label={label ? <b>{label}</b> : ""}
            labelInfo={labelInfoReactNode(labelInfoState)}
        >
            <FileInput
                inputProps={{ accept, name }}
                className={isError ? "bp4-intent-danger" : ""}
                fill={fill}
                hasSelection={value !== undefined && value !== null}
                large={large}
                onInputChange={handleFileChange}
                text={value !== undefined && value !== null ?
                    value.name : placeholder ? placeholder : "Choose a file..."}
            />
        </FormGroup>
    )
};

export const ParagraphsByNewline: React.FC<{ text?: string }> = (props) => {
    const { text } = props;
    if (!text) {
        return null;
    }

    return (
        <>
            {text.split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        </>
    )
};
