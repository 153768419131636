import { BioLibApiError } from './errors';

export function axiosResponseRejectedHandler(error: any): any {
    // see https://github.com/axios/axios/pull/1419
    function isNetworkError(error: any) {
        return !!error.isAxiosError && !error.response;
    }

    if (error instanceof BioLibApiError) {
        return Promise.reject(error);
    }
    if (isNetworkError(error)) {
        return Promise.reject(new BioLibApiError('Network Error: Lost Connection to Server'));
    }
    return Promise.reject(BioLibApiError.generate(error.response));
}
