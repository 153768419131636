import config from '../../config';
import userFlowSlice from './userFlowSlice';
import { Action } from '@reduxjs/toolkit';
import { generateRandomStateString, parseSsoCallbackUrlAndCheckState } from './utils/randomState';
import { getUserWithAccountDict } from '../../sagas/userSaga';
import { appendSlashIfNotEndsWithSlash, handleError } from '../../utils';
import { navigate } from 'gatsby';
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { userActions } from '../../state/user/userActions';
import {
    BioLibSingleton,
    BioLibValidationError,
    ISignInResponse,
    ISsoEnterpriseAuthorizationResponse
} from '@biolibtech/biolib-js';
import AuthCookie from './utils/AuthCookie';
import userConstants from '../../state/user/userConstants';
import { SignInAction } from '../../state/user/userActions.types';
import notificationActions from '../../state/notification/notificationActions';
import LocalStorageManager, { ISignInState } from './utils/LocalStorageManager';
import { IRootState } from '../../state/rootReducer';

function getSignInStateFromWindowLocation(): ISignInState {
    let redirectTo = appendSlashIfNotEndsWithSlash(location.pathname);
    if (['/sign-in/', '/sign-up/', '/sign-up/accept-terms/'].includes(redirectTo)) {
        redirectTo = '/';
    }
    return { redirectTo };
}

function* saveSignInStateToLocalStorage() {
    let signInState: ISignInState | null = yield select((state: IRootState) => state.userFlow.signInState);
    if (signInState === null) {
        signInState = getSignInStateFromWindowLocation();
    }
    LocalStorageManager.signInState = signInState;
}

function* watchGitHubSignIn() {
    yield takeLatest(userFlowSlice.actions.gitHubSignIn, function* (action) {
        if (userFlowSlice.actions.gitHubSignIn.match(action)) {
            try {
                if (!config.githubSsoClientId) {
                    throw new Error('GitHub SSO not configured in frontend');
                }
                const payload = action.payload as { areTermsAcceptedForSignUp?: boolean };
                const randomState = generateRandomStateString();
                LocalStorageManager.githubSsoState = {
                    randomState,
                    areTermsAccepted: payload.areTermsAcceptedForSignUp === true,
                };

                yield saveSignInStateToLocalStorage();

                window.location.href = `https://github.com/login/oauth/authorize\
?scope=${config.githubSsoScope}\
&client_id=${config.githubSsoClientId}\
&state=${randomState}
&redirect_uri=${config.baseURL}/sign-in/callback/github/`;

            } catch (error) {
                LocalStorageManager.githubSsoState = null;
                yield call(handleError, {
                    error,
                    actionToDispatch: userFlowSlice.actions.gitHubSignInFailure,
                    friendlyErrorMessageToPrepend: 'Sign in failed',
                    showToast: true,
                });
            }
        }
    })
}

function* watchGitHubHandleCallback() {
    yield takeLatest(userFlowSlice.actions.gitHubHandleCallback, function* (action) {
        if (userFlowSlice.actions.gitHubHandleCallback.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const { code, areTermsAccepted } = parseSsoCallbackUrlAndCheckState(action.payload.url);

                const { user, refresh_token }: ISignInResponse = yield biolib.sso.gitHubSignIn({
                    are_terms_accepted: areTermsAccepted,
                    code,
                });

                AuthCookie.set(refresh_token);
                yield put(userActions.signInSuccess(getUserWithAccountDict(user)));
                yield put(userFlowSlice.actions.gitHubSignInSuccess());
            } catch (error) {
                if (error instanceof BioLibValidationError &&
                    error.validationErrors && Object.keys(error.validationErrors).includes('are_terms_accepted')
                ) {
                    yield navigate('/sign-up/accept-terms/');
                } else {
                    yield navigate('/');
                    yield call(handleError, {
                        error,
                        actionToDispatch: userFlowSlice.actions.gitHubSignInFailure,
                        friendlyErrorMessageToPrepend: 'Sign in failed',
                        showToast: true,
                    });
                }
            } finally {
                LocalStorageManager.githubSsoState = null;
            }
        }
    })
}

function setCookieAndRedirectToEnterpriseSso(response: ISsoEnterpriseAuthorizationResponse): void {
    const { sso_connection_public_id, authorization_url } = response;
    const expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + 5);
    const cookieName = `${config.isDev ? '' : '__Secure-'}blb_sso_connection_public_id`;

    // Set the cookie
    document.cookie = `\
${cookieName}=${sso_connection_public_id}; \
domain=${config.domainForCookies}; \
expires=${expiryDate.toUTCString()}; \
SameSite=None; \
path=/; \
secure;`;

    window.location.href = authorization_url;
}

function* watchEnterpriseSignIn() {
    yield takeLatest(userFlowSlice.actions.enterpriseSignIn.type, function* (action: Action) {
        if (userFlowSlice.actions.enterpriseSignIn.match(action)) {
            try {
                const { email } = action.payload;
                const biolib = BioLibSingleton.get();
                const emailParts = email.split('@');
                if (emailParts.length !== 2) {
                    throw new Error('Invalid email');
                }

                const domain = emailParts[1];

                const response: ISsoEnterpriseAuthorizationResponse = yield biolib.sso.enterpriseGetAuthorizationUrl({
                    domain,
                });

                yield saveSignInStateToLocalStorage();
                setCookieAndRedirectToEnterpriseSso(response);
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: userFlowSlice.actions.enterpriseSignInFailure,
                    friendlyErrorMessageToPrepend: 'Sign in failed',
                    showToast: false,
                });
            }
        }
    })
}

function* watchEnterpriseDeploymentSignIn() {
    yield takeLatest(userFlowSlice.actions.enterpriseDeploymentSignIn.type, function* (action: Action) {
        if (userFlowSlice.actions.enterpriseDeploymentSignIn.match(action)) {
            try {
                const { ssoConnectionUuid } = action.payload;
                const biolib = BioLibSingleton.get();

                const response: ISsoEnterpriseAuthorizationResponse = yield biolib.sso.enterpriseGetAuthorizationUrl({
                    sso_connection_public_id: ssoConnectionUuid,
                });

                yield saveSignInStateToLocalStorage();
                setCookieAndRedirectToEnterpriseSso(response);
            } catch (error) {
                yield navigate('/sign-in/error/');
                yield call(handleError, {
                    error,
                    actionToDispatch: userFlowSlice.actions.enterpriseSignInFailure,
                    friendlyErrorMessageToPrepend: 'Sign in failed',
                    showToast: true,
                });
            }
        }
    })
}

function* watchRequireSignIn() {
    yield takeLatest(userFlowSlice.actions.requireSignIn, function* (action: Action) {
        if (userFlowSlice.actions.requireSignIn.match(action)) {
            yield put(userFlowSlice.actions.setSignInState({ signInState: getSignInStateFromWindowLocation() }));
            yield navigate('/sign-in/');
            yield put(notificationActions.addInfo('Please sign in to view the page'));
        }
    })
}

function* watchSignInWithPassword() {
    yield takeLatest(userConstants.SIGN_IN, function* (action: SignInAction) {
        const signInState: ISignInState | null = yield select((state: IRootState) => state.userFlow.signInState);
        if (signInState === null) {
            yield put(userFlowSlice.actions.setSignInState({ signInState: getSignInStateFromWindowLocation() }));
        }
    })
}

function* clearSignInState() {
    // CLEAR_SIGN_IN_ERRORS action is dispatched on SignIn component unmount
    yield takeLatest([userConstants.CLEAR_SIGN_IN_ERRORS, userConstants.SIGN_OUT], function* () {
        yield put(userFlowSlice.actions.setSignInState({ signInState: null }));
    })
}

function* userFlow() {
    while (true) {
        yield take(userConstants.SIGN_IN_SUCCESS);
        const signInState: ISignInState | null = yield select((state: IRootState) => state.userFlow.signInState);
        if (signInState !== null) {
            yield navigate(signInState.redirectTo);
            yield put(userFlowSlice.actions.setSignInState({ signInState: null }));
        }
    }
}

export default function* userFlowSaga() {
    yield fork(clearSignInState);
    yield fork(userFlow);
    yield fork(watchEnterpriseDeploymentSignIn);
    yield fork(watchEnterpriseSignIn);
    yield fork(watchGitHubHandleCallback);
    yield fork(watchGitHubSignIn);
    yield fork(watchRequireSignIn);
    yield fork(watchSignInWithPassword);
}
