import appsConstants from "../state/apps/appsConstants";
import userActions from "../state/user/userActions";
import { appsActions } from "../state/apps/appsActions";
import { BioLibApiError, BioLibSingleton, ISlimApp, Paginated, IFavorite } from "@biolibtech/biolib-js";
import { channel } from "redux-saga";
import { IAppIdToFavoriteId } from "../state/apps/app.types";
import { IRootState } from "../state/rootReducer";
import { isAppFavorite } from "../utils";
import { notificationActions } from "../state/notification/notificationActions";
import { NotificationTypes } from "../state/notification/notificationActionTypes";
import { call, fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import {
    FavoriteAddAction,
    FavoriteAddSuccessAction,
    FavoriteRemoveAction,
    FavoriteRemoveSuccessAction,
} from "../state/apps/appsActionTypes";

const notificationChannel = channel();

export function* fetchFavorites() {
    try {
        // TODO: Fix this when it is the biolib api has a methods for favorites
        const biolib = BioLibSingleton.get();
        const response: Paginated<IFavorite> = yield biolib.favorites.fetch();

        const favoritesArray: ISlimApp[] = [];
        const appIdToFavoriteId: IAppIdToFavoriteId = {};

        response.results.forEach(favoriteWrapper => {
            favoritesArray.push(favoriteWrapper.app);
            appIdToFavoriteId[favoriteWrapper.app.public_id] = favoriteWrapper.public_id;
        });

        yield put(appsActions.favoritesFetchSuccess(favoritesArray, appIdToFavoriteId));

    } catch (error) {
        // TODO: Handle
        yield put(appsActions.favoritesFetchFailed(error.errors));
    }
}

function* watchFavoritesFetch() {
    yield takeLatest(appsConstants.FAVORITES_FETCH, fetchFavorites);
}

function* addFavorite(action: FavoriteAddAction) {
    try {
        // TODO: Fix this when it is the biolib api has a methods for favorites
        const state: IRootState = yield select();

        if (!state.user.isSignedIn) {
            yield put(userActions.authenticationRequired("You must be signed in to add favorites"));
            return;
        }

        if (isAppFavorite(state.apps.appIdToFavoriteId, action.payload.appId)) {
            yield put(appsActions.favoriteAddFailed("App already in favorites"));
            return;
        }
        yield BioLibSingleton.get().favorites.add({ app: action.payload.appId });
        yield put(appsActions.favoriteAddSuccess(action.payload.appId));
    } catch (error) {
        if (error instanceof BioLibApiError) {
            yield put(appsActions.favoriteAddFailed(`Unable to add favorite: ${error.toString()}`));
        } else {
            yield put(appsActions.favoriteAddFailed(`Unable to add favorite`));
        }
    }
}

function* watchFavoriteAdd() {
    yield takeEvery(appsConstants.FAVORITE_ADD, addFavorite);
}

function* handleFavoriteAddSuccess(action: FavoriteAddSuccessAction) {
    yield put(notificationActions.addInfo("App added to favorites", NotificationTypes.APPS));
    yield call(fetchFavorites);
}

function* watchFavoriteAddSuccess() {
    yield takeEvery(appsConstants.FAVORITE_ADD_SUCCESS, handleFavoriteAddSuccess);
}

function* removeFavorite(action: FavoriteRemoveAction) {
    try {
        const state: IRootState = yield select();
        if (!state.user.isSignedIn) {
            yield put(userActions.authenticationRequired("Please sign in"));
            return;
        }

        if (!isAppFavorite(state.apps.appIdToFavoriteId, action.payload.appId)) {
            yield put(appsActions.favoriteRemoveFailed("App not in favorites"));
            return;
        }

        const favoriteUuid = state.apps.appIdToFavoriteId[action.payload.appId];
        yield BioLibSingleton.get().favorites.remove(favoriteUuid);
        yield put(appsActions.favoriteRemoveSuccess(action.payload.appId));

    } catch (error) {
        // TODO: Handle this properly
        yield put(appsActions.favoriteRemoveFailed(error.errors));
    }
}

function* watchRemoveFavorite() {
    yield takeEvery(appsConstants.FAVORITE_REMOVE, removeFavorite);
}

function* handleFavoriteRemoveSuccess(action: FavoriteRemoveSuccessAction) {
    yield put(notificationActions.addInfo("App removed from favorites", NotificationTypes.APPS));
    yield call(fetchFavorites);
}

function* watchFavoriteRemoveSuccess() {
    yield takeEvery(appsConstants.FAVORITE_REMOVE_SUCCESS, handleFavoriteRemoveSuccess);
}

function* watchNotificationChannel() {
    while (true) {
        const action = yield take(notificationChannel);
        yield put(action);
    }
}

export default function* appsSaga() {
    yield fork(watchFavoritesFetch);
    yield fork(watchFavoriteAdd);
    yield fork(watchRemoveFavorite);
    yield fork(watchFavoriteAddSuccess);
    yield fork(watchFavoriteRemoveSuccess);
    yield fork(watchNotificationChannel);
}
