import React from 'react';
import { MenuDivider } from '@blueprintjs/core';
import { IAccount } from '@biolibtech/biolib-js';
import MenuLink from './MenuLink';
import clsx from 'clsx';
import { useLogoutMutation } from '../../features/auth/authApi';

interface IProps {
    account?: IAccount;
    isTeamSubdomain: boolean;
    isLarge?: boolean;
    onClick?: () => void;
}

export default function UserMenu(props: IProps) {
    const [logout, { isLoading: isLoggingOut }] = useLogoutMutation();
    return (
        <div className={clsx('md:w-48 p-1', { 'space-y-3': props.isLarge })}>
            <MenuLink
                icon='dashboard'
                isLarge={props.isLarge}
                onClick={props.onClick}
                text='Dashboard'
                to='/'
                useFullPath={props.isTeamSubdomain}
            />
            <MenuLink
                icon='th'
                isLarge={props.isLarge}
                onClick={props.onClick}
                text='Results'
                to='/results/'
                useFullPath={props.isTeamSubdomain}
            />
            <MenuLink
                icon='user'
                isLarge={props.isLarge}
                onClick={props.onClick}
                text='Profile'
                to={props.account ? `/${props.account.account_handle}/` : undefined}
                useFullPath={props.isTeamSubdomain}
            />
            <MenuLink
                icon='cog'
                isLarge={props.isLarge}
                onClick={props.onClick}
                text='Settings'
                to='/settings/'
                useFullPath={props.isTeamSubdomain}
            />
            <MenuDivider/>
            <MenuLink
                disableIsActive={true}
                icon='log-out'
                isLarge={props.isLarge}
                onClick={logout}
                text='Logout'
                useFullPath={props.isTeamSubdomain}
                isLoading={isLoggingOut}
            />
        </div>
    );
};
