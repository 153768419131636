import axios from 'axios';
import CustomDataView from './CustomDataView';

interface IMetadata {
    version: number;
    type: number;
    stdoutLength: number;
    stderrLength: number;
    filesInfoLength: number;
    filesDataLength: number;
    exitCode: number;
}

export class LazyLoadedFile {
    private readonly _path: string;
    private readonly _dataStartByteOffset: number;
    private readonly _sizeInBytes: number;
    public getData: () => Promise<Uint8Array>;

    constructor(path: string, dataStartByteOffset: number, sizeInBytes: number, getData: () => Promise<Uint8Array>) {
        this._dataStartByteOffset = dataStartByteOffset;
        this._path = path;
        this._sizeInBytes = sizeInBytes;
        this.getData = getData;
    }

    get dataStartByteOffset(): number {
        return this._dataStartByteOffset;
    }

    get path(): string {
        return this._path;
    }

    get sizeInBytes(): number {
        return this._sizeInBytes;
    }
}

export default class ModuleOutputV2 {
    private readonly version = 1;
    private readonly type = 11;
    private readonly metadataByteLengths: Record<keyof IMetadata, number> = {
        // Note: the order is important here
        version: 1,
        type: 1,
        stdoutLength: 8,
        stderrLength: 8,
        filesInfoLength: 8,
        filesDataLength: 8,
        exitCode: 2,
    };
    private readonly metadataLength: number = Object.values(this.metadataByteLengths).reduce(
        (previous, current) => previous + current
    );

    private url: string;
    private metadata: IMetadata | null = null;
    private stdout: Uint8Array | null = null;
    private stderr: Uint8Array | null = null;
    private files: LazyLoadedFile[] | null = null;
    private entireArrayBufferCached: ArrayBuffer | null = null;

    constructor(url: string) {
        this.url = url;
        this.metadata = null;
    }

    public async fetchAllData(): Promise<void> {
        const sizeInBytes = await this.getSizeInBytes();
        if (sizeInBytes > 1_000_000_000) {
            throw new Error('Result is too large to download in browser. Please use the BioLib Python client instead.');
        }
        const { data } = await axios.get<ArrayBuffer>(this.url, {
            responseType: 'arraybuffer',
            headers: {
                'BioLib-Client': 'biolib-frontend',
                'Cache-Control': 'no-cache',
            },
        });
        this.entireArrayBufferCached = data;
    }

    public async getSizeInBytes(): Promise<number> {
        const metadata = await this.getMetadata();
        return this.metadataLength
            + metadata.filesInfoLength
            + metadata.filesDataLength
            + metadata.stderrLength
            + metadata.stdoutLength;
    }

    public async getExitCode(): Promise<number> {
        const metadata = await this.getMetadata();
        return metadata.exitCode;
    }

    public async getStdout(): Promise<Uint8Array> {
        if (this.stdout === null) {
            const metadata = await this.getMetadata();
            const start = this.metadataLength;
            const end = start + metadata.stdoutLength - 1;
            this.stdout = await this.getBytes(start, end);
        }
        return this.stdout;
    }

    public async getStderr(): Promise<Uint8Array> {
        if (this.stderr === null) {
            const metadata = await this.getMetadata();
            const start = this.metadataLength + metadata.stdoutLength;
            const end = start + metadata.stderrLength - 1;
            this.stderr = await this.getBytes(start, end);
        }
        return this.stderr;
    }

    public async getFiles(): Promise<LazyLoadedFile[]> {
        if (this.files === null) {
            this.files = [];
            const metadata = await this.getMetadata();
            if (metadata.filesInfoLength === 0) {
                return this.files;
            }

            const filesInfoStart = this.metadataLength + metadata.stdoutLength + metadata.stderrLength;
            const filesInfoEnd = filesInfoStart + metadata.filesInfoLength - 1;

            const uint8Array = await this.getBytes(filesInfoStart, filesInfoEnd);
            const dataView = new CustomDataView(
                uint8Array.buffer,
                uint8Array.byteOffset,
                uint8Array.byteLength,
            );

            const textDecoder = new TextDecoder('utf-8', { fatal: true });

            let filesDataPointer = filesInfoStart + metadata.filesInfoLength;
            let filesInfoPointer = 0;
            while (filesInfoPointer < uint8Array.byteLength) {
                const pathLength = Number(dataView.getUint32(filesInfoPointer));
                filesInfoPointer += 4;

                const pathBuffer = uint8Array.slice(filesInfoPointer, filesInfoPointer + pathLength);
                filesInfoPointer += pathLength;

                const dataLength = Number(dataView.getBigUint64(filesInfoPointer));
                filesInfoPointer += 8;

                const path = textDecoder.decode(pathBuffer);
                const dataStart = filesDataPointer;
                const dataEnd = filesDataPointer + dataLength - 1;
                filesDataPointer += dataLength;

                const getFileData = async () => await this.getBytes(dataStart, dataEnd);
                this.files.push(new LazyLoadedFile(path, dataStart, dataLength, getFileData));
            }
        }
        return this.files;
    }

    private async getBytes(start: number, end: number): Promise<Uint8Array> {
        const expectedLength = end - start + 1;
        if (expectedLength < 0) {
            throw Error(`GetBytes got invalid range: ${start}-${end}`);
        }
        if (expectedLength === 0) {
            return new Uint8Array(0);
        }

        if (this.entireArrayBufferCached !== null) {
            return new Uint8Array(this.entireArrayBufferCached, start, expectedLength);
        }

        const { data } = await axios.get<ArrayBuffer>(this.url, {
            headers: {
                'BioLib-Client': 'biolib-frontend',
                'range': `bytes=${start}-${end}`,
                'Cache-Control': 'no-cache',
            },
            responseType: 'arraybuffer',
        });
        if (data.byteLength !== expectedLength) {
            throw Error(
                `GetBytes returned an arraybuffer of unexpected length. 
Got ${data.byteLength} expected ${expectedLength}`
            )
        }
        return new Uint8Array(data);
    }

    private async getMetadata(): Promise<IMetadata> {
        if (this.metadata === null) {
            const metadataArrayBuffer = await this.getBytes(0, this.metadataLength);
            const dataView = new CustomDataView(
                metadataArrayBuffer.buffer,
                metadataArrayBuffer.byteOffset,
                metadataArrayBuffer.byteLength,
            );

            const metadata: Partial<IMetadata> = {};
            let pointer = 0;
            for (const [key, byteLength] of Object.entries(this.metadataByteLengths)) {
                let value: number;
                switch (byteLength) {
                    case 1:
                        value = dataView.getUint8(pointer);
                        break;
                    case 2:
                        value = dataView.getUint16(pointer);
                        break;
                    case 8:
                        value = Number(dataView.getBigUint64(pointer));
                        break;
                    default:
                        throw new Error('Unexpected byte length in metadata conversion');
                }
                metadata[key as keyof IMetadata] = value;
                pointer += byteLength;
            }

            const { version, type } = metadata;
            if (version !== this.version) {
                throw Error(`Unsupported BioLib Binary Format version: Got ${version} expected ${this.version}`);
            }
            if (type !== this.type) {
                throw Error(`Unsupported BioLib Binary Format type: Got ${type} expected ${this.type}`);
            }
            this.metadata = metadata as IMetadata;
        }
        return this.metadata;
    }
}
