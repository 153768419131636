import { IAccount, IAccountAsAdmin, IAccountAsMember } from "@biolibtech/biolib-js";
import { IAccountsDict } from "./accounts/accountsReducer";
import { IRootState } from "./rootReducer";

export function getIntrinsicAccount(state: IRootState): IAccountAsAdmin | undefined {
    return state.user.intrinsicAccountId ?
        state.accounts.accountsDict[state.user.intrinsicAccountId] as IAccountAsAdmin : undefined;
}

export function getOrganizationAccountsArray(state: IRootState): (IAccountAsAdmin | IAccountAsMember)[] {
    const accountsDict = state.accounts.accountsDict;
    if (!accountsDict) {
        return [];
    }
    return state.user.organizationIds.reduce((accumulator: (IAccountAsAdmin | IAccountAsMember)[], orgId) =>
        ([...accumulator, accountsDict[orgId] as IAccountAsAdmin | IAccountAsMember]), []);
}

export function getAccountByHandle(account_handle?: string, accountsDict?: IAccountsDict): IAccount | undefined {
    if (!account_handle || !accountsDict) {
        return undefined
    }
    return Object.values(accountsDict).find(account => account.account_handle === account_handle);
}

export function getAccountHandles(accountsDict: IAccountsDict): string[] {
    const accountHandlesInFeed: string[] = [];
    for (const accountId in accountsDict) {
        accountHandlesInFeed.push(accountsDict[accountId].account_handle)
    }
    return accountHandlesInFeed;
}
