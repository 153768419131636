import {
    AccountHandle,
    AccountUuid,
    IAccountAsAdmin,
    IAccount,
    IAccountAsMember,
    IAccountMemberResponse,
    IAccountUpdate,
    IRemoteHostResponse,
    OrganizationRole,
    ISsoConnectionResponse,
    ISsoDomainResponse,
    ISsoConnectionRequestData,
    ISsoDomainRequestData,
    ISaml2SsoConnection,
} from './Account.types';
import BaseResource from './BaseResource';
import { IRemoteHost } from './AppTypes';
import { Paginated } from './common.types';

export default class Account extends BaseResource {
    protected readonly basePath = '/api/account';

    public create(data: IAccountUpdate): Promise<IAccountAsAdmin> {
        return this.post({ url: `/`, data, shouldAuthenticate: true });
    }

    public fetch(org_account_handle: string): Promise<IAccount | IAccountAsMember | IAccountAsAdmin> {
        return this.get({ url: `/${org_account_handle}/`, shouldAuthenticate: true });
    }

    public fetchMembers(org_account_handle: string): Promise<IAccountAsMember[]> {
        return this.get<{ members: IAccountAsMember[] }>({
            url: `/${org_account_handle}/members/`,
            shouldAuthenticate: true
        }).then(({ members }) => members);
    }

    public deleteMember(org_account_handle: string, member_account_handle: string): Promise<void> {
        return this.delete({
            shouldAuthenticate: true,
            url: `/${org_account_handle}/members/${member_account_handle}/`,
        });
    }

    public updateMemberRole(
        org_account_handle: string,
        member_account_handle: string,
        data: { role: OrganizationRole }
    ): Promise<IAccountMemberResponse> {
        return this.patch<IAccountMemberResponse>({
            shouldAuthenticate: true,
            url: `/${org_account_handle}/members/${member_account_handle}/`, data,
        });
    }

    public inviteMember(
        org_account_handle: string,
        data: { role: OrganizationRole; account_handle: AccountHandle } | { role: OrganizationRole; email: string },
    ): Promise<void> {
        return this.post<void>({
            shouldAuthenticate: true,
            url: `/${org_account_handle}/members/invite/`, data,
        });
    }

    public acceptInvitation(data: { invite_id: string; token: string; }): Promise<void> {
        return this.post<void>({
            data,
            shouldAuthenticate: true,
            url: `/organization_invitations/`,
        });
    }

    public leaveOrganization(org_account_handle: string): Promise<void> {
        return this.post<void>({
            data: {},
            shouldAuthenticate: true,
            url: `/${org_account_handle}/members/leave/`,
        });
    }

    public update(account_handle: string, data: IAccountUpdate): Promise<IAccountAsMember | IAccountAsAdmin> {
        return this.patch({
            data,
            shouldAuthenticate: true,
            shouldSendAsFormData: true,
            url: `/${account_handle}/`,
        });
    }

    public fetchRemoteHosts(
        accountId: AccountUuid,
        params: { page?: number; page_size?: number }
    ): Promise<Paginated<IRemoteHostResponse>> {
        return this.get<Paginated<IRemoteHostResponse>>({
            params,
            url: `/${accountId}/remote_hosts/`,
            shouldAuthenticate: true
        });
    }

    public addRemoteHost(accountId: AccountUuid, data: IRemoteHost): Promise<IRemoteHostResponse> {
        return this.post<IRemoteHostResponse>({
            data,
            shouldAuthenticate: true,
            url: `/${accountId}/remote_hosts/`,
        });
    }

    public deleteRemoteHost(accountId: AccountUuid, remoteHostId: string): Promise<void> {
        return this.delete({
            shouldAuthenticate: true,
            url: `/${accountId}/remote_hosts/${remoteHostId}/`,
        });
    }

    public createSsoConnection(
        accountId: AccountUuid,
        data: ISsoConnectionRequestData,
    ): Promise<ISsoConnectionResponse> {
        return this.post({
            data,
            shouldAuthenticate: true,
            // 's/' is a workaround since the SSO resources are under /accounts/
            url: `s/${accountId}/sso_connections/`,
        })
    }

    public fetchSsoConnections(accountId: AccountUuid): Promise<Paginated<ISsoConnectionResponse>> {
        return this.get({
            shouldAuthenticate: true,
            // 's/' is a workaround since the SSO resources are under /accounts/
            url: `s/${accountId}/sso_connections/`,
        })
    }

    public updateSsoConnection(
        accountId: AccountUuid,
        ssoConnectionId: string,
        data: { saml2_sso_connection: Partial<ISaml2SsoConnection>; },
    ): Promise<ISsoConnectionResponse> {
        return this.patch({
            data,
            shouldAuthenticate: true,
            // 's/' is a workaround since the SSO resources are under /accounts/
            url: `s/${accountId}/sso_connections/${ssoConnectionId}/`,
        })
    }

    public createSsoDomain(
        accountId: AccountUuid,
        ssoConnectionId: string,
        data: ISsoDomainRequestData,
    ): Promise<ISsoDomainResponse> {
        return this.post({
            data,
            shouldAuthenticate: true,
            // 's/' is a workaround since the SSO resources are under /accounts/
            url: `s/${accountId}/sso_connections/${ssoConnectionId}/domains/`,
        })
    }

    public deleteSsoDomain(accountId: AccountUuid, ssoConnectionId: string, domainId: string): Promise<void> {
        return this.delete({
            shouldAuthenticate: true,
            // 's/' is a workaround since the SSO resources are under /accounts/
            url: `s/${accountId}/sso_connections/${ssoConnectionId}/domains/${domainId}/`,
        })
    }
}
