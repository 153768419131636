import * as React from 'react';
import { ILogMessage } from '../types';
import { isoTimeStampToHumanReadable } from '../utils/renderResults';
import { Collapsable } from './common';

interface IProps {
    logMessages: ILogMessage[];
    isOpenByDefault?: boolean;
}

const AppLog: React.FC<IProps> = (props) => (
    <Collapsable title='Application log' isOpenByDefault={props.isOpenByDefault}>
        <AppLogContent logMessages={props.logMessages} />
    </Collapsable>
);

const AppLogContent: React.FC<{ logMessages: ILogMessage[]; }> = ({ logMessages }) => {
    const elementId = 'application-log';
    React.useEffect(() => {
        const scrollDiv = document.getElementById(elementId);
        if (scrollDiv !== null) {
            scrollDiv.scrollTo({ top: scrollDiv.scrollHeight });
        }
        // The useEffect function is called when the messages prop is updated
    }, [logMessages]);

    return (
        <div className='relative' style={{ height: 150 }}>
            <div className='absolute h-full w-full'>
                <pre className='h-full m-0 p-3 bg-white text-gray-900 font-mono text-sm' id={elementId}>
                    {logMessages.map(message =>
                        `[${isoTimeStampToHumanReadable(message.timestamp)}] ${limitMessage(message.message, 500)}\n`
                    )}
                </pre>
            </div>
        </div>
    );
};

function limitMessage(message: string, maxLength: number) {
    if (message.length > maxLength) {
        return (
            `${message.slice(0, maxLength)} [This log line has been truncated to the first ${maxLength} characters]`);
    }
    return message;
}

export default AppLog;
