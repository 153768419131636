import React, { Component, ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    fallback?: ReactNode;
}

interface IState {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(error: Error): IState {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error('Error caught by Error Boundary:', error, errorInfo);
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            return this.props.fallback ?? <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
