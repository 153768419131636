import { IAppVersionFull, ISetting } from '@biolibtech/biolib-js';

export default function getSortedAppVersion(appVersion: IAppVersionFull): IAppVersionFull {
    function getSortedSettings(settings: ISetting[]): ISetting[] {
        const sortedSettings = [...settings].sort((a: ISetting, b: ISetting) => (a.order - b.order));
        for (const index in sortedSettings) {
            sortedSettings[index] = {
                ...sortedSettings[index],
                sub_arguments: getSortedSettings(sortedSettings[index].sub_arguments)
            };
        }
        return sortedSettings;
    }

    return { ...appVersion, settings: getSortedSettings(appVersion.settings) };
}
