import { FieldInputProps } from 'formik';
import React from 'react';

interface IProps extends FieldInputProps<string> {
    placeholder: string;
}

export default function TextInput(props: IProps) {
    return (
        <input
            {...props}
            className='w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-800 sm:text-sm sm:leading-6'
        />
    );
}
