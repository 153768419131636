import * as React from 'react'
import { Callout } from '@blueprintjs/core';
import { Link } from 'gatsby';
import { FiExternalLink } from 'react-icons/all';
import HelpButtons from './HelpButtons';

interface IInitializationErrorProps {
    errorName: string;
    title: string;
}

const InitializationError: React.FC<IInitializationErrorProps> = (props) => (
    <>
        <Callout icon='info-sign' title={`Initialization Error: ${props.errorName}`}/>
        <div className='p-1 mt-3'>
            <h3 className='text-xl font-bold'>
                {props.title}
            </h3>
            <div className='my-2 max-w-xl text-large text-gray-700'>
                {props.children}
            </div>
        </div>
        <hr className='my-5'/>
        <HelpButtons/>
    </>
)

export const ComputeNodeConnectionLostCallout: React.FC<{ computeNodeUrl?: string }> = (props) => (
    <InitializationError errorName='Server Unreachable' title='Whoops – could not connect to compute node'>
        <p>
            We could not reach the compute node at <b>{props.computeNodeUrl}</b>.
            Please make sure this compute node is reachable and running.
            <br/><br/>
            Alternatively, re-configure your compute node settings{' '}
            <Link to='/settings/compute/' className='inline-flex'>
                here <FiExternalLink className='mt-1 ml-1'/>
            </Link>.
        </p>
    </InitializationError>
)

export const ComputeLimitExceededPleaseSignInCallout: React.FC = () => (
    <InitializationError errorName='Server Busy' title='Whoops – it looks like this application is very popular 🙌'>
        <p>
            The server running this application is under a high load at the moment.
            Please try again later ⏰
            <br/><br/>
            Alternatively, if you{' '}
            <Link to='/sign-in/' className='inline-flex font-bold'>
                sign in <FiExternalLink className='mt-1 ml-1'/>
            </Link> we know that you are not a robot and can start
            another server right away!
        </p>
    </InitializationError>
)

export const ComputeLimitExceededPleaseContactBioLibCallout: React.FC = () => (
    <InitializationError errorName='Server Busy' title='Whoops – it looks like this application is very popular 🙌'>
        <p>
            The server running this application is under a high load at the moment.
            Please try again later ⏰
            <br/><br/>
            Alternatively, you can reach out to us{' '}
            <Link to='/contact/' className='inline-flex font-bold'>
                here <FiExternalLink className='mt-1 ml-1'/>
            </Link> to get additional server capacity.
        </p>
    </InitializationError>
)
