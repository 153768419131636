export interface IGithubSsoState {
    randomState: string;
    areTermsAccepted: boolean;
}

export interface ISignInState {
    redirectTo: string;
}

export interface ILocalStorageStateWrapper<T> {
    createdAt: string;
    data: T;
}

enum LocalStorageKey {
    ghSsoState = 'ghSsoState',
    signInState = 'signInState',
}

export default class LocalStorageManager {

    public static get githubSsoState(): IGithubSsoState | null {
        return this.getState<IGithubSsoState>(LocalStorageKey.ghSsoState);
    }

    public static set githubSsoState(state: IGithubSsoState | null) {
        this.saveState(LocalStorageKey.ghSsoState, state);
    }

    public static get signInState(): ISignInState | null {
        return this.getState<ISignInState>(LocalStorageKey.signInState);
    }

    public static set signInState(state: ISignInState | null) {
        this.saveState(LocalStorageKey.signInState, state);
    }

    private static getState<T>(key: LocalStorageKey, lifeTimeMinutes = 15): null | T {
        const jsonString: string | null = localStorage.getItem(key);
        if (jsonString === null) {
            return null;
        }
        const stateWrapper: ILocalStorageStateWrapper<T> = JSON.parse(jsonString);
        const expiryDate = new Date(stateWrapper.createdAt);
        expiryDate.setMinutes(expiryDate.getMinutes() + lifeTimeMinutes);

        if (expiryDate < new Date()) {
            localStorage.removeItem(LocalStorageKey.signInState);
            return null;
        }
        return stateWrapper.data;
    }

    private static saveState(key: LocalStorageKey, data: null | Record<string, any>): void {
        if (data === null) {
            localStorage.removeItem(key);
        } else {
            const stateWrapper: ILocalStorageStateWrapper<Record<string, any>> = {
                data,
                createdAt: new Date().toISOString(),
            }
            localStorage.setItem(key, JSON.stringify(stateWrapper));
        }
    }
}
