import { IApp, IAppVersion } from '@biolibtech/biolib-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILoadingState, LoadingState } from '../../utils';

export interface AppVersionDict {
    [public_id: string]: IAppVersion;
}

export interface AppPageState {
    app: IApp | undefined;
    appVersionSelectedId: string | undefined;
    appVersionDict: AppVersionDict;
    appLoadingState: ILoadingState;
    appVersionLoadingState: ILoadingState;
}

const initialState: AppPageState = {
    app: undefined,
    appVersionSelectedId: undefined,
    appLoadingState: LoadingState.default,
    appVersionLoadingState: LoadingState.default,
    appVersionDict: {},
}

const appPageSlice = createSlice({
    name: 'appPage',
    initialState,
    reducers: {
        loadAppByUri(
            state,
            action: PayloadAction<{ uri: string }>
        ) {
            state.appLoadingState = LoadingState.begin;
        },
        loadAppSuccess(
            state,
            action: PayloadAction<{ app: IApp, newAppVersionId: string, newAppVersion: IAppVersion }>
        ) {
            state.app = action.payload.app;
            state.appVersionDict[action.payload.newAppVersionId] = action.payload.newAppVersion;
            state.appVersionSelectedId = action.payload.newAppVersionId;
            state.appLoadingState = LoadingState.success;
        },
        loadAppFailed(state, action: PayloadAction<string>) {
            state.appLoadingState = LoadingState.failure(action.payload);
        },
        changeAppVersion(state, action: PayloadAction<string>) {
            state.appVersionLoadingState = LoadingState.begin;
        },
        changeAppVersionSuccess(state, action: PayloadAction<{ newAppVersionId: string, newAppVersion: IAppVersion }>) {
            state.appVersionDict[action.payload.newAppVersionId] = action.payload.newAppVersion;
            state.appVersionSelectedId = action.payload.newAppVersionId;
            state.appVersionLoadingState = LoadingState.success;
        },
        changeAppVersionFailure(state, action: PayloadAction<string>) {
            state.appVersionLoadingState = LoadingState.failure(action.payload);
        },
        clearApp() {
            return initialState;
        },
    },
})

export default appPageSlice;
