import { FieldInputProps, FormikProps } from 'formik';

export function saveFileListToFormikField(
    files: FileList | null,
    field: FieldInputProps<any>,
    form: FormikProps<any>,
    allowMultipleFiles: boolean
) {
    if (!files || files.length === 0) {
        form.setFieldValue(field.name, '');
    } else {
        if (!allowMultipleFiles && files.length > 1) {
            form.setFieldTouched(field.name, true, false);
            form.setFieldError(field.name, 'Only a single file is accepted');
        }
        form.setFieldValue(field.name, files);
    }
}

export function combineFileLists(fileListArray: FileList[]): FileList {
    const dataTransfer = new DataTransfer();
    const existingFileNames = new Set<string>();

    fileListArray
        .filter((fileList) => fileList != null)
        .flatMap((fileList) => Array.from(fileList))
        .forEach((file) => {
            if (!existingFileNames.has(file.name)) {
                dataTransfer.items.add(file);
                existingFileNames.add(file.name);
            }
        });

    return dataTransfer.files;
}

export function createSingletonFileList(file: File): FileList {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    return dataTransfer.files;
};
