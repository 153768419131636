import { FocusStyleManager, Position, Toast, Toaster } from '@blueprintjs/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../config';
import AppOverlay from '../features/appRun/components/AppOverlay';
import { notificationActions } from '../state/notification/notificationActions';
import '../styles/main.scss';
import '../styles/fonts.scss';
import { IRootState } from '../state/rootReducer';
import { getIntrinsicAccount } from '../state';
import { bindActionCreators, Dispatch } from 'redux';
import { RootActions } from '../state/rootActions';
import { connect } from 'react-redux';
import Footer from './Footer';
import Navbar from './Navbar';
import * as queryString from 'query-string';
import { navigate } from 'gatsby';

// Do not show blue box around all Blueprint buttons
FocusStyleManager.onlyShowFocusOnTabs();

const mapStateToProps = (state: IRootState) => ({
    intrinsicAccount: getIntrinsicAccount(state),
    isSignedIn: state.user.isSignedIn,
    isStateInitialized: state.userFlow.isStateInitialized,
    toasts: state.notification.toasts,
});

const mapDispatchToProps = (dispatch: Dispatch<RootActions>) => bindActionCreators(
    {
        addErrorToast: notificationActions.addError,
        clearToasts: notificationActions.clearToasts,
    },
    dispatch
);

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface IProps extends ReduxProps {
    location: Location;
    children: React.ReactNode;
}

interface IState {
    isScrolledDown: boolean;
}

class PageWrapperLayout extends React.Component<IProps, IState> {
    private toaster: Toaster | undefined;
    private SCROLLED_DOWN_CUTOFF = 50;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isScrolledDown: false,
        };
    }

    public componentDidMount(): void {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', this.onMainScroll);

            const queryParams = queryString.parse(location.search.replace('?', ''));
            if (typeof queryParams?.error == 'string') {
                try {
                    const errorDict: { [errorKey: string]: { message: string } } =
                        JSON.parse(atob(decodeURIComponent(queryParams?.error)));

                    // TODO: Write redux saga to handle the error dict instead of just creating a toast
                    for (const { message } of Object.values(errorDict)) {
                        this.props.addErrorToast(message);
                    }
                    navigate(location.pathname);
                } catch (error) {
                    console.error('Failed to parse error in URL parameters: ', error);
                }
            }
        }
    }

    public componentWillUnmount(): void {
        if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.onMainScroll);
        }
    }

    public componentDidUpdate(): void {
        if (this.props.toasts.length > 0) {
            this.props.toasts.forEach(toastProps => {
                if (this.toaster !== undefined) {
                    this.toaster.show(toastProps);
                }
            });
            this.props.clearToasts();
        }
    }

    public render(): React.ReactNode {
        const {
            children,
            intrinsicAccount,
            isStateInitialized,
            toasts,
        } = this.props;
        const isTeamSubdomain = config.isTeamSubdomainPage(this.props.location);
        const isSignedIn = this.props.isSignedIn || config.isAuthenticatedPreBuiltPage(this.props.location);

        const path = this.props.location.pathname;

        const isSearchDisabled = ['/explore/', '/explore'].includes(path);
        const isFooterHidden = path.startsWith('/results/');

        return (
            <div onDragOver={(event) => event.preventDefault()} onDrop={(event) => event.preventDefault()}>
                <Helmet>
                    <meta
                        name='viewport'
                        content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
                    />
                    <html lang='en' />
                </Helmet>

                <Toaster position={Position.TOP} ref={(ref: Toaster) => this.toaster = ref}>
                    {toasts.map((toast, index) => (<Toast key={index} {...toast} />))}
                </Toaster>

                <AppOverlay />

                <Navbar
                    account={intrinsicAccount}
                    isBgDark={false}
                    isFrontPage={false}
                    isMinimal={true}
                    isSearchDisabled={isSearchDisabled}
                    isSignedIn={isSignedIn}
                    isStateInitialized={isStateInitialized}
                    isTeamSubdomain={isTeamSubdomain}
                />
                <div className='flex flex-col h-screen w-screen'>
                    <div className='flex-1 bg-default'>
                        <div className='print:hidden h-16 md:h-12' />
                        {children}
                    </div>
                    {isFooterHidden ? null :
                        <>
                            <div className='flex-auto bg-default' />
                            <div className='flex-initial bg-default print:hidden' id='footer'>
                                <Footer />
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }

    private onMainScroll = (untypedEvent: Event) => {
        const event = untypedEvent as unknown as React.UIEvent<Window>;
        const scrollPosition = event.currentTarget.window.scrollY;

        if (scrollPosition > this.SCROLLED_DOWN_CUTOFF) {
            if (!this.state.isScrolledDown) {
                this.setState({ isScrolledDown: true });
            }
        } else {
            if (this.state.isScrolledDown) {
                this.setState({ isScrolledDown: false });
            }
        }
    };
}

const PageWrapperLayoutReduxConnected = connect(mapStateToProps, mapDispatchToProps)(PageWrapperLayout);

// Gatsby requires this wrapper to pass props to the layout component.
const PageWrapper = ({ element, props }: any) => {
    return (<PageWrapperLayoutReduxConnected {...props}>{element}</PageWrapperLayoutReduxConnected>);
};

export default PageWrapper;
