import { FieldProps } from 'formik';
import React, { useRef, useState, MouseEvent, DragEvent, useEffect } from 'react';
import clsx from 'clsx';
import { saveFileListToFormikField } from './utils';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { byteSizeToHumanReadable } from '@utils';

interface IProps extends FieldProps {
    autoSubmitOnceValid: boolean;
    defaultValue?: string;
}

export default function DragAndDropArea(props: IProps) {
    const { field, form, autoSubmitOnceValid, defaultValue } = props;
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const selectedFile = (field.value as FileList)?.[0] ?? null;

    useEffect(() => {
        if (autoSubmitOnceValid && form.isValid && selectedFile) {
            form.submitForm();
        }
    }, [form.isValid, selectedFile]);

    const handleFileSelection = (files: FileList | null) => {
        saveFileListToFormikField(files, field, form, false);
    };

    const handleClearFile = (e: MouseEvent) => {
        e.stopPropagation();
        form.setFieldValue(field.name, '');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
        handleFileSelection(e.dataTransfer.files);
    };

    return (
        <div
            className={clsx(
                'h-36 flex-grow flex flex-col border-2 border-dotted justify-center items-center rounded-md group',
                isDragging ? 'border-emerald-500' : 'border-gray-300',
                { 'hover:cursor-pointer': !selectedFile }
            )}
            onDrop={handleDrop}
            onDragOver={(e) => {
                e.preventDefault();
                setIsDragging(true);
            }}
            onDragLeave={() => setIsDragging(false)}
            onClick={() => !selectedFile && fileInputRef.current?.click()}
        >
            <input
                hidden
                multiple={false}
                onChange={(e) => handleFileSelection(e.target.files)}
                ref={fileInputRef}
                type='file'
            />
            <div className='flex items-center px-2'>
                {selectedFile ? (
                    <div className='flex flex-row space-x-2'>
                        <h2 className='text-lg text-gray-500 select-none my-auto pr-2'>
                            {selectedFile?.name} - {byteSizeToHumanReadable(selectedFile?.size)}
                        </h2>
                        <button onClick={handleClearFile} title='Clear selected file'>
                            <XMarkIcon className='h-9 text-gray-500 rounded-full hover:bg-gray-50 hover:text-red-600 ' />
                        </button>
                    </div>
                ) : (
                    <div>
                        <div className='flex flex-row space-x-2'>
                            <h2 className='text-lg text-gray-500 group-hover:text-cyan-800 select-none my-auto pr-2'>
                                Drag and drop or click to upload
                            </h2>
                            <CloudArrowUpIcon className='h-9 text-gray-500 group-hover:text-cyan-800' />
                        </div>
                    </div>
                )}
            </div>
            <div>
                {defaultValue && !selectedFile && (
                    <span className='text-gray-600'>
                        [Using default file: {defaultValue}. To change, select a new file.]
                    </span>
                )}
            </div>
        </div>
    );
}
