import { useEffect, useState } from 'react';

export function getInitialRows(): Record<number, Record<number, string>> {
    const initialRows: Record<number, Record<number, string>> = {};
    for (let i = 0; i < 100; i += 1) {
        initialRows[i] = { 0: '', 1: '' };
    }
    return initialRows;
}

export function useScrollbarWidth(): number {
    const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);

    const measureScrollbarWidth = () => {
        const scrollDiv = document.createElement('div');
        scrollDiv.style.width = '100px';
        scrollDiv.style.height = '100px';
        scrollDiv.style.overflow = 'scroll';
        scrollDiv.style.position = 'absolute';
        scrollDiv.style.top = '-9999px';
        document.body.appendChild(scrollDiv);

        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        setScrollbarWidth(scrollbarWidth);

        document.body.removeChild(scrollDiv);
    };

    useEffect(() => {
        measureScrollbarWidth();

        // Add event listener to detect window resizing and zoom changes
        const handleResize = () => {
            measureScrollbarWidth();
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return scrollbarWidth;
}
