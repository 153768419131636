import { BioLibApiError } from '@biolibtech/biolib-js';
import { PayloadAction } from '@reduxjs/toolkit';
import { ActionCreator } from 'redux';
import { put } from 'redux-saga/effects';
import notificationActions from '../state/notification/notificationActions';

export function prependMessageToError(error: Error | BioLibApiError, message?: string) {
    let errorMessage = message || '';
    if (error instanceof BioLibApiError) {
        errorMessage = `${errorMessage}\n${error.toString()}`;
    } else {
        errorMessage = `${errorMessage}\n${error.message}`;
    }
    return errorMessage;
}

export interface IHandleErrorOptions {
    actionToDispatch: ActionCreator<PayloadAction<string>>;
    error: Error | BioLibApiError;
    showToast?: boolean;
    friendlyErrorMessageToPrepend?: string;
}

export function* handleError(
    { error, actionToDispatch, showToast, friendlyErrorMessageToPrepend }: IHandleErrorOptions,
): Generator {
    const errorMessage = prependMessageToError(error, friendlyErrorMessageToPrepend);
    yield put(actionToDispatch(errorMessage));
    if (showToast) {
        yield put(notificationActions.addError(errorMessage));
    }
}
