import {
    AccountUuid,
    IApiToken,
    IApiTokenCreateResponse,
    IRemoteHost,
    IRemoteHostResponse,
    ISsoConnectionRequestData,
    ISsoConnectionResponse,
    ISsoDomainRequestData,
} from '@biolibtech/biolib-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaginationState } from '../../state';
import { ILoadingState, LoadingState } from '../../utils';
import { ISaml2ConnectionFormValues } from './components/sso/Saml2Connection';

export interface IAccountSettingsState {
    apiTokensPaginated: IPaginationState<IApiToken> | null;
    newlyCreatedApiToken: null | IApiTokenCreateResponse;
    remoteHostsPaginated: Record<AccountUuid, IPaginationState<IRemoteHostResponse>>;
    ssoConnections: ISsoConnectionResponse[];
    isLoading: {
        apiTokenCreate: boolean;
        apiTokenDelete: boolean;
        apiTokensFetch: boolean;
        apiTokenUpdate: boolean;
        ssoConnectionCreate: boolean;
        ssoConnectionsFetch: boolean;
        ssoConnectionUpdate: boolean;
        ssoDomainCreate: boolean;
        ssoDomainDelete: boolean;
    }
    loadingStates: {
        addRemoteHost: ILoadingState;
        deleteRemoteHost: ILoadingState;
    }
}

const initialState: IAccountSettingsState = {
    apiTokensPaginated: null,
    newlyCreatedApiToken: null,
    remoteHostsPaginated: {},
    ssoConnections: [],
    isLoading: {
        apiTokenCreate: false,
        apiTokenDelete: false,
        apiTokensFetch: false,
        apiTokenUpdate: false,
        ssoConnectionCreate: false,
        ssoConnectionsFetch: false,
        ssoConnectionUpdate: false,
        ssoDomainCreate: false,
        ssoDomainDelete: false,
    },
    loadingStates: {
        addRemoteHost: LoadingState.default,
        deleteRemoteHost: LoadingState.default,
    }
}

const accountSettingsSlice = createSlice({
    name: 'accountSettings',
    initialState,
    reducers: {
        teamAcceptInvitation(state, action: PayloadAction<{ invite_id: string; token: string; }>) {
            // no-op here, this action is used by a saga
        },
        teamAcceptInvitationSuccess(state) {
            // no-op here, this action is used by a saga
        },
        teamAcceptInvitationFailure(state, action: PayloadAction<string>) {
            // no-op here, this action is used by a saga
        },
        teamLeave(state, action: PayloadAction<{ teamAccountHandle: string; }>) {
            // no-op here, this action is used by a saga
        },
        teamLeaveSuccess() {
            // no-op here, this action is used by a saga
        },
        teamLeaveFailure(state, action: PayloadAction<string>) {
            // no-op here, this action is used by a saga
        },
        fetchRemoteHosts(state, action: PayloadAction<{ accountId: AccountUuid; page?: number; }>) {
            // no-op here, this action is used by a saga
        },
        fetchRemoteHostsSuccess(
            state,
            action: PayloadAction<{
                accountId: AccountUuid;
                remoteHostsPaginated: IPaginationState<IRemoteHostResponse>;
            }>
        ) {
            const { accountId, remoteHostsPaginated } = action.payload;
            state.remoteHostsPaginated[accountId] = remoteHostsPaginated;
        },
        fetchRemoteHostsFailure(state, action: PayloadAction<string>) {
            // no-op here, this action is used by a saga
        },
        addRemoteHost(state, action: PayloadAction<{ accountId: AccountUuid; remoteHost: IRemoteHost }>) {
            state.loadingStates.addRemoteHost = LoadingState.begin;
        },
        addRemoteHostSuccess(state) {
            state.loadingStates.addRemoteHost = LoadingState.success;
        },
        addRemoteHostFailure(state, action: PayloadAction<string>) {
            state.loadingStates.addRemoteHost = LoadingState.failure(action.payload);
        },
        deleteRemoteHost(state, action: PayloadAction<{ accountId: AccountUuid; remoteHostId: string; }>) {
            state.loadingStates.deleteRemoteHost = LoadingState.begin;
        },
        deleteRemoteHostSuccess(state) {
            state.loadingStates.deleteRemoteHost = LoadingState.success;
        },
        deleteRemoteHostFailure(state, action: PayloadAction<string>) {
            state.loadingStates.deleteRemoteHost = LoadingState.failure(action.payload);
        },
        apiTokensFetch(state, action: PayloadAction<{ page?: number }>) {
            state.isLoading.apiTokensFetch = true;
        },
        apiTokensFetchSuccess(state, action: PayloadAction<{ apiTokensPaginated: IPaginationState<IApiToken> }>) {
            state.apiTokensPaginated = action.payload.apiTokensPaginated;
            state.isLoading.apiTokensFetch = false;
        },
        apiTokensFetchFailure(state) {
            state.isLoading.apiTokensFetch = false;
        },
        apiTokenCreate(state, action: PayloadAction<{ name: string; }>) {
            state.isLoading.apiTokenCreate = true;
        },
        apiTokenCreateSuccess(state, action: PayloadAction<{ apiToken: IApiTokenCreateResponse; }>) {
            state.isLoading.apiTokenCreate = false;
            state.newlyCreatedApiToken = action.payload.apiToken;
        },
        apiTokenCreateFailure(state) {
            state.isLoading.apiTokenCreate = false;
        },
        apiTokenDelete(state, action: PayloadAction<{ apiToken: IApiToken; }>) {
            state.isLoading.apiTokenDelete = true;
        },
        apiTokenDeleteSuccess(state) {
            state.isLoading.apiTokenDelete = false;
        },
        apiTokenDeleteFailure(state) {
            state.isLoading.apiTokenDelete = false;
        },
        apiTokenUpdate(state, action: PayloadAction<{ tokenId: string; name: string; }>) {
            state.isLoading.apiTokenUpdate = true;
        },
        apiTokenUpdateSuccess(state) {
            state.isLoading.apiTokenUpdate = false;
        },
        apiTokenUpdateFailure(state) {
            state.isLoading.apiTokenUpdate = false;
        },
        clearApiTokens(state) {
            state.isLoading = { ...initialState.isLoading };
            state.apiTokensPaginated = null;
            state.newlyCreatedApiToken = null;
        },
        ssoConnectionCreate(
            state,
            action: PayloadAction<{ accountId: AccountUuid; saml2Connection: ISaml2ConnectionFormValues; }>,
        ) {
            state.isLoading.ssoConnectionCreate = true;
        },
        ssoConnectionCreateSuccess(state) {
            state.isLoading.ssoConnectionCreate = false;
        },
        ssoConnectionCreateFailure(state) {
            state.isLoading.ssoConnectionCreate = false;
        },
        ssoConnectionsFetch(state, action: PayloadAction<{ accountId: AccountUuid }>) {
            state.isLoading.ssoConnectionsFetch = true;
        },
        ssoConnectionsFetchSuccess(state, action: PayloadAction<{ ssoConnections: ISsoConnectionResponse[] }>) {
            state.isLoading.ssoConnectionsFetch = false;
            state.ssoConnections = action.payload.ssoConnections;
        },
        ssoConnectionsFetchFailure(state) {
            state.isLoading.ssoConnectionsFetch = false;
        },
        ssoConnectionUpdate(
            state,
            action: PayloadAction<{
                accountId: AccountUuid;
                ssoConnectionId: string;
                saml2Connection: ISaml2ConnectionFormValues;
            }>,
        ) {
            state.isLoading.ssoConnectionUpdate = true;
        },
        ssoConnectionUpdateSuccess(state) {
            state.isLoading.ssoConnectionUpdate = false;
        },
        ssoConnectionUpdateFailure(state) {
            state.isLoading.ssoConnectionUpdate = false;
        },
        ssoDomainCreate(state, action: PayloadAction<{
            accountId: AccountUuid;
            ssoConnectionId: string;
            ssoDomain: ISsoDomainRequestData;
        }>) {
            state.isLoading.ssoDomainCreate = true;
        },
        ssoDomainCreateSuccess(state) {
            state.isLoading.ssoDomainCreate = false;
        },
        ssoDomainCreateFailure(state) {
            state.isLoading.ssoDomainCreate = false;
        },
        ssoDomainDelete(state, action: PayloadAction<{
            accountId: AccountUuid;
            ssoConnectionId: string;
            ssoDomainId: string;
        }>) {
            state.isLoading.ssoDomainDelete = true;
        },
        ssoDomainDeleteSuccess(state) {
            state.isLoading.ssoDomainDelete = false;
        },
        ssoDomainDeleteFailure(state) {
            state.isLoading.ssoDomainDelete = false;
        },
        clearSsoConnectionState(state) {
            state.isLoading = { ...initialState.isLoading };
            state.ssoConnections = [];
        }
    }
})

export default accountSettingsSlice;
