import { Action } from 'redux';

export interface ErrorAction extends Action {
    errorMessage: string;
}

export interface IPaginationState<T> {
    countPages: number;
    countPerPage: number;
    countTotal: number;
    currentPage: number;
    pages: { [index: number]: T[] }
}

export const EmptyPaginationState: IPaginationState<any> = {
    countPages: 0,
    countPerPage: 0,
    countTotal: 0,
    currentPage: 0,
    pages: {},
};
