import config from '../../../config';
import * as jwt_decode from 'jwt-decode';

interface IJwtToken {
    token_type: 'refresh' | 'access';
    exp: number;
    jti: string;
    public_id: string;
}

export default class AuthCookie {
    // Prepend name with '__Secure-' ensures the cookie was set on https site, this must be used with the 'secure;' flag
    protected static readonly authCookieName = `${config.isDev ? '' : '__Secure-'}blb_token`;
    protected static readonly isSignedInCookieName = 'signed_in';

    public static set(jwtTokenString: string): void {
        try {
            const jwtToken = jwt_decode<IJwtToken>(jwtTokenString);
            const expiryData = new Date(jwtToken.exp * 1000);

            const token = encodeURIComponent(jwtTokenString);
            const expires = expiryData.toUTCString();

            const commonCookieAttributes = `expires=${expires}; domain=${config.domainForCookies}; path=/; secure;`;
            document.cookie = `${this.authCookieName}=${token}; ${commonCookieAttributes} SameSite=strict;`;
            document.cookie = `${this.isSignedInCookieName}=true; ${commonCookieAttributes} SameSite=lax;`;
        } catch (error) {
            console.error('Failed to set AuthCookie: ', error);
        }
    }

    public static get(): string | null {
        return this.getCookieValue(this.authCookieName);
    }

    public static isSignedIn(): boolean {
        if (typeof window !== 'undefined') {
            try {
                return this.getCookieValue(this.authCookieName) !== null
                    && this.getCookieValue(this.isSignedInCookieName) !== null;
            } catch (error) {
                console.error('Sign in check failed: ', error);
            }
        }
        return false;
    }

    public static clear(): void {
        const cookieDeleteAttributes =
            `expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${config.domainForCookies}; path=/; secure;`;
        document.cookie = `${this.authCookieName}=; ${cookieDeleteAttributes}`;
        document.cookie = `${this.isSignedInCookieName}=; ${cookieDeleteAttributes}`;
    }

    private static getCookieValue(cookieName: string): string | null {
        const cookiesList = document.cookie.split('; ');
        if (cookiesList) {
            const cookie = cookiesList.find((row) => row.startsWith(cookieName.concat('=')));
            if (cookie) {
                const cookieAttributes = cookie.split('=');
                if (cookieAttributes.length > 1) {
                    return cookieAttributes[1];
                }
            }
        }
        return null;
    }
}
