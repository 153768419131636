import { put, takeEvery, fork } from "redux-saga/effects";
import { SubmitFormAction } from "../state/contact/contactActionTypes";
import contactConstants from "../state/contact/contactConstants";
import contactActions from "../state/contact/contactActions";
import { BioLibSingleton } from '@biolibtech/biolib-js';

function* submitContactForm(action: SubmitFormAction) {
    try {
        const biolib = BioLibSingleton.get();
        yield biolib.inquires.send(action.payload);
        yield put(contactActions.submitFormSuccess());
    } catch (e) {
        // TODO: Use returned error?
        yield put(contactActions.submitFormFailed("Contact form could not be submitted"))
    }
}

function* watchSubmitContactForm() {
    yield takeEvery(contactConstants.SUBMIT_FORM, submitContactForm);
}

export default function* contactSaga() {
    yield fork(watchSubmitContactForm);
}
