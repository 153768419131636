import * as React from 'react';
import * as classNames from 'classnames';
import { Collapse, Icon, Pre } from '@blueprintjs/core';

interface IRenderTextProps {
    className?: string;
    overflowScroll?: boolean;
    text: string;
}

export const RenderText: React.FC<IRenderTextProps> = (props) => (
    <Pre
        className={classNames('shadow-none font-mono', props.className)}
        style={props.overflowScroll ? { maxHeight: 500, overflowY: 'scroll' } : undefined}
    >
        {props.text}
    </Pre>
);

interface ICollapsableProps {
    children?: React.ReactNode;
    isOpenByDefault?: boolean;
    title: string;
}

export const Collapsable: React.FC<ICollapsableProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(props.isOpenByDefault || false);
    return (
        <>
            <div
                className='flex flex-row w-full rounded-md hover:bg-slate-100 py-1 mt-3 hover:cursor-pointer'
                onClick={() => setIsOpen(!isOpen)}
            >
                <Icon className='mt-1 mx-2' icon={isOpen ? 'chevron-up' : 'chevron-down'}/>
                <p>{props.title}</p>
            </div>
            <Collapse isOpen={isOpen} keepChildrenMounted={false}>
                {props.children}
            </Collapse>
        </>
    )
}
