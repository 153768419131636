import appPageSlice from './appPageSlice'
import { Action } from '@reduxjs/toolkit'
import { BioLibSingleton, IAppVersion } from '@biolibtech/biolib-js'
import { fork, put, select, takeLatest } from 'redux-saga/effects'
import { IRootState } from '../../state/rootReducer';
import appRunSlice from '../appRun/appRunSlice';
import config from '../../config';
import { IGetAppResponse } from '@biolibtech/biolib-js/src/BioLib/resources/App';

function* watchLoadApp() {
    yield takeLatest(appPageSlice.actions.loadAppByUri.type, function* (action: Action) {
        if (appPageSlice.actions.loadAppByUri.match(action)) {
            try {
                const { uri } = action.payload;
                const { app, app_version }: IGetAppResponse = yield BioLibSingleton.get().app.fetchByUri(uri);

                yield put(appPageSlice.actions.loadAppSuccess({
                    app,
                    newAppVersionId: app_version.public_id,
                    newAppVersion: app_version,
                }));

                const isSignedIn = yield select((state: IRootState) => state.user.isSignedIn);
                if ((isSignedIn || config.isTeamSubdomain) && app_version.is_runnable_by_user) {
                    yield put(appRunSlice.actions.openApp({ app, selectedAppVersion: app_version }));
                }
            } catch (error) {
                // TODO: Come up with standard way of doing this
                yield put(appPageSlice.actions.loadAppFailed(error.errors))
            }
        }
    });
}

function* watchChangeAppVersion() {
    yield takeLatest(appPageSlice.actions.changeAppVersion.type, function* (action: Action) {
        if (appPageSlice.actions.changeAppVersion.match(action)) {
            try {
                const newAppVersionId: string = action.payload;
                const state: IRootState = yield select();
                const appVersionDict = state.appPage.appVersionDict;

                let newAppVersion: IAppVersion
                if (newAppVersionId in appVersionDict) {
                    newAppVersion = appVersionDict[newAppVersionId];
                } else {
                    newAppVersion = yield BioLibSingleton.get().app.fetchAppVersionById(newAppVersionId);
                }

                if (!newAppVersion) {
                    yield put(appPageSlice.actions.changeAppVersionFailure('App Version not found'));
                    return;
                }

                yield put(appPageSlice.actions.changeAppVersionSuccess({ newAppVersionId, newAppVersion }));
            } catch (error) {
                yield put(appPageSlice.actions.changeAppVersionFailure(error.errors));
            }
        }
    });
}

export default function* appPageSaga() {
    yield fork(watchLoadApp);
    yield fork(watchChangeAppVersion);
}
