export interface ILoadingState {
    errorMessage: string | undefined;
    isDone: boolean;
    isLoading: boolean;
}

export const LoadingState = {
    default: {
        errorMessage: undefined,
        isLoading: false,
        isDone: false,
    },
    begin: {
        errorMessage: undefined,
        isDone: false,
        isLoading: true,
    },
    success: {
        errorMessage: undefined,
        isDone: true,
        isLoading: false,
    },
    failure: (errorMessage: string): ILoadingState => ({
        errorMessage,
        isDone: false,
        isLoading: false,
    }),
};
