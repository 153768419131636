import { put, select, takeLatest } from 'redux-saga/effects';
import accountProfileSlice from './accountProfileSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { BioLibNotFoundError, BioLibSingleton, ISlimApp, Paginated } from '@biolibtech/biolib-js';
import notificationActions from '../../state/notification/notificationActions';
import { prependMessageToError } from '../../utils';
import { IPaginationState } from '../../state';
import { IRootState } from '../../state/rootReducer';

const { actions } = accountProfileSlice;

const accountProfileAppsPageSize = 10;

function getPaginationState<ItemType>(
    paginatedResponse: Paginated<ItemType>,
    currentPage: number,
    pageSize: number
): IPaginationState<ItemType> {
    return {
        currentPage,
        countPages: Math.ceil(paginatedResponse.count / pageSize),
        countPerPage: pageSize,
        countTotal: paginatedResponse.count,
        pages: {
            [currentPage]: paginatedResponse.results,
        }
    };
}

function* fetchAccount(action: PayloadAction) {
    if (actions.fetchAccount.match(action)) {
        try {
            const { account_handle } = action.payload;
            const biolib = BioLibSingleton.get();
            const account = yield biolib.account.fetch(account_handle);
            yield put(actions.fetchAccountSuccess({ account }));
        } catch (error) {
            yield put(actions.fetchAccountFailure());
            if (!(error instanceof BioLibNotFoundError)) {
                yield put(notificationActions.addError(prependMessageToError(error, 'Loading profile failed')));
            }
        }
    }
}

function* fetchApps(action: PayloadAction) {
    if (actions.fetchApps.match(action)) {
        try {
            const { account_handle } = action.payload;
            const biolib = BioLibSingleton.get();
            const response: Paginated<ISlimApp> = yield biolib.app.fetchMultiple({
                account_handle,
                page_size: accountProfileAppsPageSize
            });
            const paginatedApps = getPaginationState(response, 0, accountProfileAppsPageSize);
            yield put(actions.fetchAppsSuccess({ paginatedApps }));
        } catch (error) {
            yield put(actions.fetchAccountFailure());
            if (!(error instanceof BioLibNotFoundError)) {
                yield put(notificationActions.addError(prependMessageToError(error, 'Loading projects failed')));
            }
        }
    }
}

function* appsChangePage(action: PayloadAction) {
    if (actions.appsChangePage.match(action)) {
        try {
            const { newPageId } = action.payload;

            const previousPaginatedApps: null | IPaginationState<ISlimApp> = yield select(
                (state: IRootState) => state.accountProfile.paginatedApps
            );

            if (newPageId < 0 || (previousPaginatedApps && newPageId >= previousPaginatedApps.countPages)) {
                yield put(notificationActions.addError('Invalid page change'));
                return;
            }

            let paginatedApps: IPaginationState<ISlimApp>;

            if (previousPaginatedApps && newPageId in previousPaginatedApps.pages) {
                paginatedApps = { ...previousPaginatedApps, currentPage: newPageId };
            } else {
                const account_handle: string | undefined = yield select(
                    (state: IRootState) => state.accountProfile.account?.account_handle
                );
                if (!account_handle) {
                    throw new Error('Undefined account handle');
                }

                const biolib = BioLibSingleton.get();
                const response: Paginated<ISlimApp> = yield biolib.app.fetchMultiple({
                    account_handle,
                    page_size: accountProfileAppsPageSize,
                    page: newPageId + 1,
                });

                if (!previousPaginatedApps) {
                    paginatedApps = getPaginationState(response, newPageId, accountProfileAppsPageSize);
                } else {
                    paginatedApps = {
                        countPages: Math.ceil(response.count / accountProfileAppsPageSize),
                        countPerPage: accountProfileAppsPageSize,
                        countTotal: response.count,
                        currentPage: newPageId,
                        pages: {
                            ...previousPaginatedApps.pages,
                            [newPageId]: response.results,
                        }
                    };
                }
            }
            yield put(actions.setPaginatedApps({ paginatedApps }));

        } catch (error) {
            yield put(notificationActions.addError(prependMessageToError(error, 'Loading projects failed')));
        }
    }
}

export default function* accountProfileSaga() {
    yield takeLatest(actions.appsChangePage.type, appsChangePage);
    yield takeLatest(actions.fetchAccount.type, fetchAccount);
    yield takeLatest(actions.fetchApps.type, fetchApps);
}
