export const sleep = (milliseconds: number) => new Promise(async (resolve) => setTimeout(resolve, milliseconds));

export function base64ToByteArray(base64_str: string): Uint8Array {
    const binary_string = atob(base64_str);
    const length = binary_string.length;
    const byteArray = new Uint8Array(length);
    for (let i = 0; i < length; i += 1) {
        byteArray[i] = binary_string.charCodeAt(i);
    }
    return byteArray;
}

export function areByteArraysEqual(a: Uint8Array, b: Uint8Array): boolean {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i += 1) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}
