import BaseResource from './BaseResource';
import { BioLibApiError } from '../errors';
import { IUser, IUserPost, UserUuid } from './User.types';

export default class User extends BaseResource {
    protected readonly basePath = '/api/user';

    public agreeToTerms(): Promise<void> {
        return this.post({ url: `/agree_to_terms/`, shouldAuthenticate: true, data: {} });
    }

    public changeEmail(user_id: UserUuid, data: { email: string }) {
        return this.patch<IUser>({ url: `/${user_id}/`, data, shouldAuthenticate: true });
    }

    public clearTokens(): void {
        this.httpClient.clearTokens();
    }

    public create(data: IUserPost): Promise<IUser> {
        return this.post<IUser>({ url: `/`, data });
    }

    /*
    *   Fetch user from supplied id if given, otherwise attempt to get uuid from refreshToken and then fetch user
    */
    public fetch(user_id?: UserUuid): Promise<IUser> {
        const userUuid = user_id || this.httpClient.getSingedInUserId();
        if (!userUuid) {
            throw new BioLibApiError('Failed to get user uuid from refreshToken');
        }
        return this.get<IUser>({ url: `/${userUuid}/`, shouldAuthenticate: true });
    }

    public resetPassword(data: { email: string }): Promise<void> {
        return this.post<void>({ url: `/password_reset/`, data });
    }

    public resetPasswordConfirm(data: { token: string; password: string; }): Promise<void> {
        return this.post<void>({ url: `/password_reset/confirm/`, data });
    }

    public signIn(data: { email: string; password: string }): Promise<{ accessToken: string, refreshToken: string; }> {
        return this.post<{ access: string, refresh: string; }>({ url: `/token/`, data })
            .then((response) => {
                // Add tokens to the httpClient so that other resource classes can make authenticated requests
                this.httpClient.setRefreshToken(response.refresh);
                this.httpClient.setAccessToken(response.access);
                return {
                    refreshToken: response.refresh,
                    accessToken: response.access,
                };
            })
    }

    public signInAndFetch(data: { email: string; password: string }): Promise<{ user: IUser, refreshToken: string }> {
        return new Promise<{ user: IUser, refreshToken: string }>(async (resolve, reject) => {
            try {
                const { refreshToken } = await this.signIn(data);
                const user = await this.fetch();
                resolve({ user, refreshToken });
            } catch (error) {
                reject(error);
            }
        });
    }

    public verifyEmail(data: { uuid: string; token: string }): Promise<void> {
        return this.post({ url: `/verify_email/`, data });
    }

    public requestVerificationEmail(): Promise<{ email: string }> {
        return this.get<{ email: string }>(({ url: `/verify_email/`, shouldAuthenticate: true }));
    }
}
