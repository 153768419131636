interface IValidationErrorsDict {
    [name: string]: IValidationErrorsDict | IValidationErrorsDict[] | string[] | string;
}

interface IBioLibApiErrorConstruct {
    statusText: string;
    status?: number;
    data?: IValidationErrorsDict;
}

export class BioLibApiError extends Error {
    public readonly name: string = 'ApiError';
    public status: number | undefined;
    public validationErrors: IValidationErrorsDict | undefined;

    constructor(error: string | IBioLibApiErrorConstruct) {
        super(typeof error === 'string' ? error : error.statusText);
        if (typeof error === 'object') {
            this.status = error.status;
            this.validationErrors = error.data;

            if (error.status !== 400) {
                this.message = this.validationErrors?.detail as string || this.message;
            }
        }
    }

    public toString(): string {
        return `${this.name}: ${this.message}`;
    }

    public static generate(error: IBioLibApiErrorConstruct) {
        if (!error) {
            return new BioLibApiError('Unknown API Error');
        }
        switch (error.status) {
            case 400:
                // TODO: avoid hardcoded strings here
                if (error.data && 'compute_limit_exceeded_please_signin' in error.data) {
                    return new ComputeLimitExceededPleaseSignIn(error);
                }
                if (error.data && 'compute_limit_exceeded_please_contact_biolib' in error.data) {
                    return new ComputeLimitExceededPleaseContactBioLib(error);
                }

                return new BioLibValidationError(error);
            case 401:
                return new BioLibAuthenticationError(error);
            case 403:
                return new BioLibPermissionError(error);
            case 404:
                return new BioLibNotFoundError(error);
            case 429:
                return new BioLibServerMaximumAttemptsReached(error);
            case 500:
                return new BioLibServerError(error);
            case 503:
                return new BioLibServerUnavailableError(error);
            default:
                return new BioLibApiError(error);
        }
    }
}

export class BioLibValidationError extends BioLibApiError {
    public readonly name = 'ValidationError';

    constructor(error: IBioLibApiErrorConstruct) {
        super(error);

        const validationErrors = this.validationErrors;
        if (validationErrors) {
            this.message = JSON.stringify(validationErrors);
        }
    }

    public toString(): string {
        const validationErrors = this.validationErrors;
        if (validationErrors) {
            try {
                return BioLibValidationError.recurseValidationErrors(validationErrors);
            } catch (error) {
                return `${this.name}: ${this.message}`;
            }
        }
        return `${this.name}: ${this.message}`;
    }

    private static recurseValidationErrors(
        validationErrors: IValidationErrorsDict | IValidationErrorsDict[] | string[] | string
    ): string {
        if (typeof validationErrors === 'string') {
            return validationErrors; // `${errorMessage}${validationErrors}`;
        }

        if (Array.isArray(validationErrors) && validationErrors.length === 0) {
            return '';
        }

        if (Array.isArray(validationErrors) && typeof validationErrors[0] === 'string') {
            return validationErrors.join('\n');
        }

        let returnString = '';

        if (Array.isArray(validationErrors)) {
            for (const value of validationErrors) {
                returnString = `${returnString}${returnString.endsWith('\n') ? '' : '\n'}${
                    BioLibValidationError.recurseValidationErrors(value)
                }`;
            }
        } else if (typeof validationErrors === 'object') {
            for (const key in validationErrors) {
                const value = validationErrors[key];
                returnString = `${returnString}${returnString.endsWith('\n') ? '' : '\n'}${
                    BioLibValidationError.recurseValidationErrors(value)
                }`;
            }
        }
        return returnString;
    }
}

export class BioLibAuthenticationError extends BioLibApiError {
    public readonly name = 'AuthenticationError';
}

export class BioLibPermissionError extends BioLibApiError {
    public readonly name = 'PermissionError';
}

export class BioLibNotFoundError extends BioLibApiError {
    public readonly name = 'NotFoundError';
}

export class BioLibServerError extends BioLibApiError {
    public readonly name = 'ServerError';
}

export class BioLibServerUnavailableError extends BioLibApiError {
    public readonly name = 'ServerUnavailableError';
}

export class BioLibServerMaximumAttemptsReached extends BioLibApiError {
    public readonly name = 'BioLibServerMaximumAttemptsReached';
}

export class ComputeNodeConnectionLost extends BioLibApiError {
    public name = 'ComputeNodeConnectionLost';
}

export class CustomComputeNodeConnectionLost extends ComputeNodeConnectionLost {
    public readonly name = 'CustomComputeNodeConnectionLost';
}

export class ComputeLimitExceededPleaseSignIn extends BioLibApiError {
    public readonly name = 'compute_limit_exceeded_please_signin';
}

export class ComputeLimitExceededPleaseContactBioLib extends BioLibApiError {
    public readonly name = 'compute_limit_exceeded_please_contact_biolib';
}

export class JobExceededMaxRuntime extends BioLibApiError {
    public readonly name = 'job_exceeded_max_runtime';
}

export class ComputeContainerRanOutOfMemory extends BioLibApiError {
    public readonly name = 'failed_to_initialize_compute_node';
}
