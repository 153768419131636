import axios, { AxiosInstance } from 'axios';
import { computeNodeSystemExceptionDict, IComputeNodeStatusResponse, IRemoteOptions } from './remote.types';
import {
    BioLibServerMaximumAttemptsReached,
    ComputeContainerRanOutOfMemory,
    JobExceededMaxRuntime,
} from '../BioLib';
import { BioLibExecutionError } from './errors';
import HttpClient from '../BioLib/HttpClient';
import Job from '../BioLib/resources/Job';
import { base64ToByteArray, sleep } from './utils';
import StdoutAndStderrPackage from './biolibBinaryFormat/StdoutAndStderrPackage';
import { IJobUtils } from './types';

export default class RemoteExecutorNew {

    public static async runJobBiolibCloud(
        utils: IJobUtils,
        options: IRemoteOptions,
        moduleInputDataSerialized: Uint8Array,
    ): Promise<void> {
        const { job, moduleName, biolibApiBaseUrl, refreshToken } = options;

        utils.addLogMessage('Cloud: Creating job');
        // As job is the only resource needed we can minimize web-worker imports by not using full BioLib client
        const httpClient = new HttpClient({ refreshToken, baseURL: biolibApiBaseUrl });
        const jobApiClient = new Job(httpClient);

        utils.addLogMessage(`Cloud: Uploading input`);
        await jobApiClient.uploadModuleInput(job, moduleInputDataSerialized);

        const cloudJob = await jobApiClient.createCloudJob(
            { module_name: moduleName, job_id: job.public_id },
            utils.addLogMessage,
            utils.setProgressCompute
        );

        utils.addLogMessage(`Cloud: Job created with id ${cloudJob.public_id}`);
        const urlObject = new URL(cloudJob.compute_node_info.url);
        const url = `${location.origin}${urlObject.pathname}${urlObject.search}`;
        const computeNode: AxiosInstance = axios.create({
            baseURL: `${url}/v1`,
            headers: { 'BioLib-Client': 'biolib-frontend' },
            timeout: 10_000,
        });
        utils.addLogMessage(`Cloud: Awaiting initialization of compute node at ${url}`);

        await this.awaitComputeNodeStatus(
            utils,
            computeNode,
            {
                errorMessageOnLimitHit: 'Failed to get results: Retry limit exceeded',
                jobId: job.public_id,
                retryIntervalInSeconds: 1.5,
                retryLimitInMinutes: 4_320, // 3 days
                statusToAwait: 'Result Ready',
                type: 'Cloud',
            },
        );
    }

    private static async getJobStatusWithRetry(
        computeNode: AxiosInstance,
        jobId: string,
    ): Promise<IComputeNodeStatusResponse> {
        const maxRetries = 25_920; // Retry for 3 days with 10 seconds interval
        for (let retryCount = 0; retryCount < maxRetries; retryCount += 1) {
            if (retryCount > 10) {
                await sleep(10_000);
            } else if (retryCount > 0) {
                await sleep(2_000);
            }
            try {
                const response = await computeNode.get<IComputeNodeStatusResponse>(`/job/${jobId}/status/`);
                return response.data;
            } catch (error) {
                if (error.response) {
                    console.error('The request was made and the server responded with: ', error.response);
                } else if (error.request) {
                    console.error('The request was made but no response was received. Hit error: ', error.request);
                    continue
                } else {
                    console.error('Failed to set up the request. Hit error: ', error);
                }
                throw error;
            }
        }
        throw new BioLibServerMaximumAttemptsReached(`Getting status for job "${jobId}" exceed max retries`);
    }

    private static async awaitComputeNodeStatus(
        utils: IJobUtils,
        computeNode: AxiosInstance,
        options: {
            errorMessageOnLimitHit: string;
            retryIntervalInSeconds: number;
            retryLimitInMinutes: number;
            statusToAwait: string;
            type: 'Cloud' | 'Compute node',
            jobId: string,
        }
    ): Promise<void> {
        const {
            errorMessageOnLimitHit,
            retryIntervalInSeconds,
            retryLimitInMinutes,
            statusToAwait,
            type,
            jobId,
        } = options;

        const statusMaxRetryAttempts = Math.floor(retryLimitInMinutes * 60 / retryIntervalInSeconds);
        const textDecoder = new TextDecoder()

        for (let retryCount = 0; retryCount < statusMaxRetryAttempts; retryCount += 1) {
            const {
                error_code,
                status_updates,
                stdout_and_stderr_packages_b64,
            } = await this.getJobStatusWithRetry(computeNode, jobId);

            if (stdout_and_stderr_packages_b64 !== undefined) {
                for (const stdout_and_stderr_package_b64 of stdout_and_stderr_packages_b64) {
                    const stdout_and_stderr_package = base64ToByteArray(stdout_and_stderr_package_b64);

                    const { stdout, stderr } = new StdoutAndStderrPackage(stdout_and_stderr_package).getAttributes()

                    if (stdout) {
                        utils.addStreamingOutputMessage(
                            textDecoder.decode(stdout)
                        )
                    }

                    if (stderr) {
                        utils.addStreamingOutputMessage(
                            textDecoder.decode(stderr)
                        )
                    }
                }
            }

            let statusToAwaitWasReached = false;
            for (const { log_message, progress } of status_updates) {
                if (progress !== undefined) {
                    utils.setProgressCompute(progress);
                }
                if (log_message !== undefined) {
                    utils.addLogMessage(`${type}: ${log_message}`);
                }
                if (log_message === statusToAwait) {
                    statusToAwaitWasReached = true;
                }
            }

            if (error_code !== undefined) {
                if (error_code === 28) {
                    throw new JobExceededMaxRuntime('Job exceeded max run time');
                } else if (error_code === 29) {
                    throw new ComputeContainerRanOutOfMemory('Container ran out of memory');
                }
                const errorMessage = computeNodeSystemExceptionDict[error_code];
                throw new BioLibExecutionError(`${type}: ${errorMessage}`);
            }

            if (statusToAwaitWasReached) {
                return;
            }

            await sleep(retryIntervalInSeconds * 1000);
        }
        throw new BioLibServerMaximumAttemptsReached(errorMessageOnLimitHit);
    }
}
