import * as React from 'react';
import { Intent, ProgressBar, Spinner, Tag } from '@blueprintjs/core';
import { IJobWrapper } from '../../types';
import AppLog from '../AppLog';

interface IProps {
    jobWrapper: IJobWrapper;
    fullHeight: boolean;
}

const ComputeTab: React.FC<IProps> = (props) => {
    const { jobWrapper } = props;
    return (
        <div className='content' style={props.fullHeight ? { height: 'calc(100vh - 242px)' } : undefined}>
            <div className='body'>
                <h3>Computing</h3>
                <div className='mt-2 flex flex-row justify-between space-x-4 items-center'>
                    <p>The computation might take a while.</p>
                    {
                        // Wasm apps set initialization progress, so we avoid showing progress tag for these apps
                        // TODO: Avoid this workaround by streamlining use of progress
                        jobWrapper.progressInitialization !== 0 ? null :
                            <ProgressTag progress={jobWrapper.progressCompute} />
                    }
                </div>
                <ProgressBar
                    className='my-2'
                    intent={Intent.PRIMARY}
                    animate={true}
                    value={jobWrapper.progressCompute === 0 ? 1 : jobWrapper.progressCompute}
                />
                {!jobWrapper.streamedOutput ? null :
                    <div
                        className='relative'
                        style={{ height: Math.min(500, 20 + jobWrapper.streamedOutput.split('\n').length * 20) }}
                    >
                        <div className='absolute h-full w-full'>
                            <pre
                                className='h-full m-0 p-3 bg-white text-gray-900 font-mono text-sm border border-gray-300 rounded'
                            >
                                {jobWrapper.streamedOutput}
                            </pre>
                        </div>
                    </div>
                }
                <AppLog logMessages={jobWrapper.logMessages} />
            </div>
        </div>
    );
};

export default ComputeTab;

const ProgressTag: React.FC<{ progress: number; }> = (props) => {
    const { progress } = props;

    let message: string | null = null;
    if (progress < 0.05) {
        message = 'Creating job';
    } else if (progress >= 0.05 && progress < 0.1) {
        message = 'Warming up a new server';
    } else if (progress >= 0.1 && progress < 0.25) {
        message = 'Initializing';
    } else if (progress >= 0.25 && progress < 0.7) {
        message = 'Pulling images';
    }

    if (message === null) {
        return null;
    }

    return (
        <Tag large={true} intent='primary' minimal={true} rightIcon={<Spinner size={15} />}>
            {message}
        </Tag>
    );
};
