import { IFilesDict, IFileSimple } from '../types';
import { IFilesMapping } from '../../BioLib';
import { BioLibExecutionError } from '../errors';

const argReferenceRegex = /\$(?<argIndex>\d+)/g;

const getArgReferenceReplacer = (parameters: string[]) => (...replacerFunctionArguments: any[]): string => {
    // The last argument of this function contains an object with keys of the named matched groups in the regex
    const { argIndex } = replacerFunctionArguments[replacerFunctionArguments.length - 1];

    if (argIndex === 0) {
        throw new BioLibExecutionError(
            'Attempted to copy using argument reference $0 which is invalid'
        );
    }

    if (argIndex > parameters.length) {
        throw new BioLibExecutionError(
            'Attempted to copy using argument reference out of bounds of arguments'
        );
    }

    return parameters[argIndex - 1];
};

function assertPathIsValid(path: string, type: 'to' | 'from'): void {
    if (!path.startsWith('/')) {
        throw new BioLibExecutionError(`Attempted to copy ${type} path ${path}. \
But only absolute paths are supported`);
    }

    if (path.includes('//')) {
        throw new BioLibExecutionError(`Attempted to copy ${type} path ${path}. \
But encountered consecutive slashes in path`);
    }

    if (path.includes('/..')) {
        throw new BioLibExecutionError(`Attempted to copy ${type} path ${path}. \
But encountered unsupported /.. path`);
    }
}

export function getFilesInMappings(
    filesMappings: IFilesMapping[],
    parameters: string[],
    getSingleFile: (path: string) => IFileSimple | null,
    getFilesInDirectory: (directoryPath: string) => Generator<{ file: IFileSimple, path: string }>,
    replaceWithToPath: boolean,
): IFilesDict {
    const mappedFiles: IFilesDict = {};
    const argReferenceReplacer = getArgReferenceReplacer(parameters);

    for (const mapping of filesMappings) {

        const fromPath = mapping.from_path.replace(argReferenceRegex, argReferenceReplacer);
        assertPathIsValid(fromPath, 'from');
        const toPath = mapping.to_path.replace(argReferenceRegex, argReferenceReplacer);
        assertPathIsValid(toPath, 'to');

        const isFromPathDir = fromPath.endsWith('/');
        const isToPathDir = toPath.endsWith('/');

        if (isFromPathDir) {
            // Mapping from a directory
            if (!isToPathDir) {
                throw new BioLibExecutionError(`Attempted to copy directory "${fromPath}" to file "${toPath}"`);
            }

            for (const { path, file } of getFilesInDirectory(fromPath)) {
                const destinationPath = replaceWithToPath ? toPath.concat(path.slice(fromPath.length)) : path;
                mappedFiles[destinationPath] = file;
            }

        } else {
            // Mapping from a file
            const file = getSingleFile(fromPath);

            if (file) {
                let destinationPath = replaceWithToPath ? toPath : fromPath;

                if (replaceWithToPath && isToPathDir) {
                    // If mapping is to directory, then concat the filename to the new path
                    const filename = fromPath.split('/').pop();
                    if (filename) {
                        destinationPath = destinationPath.concat(filename);
                    }
                }
                mappedFiles[destinationPath] = file;

            } else {
                // Check if the file path exists as a directory
                // If the iterator returns just 1 value then there is matching path
                const iterator = getFilesInDirectory(fromPath.concat('/'));
                const current = iterator.next();
                if (!current.done) {
                    throw new BioLibExecutionError(
                        `Tried to copy the file ${fromPath} but found directory ${fromPath}/ instead.
If you are trying to copy the contents of a directory ensure the path ends with a trailing slash.`
                    );
                }
            }
        }
    }

    return mappedFiles;
}
