import React from 'react';
import { FieldProps } from 'formik';
import { ISettingOption } from '@biolibtech/biolib-js';
import { Switch } from '@headlessui/react';

interface IProps extends FieldProps {
    options: ISettingOption[];
}

export default function SwitchInput(props: IProps) {
    const { options, field, form } = props;
    const onOption = options.find((option) => option.key === 'on');
    const offOption = options.find((option) => option.key === 'off');
    const isChecked = onOption ? onOption.value === field.value : field.value === 'on';

    return (
        <Switch
            {...field}
            checked={isChecked}
            onChange={() => {
                form.setFieldValue(
                    field.name,
                    onOption && offOption ? (isChecked ? offOption.value : onOption.value) : isChecked ? 'off' : 'off'
                );
            }}
            className='group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-cyan-800'>
            <span className='sr-only'>Use setting</span>
            <span
                className='pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out group-data-[checked]:translate-x-5'
            >
                <span
                    aria-hidden='true'
                    className='absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out'>
                    <svg fill='none' viewBox='0 0 12 12' className='h-3 w-3 text-gray-400'>
                        <path
                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                            stroke='currentColor'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                </span>
                <span
                    aria-hidden='true'
                    className='absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in'>
                    <svg fill='currentColor' viewBox='0 0 12 12' className='h-3 w-3 text-cyan-800'>
                        <path
                            d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z'
                        />
                    </svg>
                </span>
            </span>
        </Switch>
    );
}
