import { AccountUuid, IUser } from "@biolibtech/biolib-js";
import { Reducer } from "redux";
import { ILoadingState, LoadingState } from '../../utils';
import { AccountsConstants } from "../accounts/accountsConstants";
import { RootActions } from "../rootActions";
import userConstants from "./userConstants";
import AuthCookie from '../../features/userFlow/utils/AuthCookie';

export interface IUserState {
    intrinsicAccountId: AccountUuid | undefined;
    isSignedIn: boolean;
    organizationIds: AccountUuid[];
    user: IUser | undefined;

    changeUserEmailLoadingState: ILoadingState;
    createUserLoadingState: ILoadingState;
    resetPasswordConfirmLoadingState: ILoadingState;
    resetPasswordLoadingState: ILoadingState;
    signInLoadingState: ILoadingState;
    updateAccountLoadingState: ILoadingState;
}

const defaultState: IUserState = {
    intrinsicAccountId: undefined,
    isSignedIn: false,
    organizationIds: [],
    user: undefined,

    createUserLoadingState: LoadingState.default,
    resetPasswordConfirmLoadingState: LoadingState.default,
    resetPasswordLoadingState: LoadingState.default,
    signInLoadingState: LoadingState.default,
    updateAccountLoadingState: LoadingState.default,
    changeUserEmailLoadingState: LoadingState.default,
};

export const userReducer: Reducer<IUserState> = (
    state: IUserState = { ...defaultState, isSignedIn: AuthCookie.isSignedIn() },
    action: RootActions
) => {
    switch (action.type) {
        case userConstants.SIGN_IN: {
            return {
                ...state,
                signInLoadingState: LoadingState.begin
            };
        }

        case userConstants.SET_USER:
        case userConstants.SIGN_IN_SUCCESS: {
            const { user, intrinsicAccountId, organizationIds } = action.payload;
            return {
                ...state,
                intrinsicAccountId,
                isSignedIn: true,
                organizationIds,
                user,
            };
        }

        case userConstants.SIGN_IN_FAILED: {
            return {
                ...state,
                user: undefined,
                isSignedIn: false,
                signInLoadingState: LoadingState.failure(action.errorMessage),
            };
        }

        case userConstants.CLEAR_SIGN_IN_ERRORS: {
            return {
                ...state,
                signInLoadingState: LoadingState.default,
            }
        }

        case userConstants.SIGN_OUT:
        case userConstants.CLEAR_USER_STATE: {
            return defaultState;
        }

        case userConstants.CREATE_USER: {
            return {
                ...state,
                createUserLoadingState: LoadingState.begin,
            }
        }

        case userConstants.CREATE_USER_SUCCESS: {
            const { user, intrinsicAccountId, organizationIds } = action.payload;
            return {
                ...state,
                intrinsicAccountId,
                isSignedIn: true,
                organizationIds,
                user,
            };
        }

        case userConstants.CREATE_USER_FAILED: {
            return {
                ...state,
                createUserLoadingState: LoadingState.failure(action.errorMessage),
            };
        }

        case userConstants.CLEAR_CREATE_USER_ERRORS: {
            return {
                ...state,
                createUserLoadingState: LoadingState.default,
            }
        }

        case AccountsConstants.CREATE_SUCCESS: {
            return {
                ...state,
                organizationIds: [...state.organizationIds, action.payload.account.public_id],
            }
        }

        case userConstants.RESET_PASSWORD: {
            return {
                ...state,
                resetPasswordLoadingState: LoadingState.begin,
            };
        }

        case userConstants.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                resetPasswordLoadingState: LoadingState.success,
            };
        }

        case userConstants.RESET_PASSWORD_FAILED: {
            return {
                ...state,
                resetPasswordLoadingState: LoadingState.failure(action.errorMessage),
            };
        }

        case userConstants.RESET_PASSWORD_CLEAR_LOADING_STATE: {
            return {
                ...state,
                resetPasswordLoadingState: LoadingState.default,
            };
        }

        case userConstants.RESET_PASSWORD_CONFIRM: {
            return {
                ...state,
                resetPasswordConfirmLoadingState: LoadingState.begin,
            };
        }

        case userConstants.RESET_PASSWORD_CONFIRM_SUCCESS: {
            return {
                ...state,
                resetPasswordConfirmLoadingState: LoadingState.success,
            };
        }

        case userConstants.RESET_PASSWORD_CONFIRM_FAILED: {
            return {
                ...state,
                resetPasswordConfirmLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case userConstants.RESET_PASSWORD_CONFIRM_CLEAR_LOADING_STATE: {
            return {
                ...state,
                resetPasswordConfirmLoadingState: LoadingState.default,
            }
        }

        case userConstants.UPDATE_ACCOUNT: {
            return {
                ...state,
                updateAccountLoadingState: LoadingState.begin,
            }
        }

        // TODO: Move this action to accounts
        case userConstants.UPDATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                updateAccountLoadingState: LoadingState.success,
            }
        }

        case userConstants.UPDATE_ACCOUNT_FAILURE: {
            return {
                ...state,
                updateAccountLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case userConstants.UPDATE_ACCOUNT_CLEAR_LOADING_STATE: {
            return {
                ...state,
                updateAccountLoadingState: LoadingState.default,
            }
        }

        case userConstants.CHANGE_EMAIL: {
            return {
                ...state,
                changeUserEmailLoadingState: LoadingState.begin,
            }
        }

        case userConstants.CHANGE_EMAIL_SUCCESS: {
            return {
                ...state,
                changeUserEmailLoadingState: LoadingState.success,
                user: action.payload.user,
            }
        }

        case userConstants.CHANGE_EMAIL_FAILURE: {
            return {
                ...state,
                changeUserEmailLoadingState: LoadingState.failure(action.errorMessage),
            }
        }

        case userConstants.CHANGE_EMAIL_CLEAR_LOADING_STATE: {
            return {
                ...state,
                changeUserEmailLoadingState: LoadingState.default,
            }
        }

        default:
            return state;
    }
};
