export type ObjectKeysToString<T> = {
    [P in keyof T]: string;
}

export function validatePassword(password: string): string {
    if (!password) {
        return 'Required'
    }

    if (password.length < 8) {
        return 'The password is too short. It must contain at least 8 characters.';
    }

    if (password.match(/^[0-9]+$/)) {
        return 'Password must contain letters'
    }

    return '';
}

export function isEmail(email: string): boolean {
    const match = email.match(
        // eslint-disable-next-line max-len
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    return (match !== null && match.length > 0);
}

export function validateEmail(email?: string): string {
    if (!email) {
        return 'Required'
    }

    if (!isEmail(email)) {
        return 'Invalid email address.';
    }

    return '';
}

export function validateAlphaNumericName(name: string | undefined): string | undefined {
    if (!name) {
        return 'Required';
    }
    if (name.length > 75) {
        return 'Can not be more than 75 characters';
    }
    if (!name.match(/^[A-Za-z0-9\s_-]+$/)) {
        return 'Can only contain alphanumeric characters, dashes and underscores'
    }
    if (name.match(/([-_\s][-_\s])/)) {
        return 'Can not contain consecutive spaces, dashes or underscores'
    }
    if (name.match(/^[-_][A-Za-z0-9\s_-]+$/)) {
        return 'Can not start with dashes or underscores'
    }
    if (name.match(/^[A-Za-z0-9_\s-]+[-_]$/)) {
        return 'Can not end with dashes or underscores'
    }
    if (name.match(/\s+/)) {
        return 'Can not contain spaces'
    }
    return undefined;
}

export function trimAndReplaceSpaces(string: string): string {
    return string.trim().replace(/[\s_-]+/g, '-');
}
