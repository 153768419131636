import { baseApi } from '../../api/baseApi';
import { BioLibSingleton } from '@biolibtech/biolib-js/src';
import AuthCookie from '../userFlow/utils/AuthCookie';
import userConstants from '../../state/user/userConstants';
import { navigate } from 'gatsby';

export const authApi = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        logout: build.mutation<null, void>({
            queryFn: async (args, { dispatch }, extraOptions, baseQuery) => {
                await baseQuery({ url: '/auth/logout/', method: 'POST' });
                // The backend should add Clear-Site-Data: "*" in the response header on logout
                // In case the browser does not handle that header we ensure to clear auth tokens from javascript
                AuthCookie.clear();
                BioLibSingleton.get().user.clearTokens();
                dispatch(baseApi.util.resetApiState());
                dispatch({ type: userConstants.SIGN_OUT }); // Clear legacy redux state
                window.location.assign(location.origin);  // Redirect to front page
                return { data: null };
            }
        }),
    })
});

export const {
    useLogoutMutation,
} = authApi;
