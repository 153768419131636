import { ISlimApp } from "@biolibtech/biolib-js";
import { IAppIdToFavoriteId } from "./app.types";
import {
    FavoriteAddAction,
    FavoriteAddFailedAction,
    FavoriteAddSuccessAction,
    FavoriteRemoveAction,
    FavoriteRemoveFailedAction,
    FavoriteRemoveSuccessAction,
    FavoritesFetchFailedAction,
    FavoritesFetchSuccessAction,

    TrendingFetchSuccessAction,
} from "./appsActionTypes";
import appsConstants from "./appsConstants";

export const appsActions = {

    trendingFetchSuccess: (trendingApps: ISlimApp[]): TrendingFetchSuccessAction => ({
        type: appsConstants.TRENDING_FETCH_SUCCESS,
        payload: { trendingApps }
    }),

    favoritesFetchSuccess: (
        favoriteApps: ISlimApp[],
        appIdToFavId: IAppIdToFavoriteId
    ): FavoritesFetchSuccessAction => ({
        type: appsConstants.FAVORITES_FETCH_SUCCESS,
        payload: { favoriteApps, appIdToFavoriteId: appIdToFavId }
    }),

    favoritesFetchFailed: (errorMessage: string): FavoritesFetchFailedAction => ({
        type: appsConstants.FAVORITES_FETCH_FAILED,
        errorMessage
    }),

    favoriteAdd: (appId: string): FavoriteAddAction => ({
        type: appsConstants.FAVORITE_ADD,
        payload: { appId }
    }),

    favoriteAddSuccess: (appId: string): FavoriteAddSuccessAction => ({
        type: appsConstants.FAVORITE_ADD_SUCCESS,
        payload: { appId }
    }),

    favoriteAddFailed: (errorMessage: string): FavoriteAddFailedAction => ({
        type: appsConstants.FAVORITE_ADD_FAILED,
        errorMessage
    }),

    favoriteRemove: (appId: string): FavoriteRemoveAction => ({
        type: appsConstants.FAVORITE_REMOVE,
        payload: { appId }
    }),

    favoriteRemoveSuccess: (appId: string): FavoriteRemoveSuccessAction => ({
        type: appsConstants.FAVORITE_REMOVE_SUCCESS,
        payload: { appId }
    }),

    favoriteRemoveFailed: (errorMessage: string): FavoriteRemoveFailedAction => ({
        type: appsConstants.FAVORITE_REMOVE_FAILED,
        errorMessage
    }),
};
