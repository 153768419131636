import BioLibBinaryFormatBase from './BioLibBinaryFormatBase';
import CustomDataView from './CustomDataView';

export interface IStdoutAndStderrPackage {
    stdout: Uint8Array;
    stderr: Uint8Array;
}

export default class StdoutAndStderrPackage extends BioLibBinaryFormatBase implements IStdoutAndStderrPackage {
    protected readonly TYPE = 10;

    public stdout: Uint8Array;
    public stderr: Uint8Array;

    constructor(data: IStdoutAndStderrPackage | Uint8Array) {
        super();
        const deserialized = data instanceof Uint8Array ? this.deserialize(data) : data;
        this.stdout = deserialized.stdout;
        this.stderr = deserialized.stderr;
    }

    public getAttributes(): IStdoutAndStderrPackage {
        return {
            stdout: this.stdout,
            stderr: this.stderr,
        };
    }

    public serialize(): Uint8Array {
        throw Error('OutputLogMessage: Serialize not implemented');
    }

    private deserialize(serialized: Uint8Array): IStdoutAndStderrPackage {

        const dataView = new CustomDataView(serialized.buffer, serialized.byteOffset, serialized.byteLength);
        this.assertVersionAndType(dataView);

        let pointer = 2;

        const stdoutLength = dataView.getUint32(pointer);
        pointer += 4;
        const stdout = serialized.slice(pointer, pointer + stdoutLength);
        pointer += stdoutLength

        const stderrLength = dataView.getUint32(pointer);
        pointer += 4;
        const stderr = serialized.slice(pointer, pointer + stderrLength);

        return { stdout, stderr };
    }
}
