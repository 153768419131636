import { AppClient, BioLibSingleton, ISlimApp, Paginated } from '@biolibtech/biolib-js';
import { channel } from 'redux-saga';
import { call, fork, put, take } from 'redux-saga/effects';
import config from '../config';
import { signInWithRefreshToken } from './userSaga';
import userFlowSlice from '../features/userFlow/userFlowSlice';
import AuthCookie from '../features/userFlow/utils/AuthCookie';
import LocalStorageManager from '../features/userFlow/utils/LocalStorageManager';
import { appsActions } from '../state/apps/appsActions';

export const eventChannel = channel();

function* watchEventChannel() {
    while (true) {
        const action = yield take(eventChannel);
        yield put(action);
    }
}

function* initialize() {
    try {
        // Initialize the BioLibApi before anything else
        // TODO: Remove this localStorage check soonish
        let refreshToken: string | null = localStorage.getItem(config.localStorageKey.refreshToken);
        if (!refreshToken) {
            refreshToken = AuthCookie.get();
        } else {
            AuthCookie.set(refreshToken);
            // Remove token from localStorage as it should now only be saved as a cookie
            localStorage.removeItem(config.localStorageKey.refreshToken);
        }
        BioLibSingleton.setConfig({ baseUrl: `${config.baseURL}`, refreshToken: refreshToken ?? undefined });
        AppClient.setApiClient(BioLibSingleton.get());

        const signInState = LocalStorageManager.signInState;
        if (signInState !== null) {
            LocalStorageManager.signInState = null;
            yield put(userFlowSlice.actions.setSignInState({ signInState }));
        }

        if (refreshToken) {
            yield call(signInWithRefreshToken);
        } else {
            yield put(userFlowSlice.actions.initialized());
        }

        try {
            const response: Paginated<ISlimApp> = yield BioLibSingleton.get().app.fetchTrending();
            yield put(appsActions.trendingFetchSuccess(response.results));
        } catch (error) {
            console.error('Failed to fetch trending apps: ', error);
        }

    } catch (error) {
        console.error(error);
    }
}

export default function* initializationSaga() {
    if (typeof window !== 'undefined') {
        yield call(initialize);
        yield fork(watchEventChannel);
    }
}
