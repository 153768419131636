import { ISettingOption } from '@biolibtech/biolib-js';
import { FieldProps } from 'formik';
import React from 'react';

interface IProps extends FieldProps {
    options: ISettingOption[];
}

export default function RadioGroupInput(props: IProps) {
    const { form, field, options } = props;
    return (
        <div onBlur={field.onBlur} className='space-y-3'>
            {options.map((option: ISettingOption, index: number) => {
                const id = `${field.name}-${index}`;
                return (
                    <div className='flex items-center' key={index}>
                        <input
                            checked={field.value === option.value}
                            className='border-gray-300 text-cyan-800 focus:ring-white cursor-pointer'
                            id={id}
                            onChange={() => form.setFieldValue(field.name, option.value)}
                            type='radio'
                        />
                        <label htmlFor={id} className='pl-3 block text-sm cursor-pointer'>
                            {option.key}
                        </label>
                    </div>
                );
            })}
        </div>
    );
}
