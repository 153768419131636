import React from 'react';
import { ArgumentType, ISetting, ISettingOption } from '@biolibtech/biolib-js';
import { FieldProps } from 'formik';
import SimpleFileInput from './fileInputs/SimpleFileInput';
import TextFileInput from './fileInputs/TextFileInput';
import MultiSelect from './MultiSelect';
import SwitchInput from './SwitchInput';
import TextAreaInput from './TextAreaInput';
import RadioGroupInput from './RadioGroupInput';
import DragAndDropArea from './fileInputs/DragAndDropArea';
import TextInput from './TextInput';
import SequenceInput from './SequenceInput';

interface IProps {
    setting: ISetting;
    fieldProps: FieldProps;
    autoSubmitOnceValid?: boolean;
    depth: number;
}

export default function RenderSetting(props: IProps) {
    const { setting, fieldProps, depth } = props;
    const { field, form } = fieldProps;

    switch (setting.render_type) {
        case ArgumentType.dropdown:
            return (
                <select
                    {...field}
                    className='block max-w-full overflow-auto min-w-72 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-cyan-800 sm:text-sm sm:leading-6'>
                    {setting.options.some((option: ISettingOption) => !option.value) ? null : (
                        <option label='Choose an item...' value='' />
                    )}
                    {setting.options.map((option: ISettingOption, index: number) => (
                        <option
                            key={index}
                            label={option.key}
                            title={option.key.length > 100 ? option.key : undefined}
                            value={option.value}
                        />
                    ))}
                </select>
            );

        case ArgumentType.radio:
            return <RadioGroupInput {...fieldProps} options={setting.options} />;

        case ArgumentType.file:
            return <SimpleFileInput {...fieldProps} defaultValue={setting.default_value} />;

        case ArgumentType.multifile:
            return <SimpleFileInput {...fieldProps} defaultValue={setting.default_value} allowMultipleFiles={true} />;

        case ArgumentType.sequence:
        case ArgumentType.sequenceBeta:
            return (<SequenceInput {...fieldProps} autoFocus={depth === 0}/>);

        case ArgumentType.textFile:
            return <TextFileInput {...fieldProps} defaultValue={setting.default_value} />;

        case ArgumentType.toggle: {
            return <SwitchInput {...fieldProps} options={setting.options} />;
        }

        case ArgumentType.multiselect: {
            return (
                <MultiSelect
                    allOptions={setting.options}
                    selectedOptions={field.value}
                    setSelectedOptions={options => form.setFieldValue(field.name, options, true)}
                />
            );
        }

        case ArgumentType.textarea: {
            return <TextAreaInput {...field} placeholder={setting.default_value} />;
        }

        case ArgumentType.dragAndDropFile:
            return (
                <DragAndDropArea
                    {...fieldProps}
                    defaultValue={setting.default_value}
                    autoSubmitOnceValid={props?.autoSubmitOnceValid}
                />
            );

        case ArgumentType.text:
        default:
            return <TextInput {...field} placeholder={setting.default_value} />;
    }
}
