import { ClearAction, IContactFormData, SubmitFormAction, SubmitFormSuccessAction } from "./contactActionTypes";
import contactConstants from "./contactConstants";

const contactActions = {
    submitForm: (payload: IContactFormData): SubmitFormAction => ({
        type: contactConstants.SUBMIT_FORM,
        payload
    }),

    submitFormSuccess: (): SubmitFormSuccessAction => ({
        type: contactConstants.SUBMIT_FORM_SUCCESS
    }),

    submitFormFailed: (errorMessage: string) => ({
        type: contactConstants.SUBMIT_FORM_FAILED,
        errorMessage
    }),

    clear: (): ClearAction => ({
        type: contactConstants.CLEAR
    })
};

export default contactActions;
