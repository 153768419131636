import * as React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { Button } from '@blueprintjs/core';
import { ErrorReportSendingState } from '../../../types';
import { bindActionCreators, Dispatch } from 'redux';
import appRunSlice from '../../../appRunSlice';
import { IRootState } from '../../../../../state/rootReducer';
import { connect } from 'react-redux';
import { JobUuid } from '@biolibtech/biolib-js';

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        sendErrorReport: appRunSlice.actions.sendErrorReport,
    },
    dispatch
);

type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface IProps extends ReduxProps {
    errorReportSendingState: ErrorReportSendingState;
    jobId: JobUuid;
}

interface IState {
    isPopoverOpen: boolean;
}

class ReportErrorButton extends React.Component<IProps, IState> {
    public state = {
        isPopoverOpen: false,
    }

    public render() {
        const { isPopoverOpen } = this.state;
        const { errorReportSendingState } = this.props;

        if (errorReportSendingState === ErrorReportSendingState.success) {
            return (<Button rightIcon='tick' intent='success' disabled={true}>Error Reported</Button>);
        }

        const isLoading = errorReportSendingState === ErrorReportSendingState.processing;
        return (
            <Popover2
                className='float-right'
                isOpen={isPopoverOpen}
                onClose={this.closePopover}
                content={
                    <div className='p-4'>
                        <Button fill={true} intent='primary' large={true} onClick={this.onSendErrorReport}>
                            Send Error Report
                        </Button>
                        <hr/>
                        <p className='text-gray-500'>
                            The error report will contain the <br/>
                            stacktrace and application log.
                        </p>
                    </div>
                }>
                <Button rightIcon='error' intent='primary' loading={isLoading} onClick={this.togglePopover}>
                    Report Error
                </Button>
            </Popover2>
        );
    }

    private onSendErrorReport = () => {
        this.props.sendErrorReport({ jobId: this.props.jobId });
        this.closePopover();
    }

    private closePopover = () => {
        this.setState({ isPopoverOpen: false });
    }

    private togglePopover = () => {
        this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportErrorButton);
