import BaseResource from './BaseResource';
import { IApiToken } from './ApiToken.types';
import { Paginated } from './common.types';

export default class ApiToken extends BaseResource {
    protected readonly basePath = '/api/user/api_tokens';

    public fetchAll(params?: { page?: number; page_size?: number; }): Promise<Paginated<IApiToken>> {
        return this.get({ params, url: '/', shouldAuthenticate: true });
    }

    public createToken(data: { name: string; }): Promise<object> {
        return this.post({ data, url: '/', shouldAuthenticate: true });
    }

    public updateToken(apiTokenId: string, data: { name: string; }): Promise<IApiToken> {
        return this.patch({ data, url: `/${apiTokenId}/`, shouldAuthenticate: true });
    }

    public deleteToken(apiTokenId: string): Promise<void> {
        return this.delete({ url: `/${apiTokenId}/`, shouldAuthenticate: true });
    }
}
