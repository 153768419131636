import { Intent } from "@blueprintjs/core";
import {
    AddAction,
    ClearToastsAction,
    IButtonAction,
    INotification,
    NotificationTypes,
    RemoveAction,
} from "./notificationActionTypes";
import notificationConstants from "./notificationConstants";

export const notificationActions = {
    add: (notification: INotification): AddAction => ({
        type: notificationConstants.ADD,
        payload: { notification },
    }),

    addInfo: (message: string, type?: NotificationTypes, action?: IButtonAction): AddAction => ({
        type: notificationConstants.ADD,
        payload: {
            notification: {
                type: type || NotificationTypes.UNDEFINED,
                message,
                action,
                timestamp: new Date().toString(),
                intent: Intent.PRIMARY,
            },
        },
    }),

    addError: (
        message: string, type?: NotificationTypes, action?: IButtonAction, disableTimeout?: boolean): AddAction =>
        ({
            type: notificationConstants.ADD,
            payload: {
                notification: {
                    type: type || NotificationTypes.UNDEFINED,
                    message,
                    action,
                    timestamp: new Date().toString(),
                    intent: Intent.DANGER,
                    timeout: disableTimeout ? 0 : 10000,
                },
            },
        }),

    addSuccess: (message: string, type?: NotificationTypes, action?: IButtonAction): AddAction => ({
        type: notificationConstants.ADD,
        payload: {
            notification: {
                type: type || NotificationTypes.UNDEFINED,
                message,
                action,
                timestamp: new Date().toString(),
                intent: Intent.SUCCESS,
            },
        },
    }),

    remove: (notificationIndex: number): RemoveAction => ({
        type: notificationConstants.REMOVE,
        payload: { notificationIndex },
    }),

    clearToasts: (): ClearToastsAction => ({
        type: notificationConstants.CLEAR_TOASTS,
    }),
};

export default notificationActions;
