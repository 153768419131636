import * as React from 'react';
import userFlowSlice from '../features/userFlow/userFlowSlice';
import GitHubSsoButton from './GitHubSsoButton';
import HrWithOr from './HrWithOr';
import { Checkbox, FormGroup, Icon, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { ILoadingState, navigateWindow } from '../utils';
import { Link } from 'gatsby';
import { userActions } from '../state/user/userActions';
import {
    CustomInput,
    CustomPasswordInput,
    SubmitButton,
    validateEmail,
    validatePassword
} from './common/formElements';
import config from '../config';

interface IProps {
    createUserLoadingState: ILoadingState;
    gitHubSignInLoadingState: ILoadingState;
    isSignedIn: boolean;

    clearCreateAccountErrors: typeof userActions.clearCreateAccountErrors;
    createUser: typeof userActions.createUser;
    gitHubSignIn: typeof userFlowSlice.actions.gitHubSignIn;

    // Callbacks for the AppOverlay
    onClickSignIn?: () => void;
    onNavigateAway?: () => void;
}

const initialValues = {
    display_name: '',
    password: '',
    subject: '',
    userAgreement: false,
    username: '',
};

export default class SignUp extends React.Component<IProps> {
    public componentWillUnmount(): void {
        this.props.clearCreateAccountErrors();
    }

    public componentDidMount(): void {
        if (this.props.isSignedIn && !this.props.onNavigateAway) {
            navigateWindow('/');
        }
    }

    public render() {
        const { gitHubSignInLoadingState } = this.props;

        if (gitHubSignInLoadingState.isLoading) {
            return (
                <div style={{ paddingTop: 2 }}>
                    <h1 className='subtitle is-size-3 has-text-centered'>Create your account</h1>
                    <Spinner/>
                </div>
            );
        }

        return (
            <Formik
                initialValues={initialValues}
                validate={this.validate}
                onSubmit={this.onSubmit}
            >
                <Form noValidate={true} method='POST' autoComplete='off'>
                    <h1 className='subtitle is-size-3 has-text-centered'>Create your account</h1>
                    <GitHubSsoButton
                        githubSignIn={this.props.gitHubSignIn}
                        isSignUp={true}
                        onNavigateAway={this.props.onNavigateAway}
                    />
                    <HrWithOr/>
                    <Field name='username' component={EmailInputField}/>
                    <Field name='display_name' component={DisplayNameField}/>
                    <Field name='password' component={PasswordInputField}/>
                    {config.isEnterprise ? null :
                        <Field name='userAgreement' component={UserAgreementField}/>
                    }
                    <div style={{ position: 'absolute', left: '-5000px' }}>
                        {/* If filled the form does not validate, this should not happen to actual human users. */}
                        <Field name='subject' component={HoneyPotBotCheck}/>
                    </div>
                    <SubmitButton
                        fill={true}
                        isSubmitting={this.props.createUserLoadingState.isLoading}
                        text='Sign up with email'
                    />
                    <p className='has-text-danger'>{this.props.createUserLoadingState.errorMessage}</p>
                    <hr/>
                    <p className='has-text-centered'>
                        Already have a BioLib account?{' '}
                        {this.props.onClickSignIn ?
                            <a onClick={this.props.onClickSignIn}>
                                Click here to sign in <Icon icon='chevron-right'/>
                            </a> :
                            <Link to='/sign-in/'>Click here to sign in <Icon icon='chevron-right'/></Link>
                        }
                    </p>
                </Form>
            </Formik>
        )
    }

    private validate = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<typeof initialValues> = {};

        const emailError = validateEmail(values.username);
        if (emailError !== '' && values.username !== '') {
            errors.username = emailError;
        }

        const passwordError = validatePassword(values.password);
        if (passwordError !== '') {
            errors.password = passwordError;
        }

        if (!values.display_name) {
            errors.display_name = 'Required';
        }

        if (!values.userAgreement && !config.isEnterprise) {
            errors.userAgreement = 'Required';
        }

        // Name 'Subject' used for a simple honeypot bot check.
        // If filled the form does not validate, this should not happen to actual human users.
        if (values.subject !== '') {
            errors.subject = 'Error';
        }

        return errors;
    }

    private onSubmit = ({ username, display_name, password }: typeof initialValues): void => {
        this.props.createUser({ email: username, display_name, password });
    }
}

const EmailInputField: React.FC<FieldProps> = CustomInput({
    autoFocus: true,
    leftIcon: 'envelope',
    label: 'Email',
    required: true,
    type: 'email',
});

const DisplayNameField = CustomInput({
    autocompleteDisabled: true,
    leftIcon: 'user',
    label: 'Display Name',
    type: 'text',
});

const PasswordInputField: React.FC<FieldProps> = CustomPasswordInput({
    autocompleteDisabled: true,
    label: 'Password',
});

export const UserAgreementField: React.FC<FieldProps> = (props) => {
    const { value, name, onBlur, onChange } = props.field;
    const { touched, errors } = props.form;
    const isError = touched[name] && errors[name];
    return (
        <FormGroup
            helperText={isError ? errors[name] : ''}
            intent={isError ? Intent.DANGER : Intent.NONE}
        >
            <Checkbox
                checked={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                autoComplete={'off'}
            >
                I agree to the <a href='/legal/terms/' target='_blank'>
                terms and conditions</a>.
            </Checkbox>
        </FormGroup>
    )
};

const HoneyPotBotCheck = (props: FieldProps) => {
    const { onChange, onBlur, name, value } = props.field;
    return (
        <FormGroup label={'Subject'}>
            <InputGroup
                placeholder={'Subject'}
                id={name}
                large={true}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                tabIndex={-1}
                value={value}
            />
        </FormGroup>
    )
};
