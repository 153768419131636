export enum AccountsConstants {
    ACCOUNT_FETCH = 'accounts/ACCOUNT_FETCH',
    ACCOUNT_FETCH_SUCCESS = 'accounts/ACCOUNT_FETCH_SUCCESS',
    ACCOUNT_FETCH_FAILURE = 'accounts/ACCOUNT_FETCH_FAILURE',

    CLEAR_ACCOUNT_LOADING_STATE = 'accounts/CLEAR_PROFILE_LOADING_STATE',

    APP_FEED_FETCH = 'accounts/APP_FEED_FETCH',
    APP_FEED_FETCH_SUCCESS = 'accounts/APP_FEED_FETCH_SUCCESS',
    APP_FEED_FETCH_FAILURE = 'accounts/APP_FEED_FETCH_FAILURE',

    APP_FEED_CHANGE_PAGE = 'accounts/APP_FEED_CHANGE_PAGE',
    APP_FEED_CHANGE_PAGE_SUCCESS = 'accounts/APP_FEED_CHANGE_PAGE_SUCCESS',
    APP_FEED_CHANGE_PAGE_FAILURE = 'accounts/APP_FEED_CHANGE_PAGE_FAILURE',

    FETCH_APPS = 'accounts/FETCH_APPS',
    FETCH_APPS_SUCCESS = 'accounts/FETCH_APPS_SUCCESS',
    FETCH_APPS_FAILURE = 'accounts/FETCH_APPS_FAILURE',

    APPS_CHANGE_PAGE = 'accounts/APPS_CHANGE_PAGE',
    APPS_CHANGE_PAGE_SUCCESS = 'accounts/APPS_CHANGE_PAGE_SUCCESS',
    APPS_CHANGE_PAGE_FAILURE = 'accounts/APPS_CHANGE_PAGE_FAILURE',

    CREATE = 'accounts/CREATE',
    CREATE_SUCCESS = 'accounts/CREATE_SUCCESS',
    CREATE_FAILURE = 'accounts/CREATE_FAILURE',

    INVITE = 'accounts/INVITE',
    INVITE_SUCCESS = 'accounts/INVITE_SUCCESS',
    INVITE_FAILURE = 'accounts/INVITE_FAILURE',
    INVITE_CLEAR_LOADING_STATE = 'accounts/INVITE_CLEAR_LOADING_STATE',

    DELETE_MEMBER = 'accounts/DELETE',
    DELETE_MEMBER_SUCCESS = 'accounts/DELETE_SUCCESS',
    DELETE_MEMBER_FAILURE = 'accounts/DELETE_FAILURE',

    UPDATE_MEMBER_ROLE = 'accounts/UPDATE_MEMBER_ROLE',
    UPDATE_MEMBER_ROLE_SUCCESS = 'accounts/UPDATE_MEMBER_ROLE_SUCCESS',
    UPDATE_MEMBER_ROLE_FAILURE = 'accounts/UPDATE_MEMBER_ROLE_FAILURE',

    FETCH_MEMBERS = 'accounts/FETCH_MEMBERS',
    FETCH_MEMBERS_SUCCESS = 'accounts/FETCH_MEMBERS_SUCCESS',
    FETCH_MEMBERS_FAILURE = 'accounts/FETCH_MEMBERS_FAILURE',
}
