import * as React from 'react';
import { AiFillGithub } from 'react-icons/all';
import { Button } from '@blueprintjs/core';
import userFlowSlice from '../features/userFlow/userFlowSlice';

interface IProps {
    isSignUp?: boolean;

    githubSignIn: typeof userFlowSlice.actions.gitHubSignIn;
    onNavigateAway?: () => void;
}

const GitHubSsoButton: React.FC<IProps> = (props) => (
    <div style={{ marginBottom: 10, marginTop: 10, textAlign: 'left' }} className='has-text-left'>
        <Button
            icon={<AiFillGithub size={24}/>}
            large={true}
            fill={true}
            onClick={() => {
                if (props.onNavigateAway) {
                    props.onNavigateAway();
                }
                props.githubSignIn({});
            }}
        >
            {props.isSignUp ? 'Sign up' : 'Sign in'} with GitHub
        </Button>
    </div>
);

export default GitHubSsoButton;
