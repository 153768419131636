import * as React from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import { navigate } from 'gatsby';

interface IProps {
    autoFocus?: boolean;
    className?: string;
    isTeamSubdomain?: boolean;
    isSearchButtonHidden?: boolean;
    large: boolean;
    onSearch?: () => void;
    placeholder: string;
    clearStateToggle?: boolean;
    isDisabled?: boolean;
}

interface IState {
    searchFieldValue: string;
}

export default class SearchField extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            searchFieldValue: ''
        };
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.props.clearStateToggle !== undefined && prevProps.clearStateToggle !== this.props.clearStateToggle) {
            this.setState({ searchFieldValue: '' });
        }
    }

    public render() {
        const { searchFieldValue } = this.state;
        const { large, placeholder, autoFocus, className, isSearchButtonHidden, isDisabled } = this.props;

        return (
            <InputGroup
                className={className}
                large={large}
                placeholder={placeholder}
                leftIcon='search'
                type='text'
                autoFocus={autoFocus}
                value={isDisabled ? placeholder : searchFieldValue}
                onChange={this.handleSearchFieldChange}
                onKeyPress={this.handleKeyPressInSearchField}
                fill={true}
                disabled={isDisabled}
                rightElement={isSearchButtonHidden ? undefined :
                    <Button onClick={this.handleSearch} large={large}>
                        Search
                    </Button>
                }
            />
        );
    }

    private handleKeyPressInSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    };

    private handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchFieldValue: event.currentTarget.value });
    };

    private handleSearch = () => {
        if (this.props.onSearch) {
            this.props.onSearch();
        }
        const { searchFieldValue } = this.state;
        if (searchFieldValue !== '') {
            if (this.props.isTeamSubdomain) {
                window.location.href = `https://biolib.com/explore/?q=${searchFieldValue}`;
            } else {
                navigate(`/explore/?q=${searchFieldValue}`);
            }
        } else {
            if (this.props.isTeamSubdomain) {
                window.location.href = `https://biolib.com/explore/`;
            } else {
                navigate(`/explore/`);
            }
        }
    };
}
