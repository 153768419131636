import React from 'react';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { Badge } from '@components/base/badge';
import { XMarkIcon as XMarkIconSmall } from '@heroicons/react/16/solid';
import { ISettingOption } from '@biolibtech/biolib-js';

interface IProps {
    allOptions: ISettingOption[];
    selectedOptions: ISettingOption[];
    setSelectedOptions: (options: ISettingOption[]) => void;
}

export default function MultiSelect(props: IProps) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { allOptions, selectedOptions, setSelectedOptions } = props;
    const [query, setQuery] = useState('');

    const filteredOptions: ISettingOption[] = query === '' ? allOptions :
        allOptions.filter((option) => option.key.replace('<tag>', '').toLowerCase().includes(query.toLowerCase()));

    function removeOption(option: ISettingOption) {
        setSelectedOptions(selectedOptions.filter(({ key }) => key !== option.key));
    }

    function openDropdown() {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    return (
        <Combobox
            immediate={true}
            multiple={true}
            as='div'
            value={selectedOptions}
            onChange={setSelectedOptions}
            onClose={() => setQuery('')}
            className='flex flex-col space-y-2 w-full'
            virtual={{
                // @ts-ignore
                options: filteredOptions,
            }}
        >
            <div
                className='flex flex-row w-full justify-between rounded-md bg-white text-gray-900 shadow-sm border border-gray-300'
            >
                <div className='flex-auto flex flex-wrap flex-row p-0.5 items-center'>
                    {selectedOptions.map((option) => {
                        const { text } = splitOptionKey(option.key);
                        return (
                            <Badge key={option.key} className='whitespace-nowrap w-fit h-fit p-1 m-0.5'>
                                <span className='cursor-pointer' onClick={openDropdown}>
                                    {text}
                                </span>
                                <XMarkIconSmall
                                    className='h-4 w-4 cursor-pointer hover:text-red-600'
                                    onClick={() => removeOption(option)}
                                />
                            </Badge>
                        );
                    })}

                    <ComboboxInput
                        ref={inputRef}
                        className='grow border-none min-w-10 h-7 focus:ring-none focus:ring-inset focus:ring-cyan-800 rounded-md m-0.5'
                        onChange={(event) => setQuery(event.target.value)}
                        onBlur={() => setQuery('')}
                        placeholder='Click to add...'
                    />
                </div>

                <div
                    className='flex-none flex items-center w-10 border-l border-gray-300 inset-y-0 bottom-0 right-0 rounded-r-md focus:outline-none p-1'
                >
                    <div className='m-auto p-1 cursor-pointer hover:bg-gray-100 rounded-md'
                         onClick={() => setSelectedOptions([])}>
                        <XMarkIcon className='h-5 w-5 text-gray-400 m-auto' aria-hidden='true'/>
                    </div>
                </div>
            </div>

            <div className='w-full relative'>
                {!query || filteredOptions.length > 0 ? null :
                    <div
                        className='absolute border z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                    >
                        <p className='p-3 text-gray-500'>No results</p>
                    </div>
                }

                <ComboboxOptions
                    className='absolute border empty:invisible z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                >
                    {({ option }) => {
                        const { text, label } = splitOptionKey(option.key);
                        return (
                            <ComboboxOption
                                key={option.key}
                                value={option}
                                className='w-full border-b border-gray-50 group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-cyan-800 data-[focus]:text-white'
                            >
                            <span
                                className='absolute inset-y-0 left-0 flex items-center px-2 text-cyan-800 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden'
                            >
                                <CheckIcon aria-hidden='true' className='h-5 w-5'/>
                            </span>
                                <span className='ml-6'>{text}</span>
                                <span className='ml-3 group-data-[focus]:text-gray-200 text-gray-500'>
                                {label}
                            </span>
                            </ComboboxOption>
                        );
                    }}
                </ComboboxOptions>
            </div>
        </Combobox>
    );
}

function splitOptionKey(key: string): { text: string; label: string; } {
    const keyParts = key.split('<tag>');
    const text = keyParts.shift() ?? '';
    const label = keyParts.shift() ?? '';
    return { text, label };
}
