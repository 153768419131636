export function getUnchangedAttributes(
    oldObject: Record<string, any>,
    newObject: Record<string, any>
): Record<string, any> {
    const returnObject: Record<string, any> = {};

    // Only send the attributes that have been changed
    for (const key in newObject) {
        if (oldObject[key] !== newObject[key]) {
            returnObject[key] = newObject[key];
        }
    }
    return returnObject;
}
