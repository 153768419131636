enum userConstants {
    SIGN_IN = "user/SIGN_IN",
    SIGN_IN_SUCCESS = "user/SIGN_IN_SUCCESS",
    SIGN_IN_FAILED = "user/SIGN_IN_FAILED",

    SET_USER = "user/SET_USER",

    CLEAR_SIGN_IN_ERRORS = "user/CLEAR_SIGN_IN_ERRORS",

    SIGN_OUT = "user/SIGN_OUT",
    CLEAR_USER_STATE = "user/CLEAR_USER_STATE",
    AUTHENTICATION_REQUIRED = "user/AUTHENTICATION_REQUIRED",

    CREATE_USER = "user/CREATE_USER",
    CREATE_USER_SUCCESS = "user/CREATE_USER_SUCCESS",
    CREATE_USER_FAILED = "user/CREATE_USER_FAILED",

    CLEAR_CREATE_USER_ERRORS = "user/CLEAR_CREATE_ACCOUNT_ERRORS",

    CHANGE_EMAIL = "user/CHANGE_EMAIL",
    CHANGE_EMAIL_SUCCESS = "user/CHANGE_EMAIL_SUCCESS",
    CHANGE_EMAIL_FAILURE = "user/CHANGE_EMAIL_FAILURE",
    CHANGE_EMAIL_CLEAR_LOADING_STATE = "user/CHANGE_EMAIL_CLEAR_LOADING_STATE",

    UPDATE_ACCOUNT = "user/UPDATE_ACCOUNT",
    UPDATE_ACCOUNT_SUCCESS = "user/UPDATE_ACCOUNT_SUCCESS",
    UPDATE_ACCOUNT_FAILURE = "user/UPDATE_ACCOUNT_FAILURE",
    UPDATE_ACCOUNT_CLEAR_LOADING_STATE = "user/UPDATE_ACCOUNT_CLEAR_LOADING_STATE",

    RESET_PASSWORD = "user/RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS = "user/RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED = "user/RESET_PASSWORD_FAILED",
    RESET_PASSWORD_CLEAR_LOADING_STATE = "user/RESET_PASSWORD_CLEAR_LOADING_STATE",

    RESET_PASSWORD_CONFIRM = "user/RESET_PASSWORD_CONFIRM",
    RESET_PASSWORD_CONFIRM_SUCCESS = "user/RESET_PASSWORD_CONFIRM_SUCCESS",
    RESET_PASSWORD_CONFIRM_FAILED = "user/RESET_PASSWORD_CONFIRM_FAILED",
    RESET_PASSWORD_CONFIRM_CLEAR_LOADING_STATE = "user/RESET_PASSWORD_CONFIRM_CLEAR_LOADING_STATE",

    VERIFY_EMAIL = "user/VERIFY_EMAIL",
    VERIFY_EMAIL_SUCCESS = "user/VERIFY_EMAIL_SUCCESS",
    VERIFY_EMAIL_FAILED = "user/VERIFY_EMAIL_FAILED",

    REQUEST_VERIFICATION_EMAIL = "user/REQUEST_VERIFICATION_EMAIL",
}

export default userConstants;
