import BaseResource from './BaseResource';
import { BioLibApiError, BioLibNotFoundError } from '../errors';
import {
    IApp,
    IAppMetadata,
    IAppVersion,
    ISlimApp,
} from './AppTypes';
import { Paginated } from './common.types';

interface IAppGetParams {
    account_handle?: string;
    page?: number;
    page_size?: number;
    search?: string;
}

export interface IGetAppResponse {
    app: IApp;
    app_uri: string;
    app_version: IAppVersion;
}

export default class App extends BaseResource {
    protected readonly basePath = '/api';

    public create(data: { uri: string; }): Promise<{ uuid: string; uri: string; }> {
        return this.post({
            data,
            shouldAuthenticate: true,
            url: `/resources/apps/`,
        });
    }

    public fetchAppVersionById(app_version_id: string, license?: string): Promise<IAppVersion> {
        return new Promise<IAppVersion>(async (resolve, reject) => {
            try {
                const appVersion = await this.get<IAppVersion>({
                    url: `/app_versions/${app_version_id}/`,
                    shouldAuthenticate: true,
                    params: { license }
                });

                if (appVersion) {
                    resolve(appVersion);
                } else {
                    reject(new BioLibNotFoundError(`Could not find appVersion with ID: ${app_version_id}`));
                }

            } catch (error) {
                if (error instanceof BioLibApiError) {
                    reject(error);
                } else {
                    reject(new BioLibApiError(`Failed to fetch appVersion with ID: ${app_version_id}`));
                }
            }
        });
    }

    public async fetchByUri(uri: string): Promise<IGetAppResponse> {
        try {
            return await this.get<IGetAppResponse>({
                url: `/app/`,
                params: { uri },
                shouldAuthenticate: true,
            });
        } catch (error) {
            if (error instanceof BioLibApiError) {
                throw error;
            } else {
                throw new BioLibApiError(`Failed to fetch ${uri}`);
            }
        }
    }

    public fetchMultiple(params?: IAppGetParams): Promise<Paginated<ISlimApp>> {
        return this.get<Paginated<ISlimApp>>({ url: `/apps/`, params, shouldAuthenticate: true });
    }

    public fetchTrending(): Promise<Paginated<ISlimApp[]>> {
        return this.get({ url: `/trending_apps/` });
    }

    public update(appId: string, data: Partial<IAppMetadata>): Promise<ISlimApp> {
        return this.patch<ISlimApp>({
            data,
            shouldAuthenticate: true,
            url: `/apps/${appId}/`,
        });
    }

    public deleteApp(appId: string): Promise<void> {
        return this.delete<void>({
            shouldAuthenticate: true,
            url: `/apps/${appId}/`
        });
    }
}
