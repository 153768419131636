enum appsConstants {
    FAVORITES_FETCH = "apps/FAVORITES_FETCH",
    FAVORITES_FETCH_SUCCESS = "apps/FAVORITES_FETCH_SUCCESS",
    FAVORITES_FETCH_FAILED = "apps/FAVORITES_FETCH_FAILED",

    FAVORITE_ADD = "apps/FAVOURITE_ADD",
    FAVORITE_ADD_SUCCESS = "apps/FAVORITE_ADD_SUCCESS",
    FAVORITE_ADD_FAILED = "apps/FAVORITE_ADD_FAILED",

    FAVORITE_REMOVE = "apps/FAVORITE_REMOVE",
    FAVORITE_REMOVE_SUCCESS = "apps/FAVORITE_REMOVE_SUCCESS",
    FAVORITE_REMOVE_FAILED = "apps/FAVORITE_REMOVE_FAILED",

    TRENDING_FETCH = "apps/TRENDING_FETCH",
    TRENDING_FETCH_SUCCESS = "apps/TRENDING_FETCH_SUCCESS",
    TRENDING_FETCH_FAILED = "apps/TRENDING_FETCH_FAILED",
}

export default appsConstants;
