import BaseResource from './BaseResource';
import { AccountHandle } from './Account.types';
import { AppUuid } from './AppTypes';
import { ILicense, ILicensePatch, ILicensePost, LicenseUuid } from './License.types';
import { Paginated } from './common.types';

export default class License extends BaseResource {
    protected readonly basePath = '/api/licenses';

    public create(data: ILicensePost): Promise<ILicense> {
        return this.post<ILicense>({ url: `/`, data, shouldAuthenticate: true });
    }

    public update(licenseId: LicenseUuid, data: ILicensePatch): Promise<ILicense> {
        return this.patch<ILicense>({ url: `/${licenseId}/`, data, shouldAuthenticate: true });
    }

    public fetchCreatedForApp(params: {
        app: AppUuid;
        page?: number;
        page_size?: number;
    }): Promise<Paginated<ILicense>> {
        return this.get<Paginated<ILicense>>({ url: `/`, params, shouldAuthenticate: true });
    }

    public fetchCreatedByAccount(params: {
        account_handle: AccountHandle;
        page?: number;
        page_size?: number;
    }): Promise<Paginated<ILicense>> {
        return this.get<Paginated<ILicense>>({ url: `/`, params, shouldAuthenticate: true });
    }

    public fetchAssigned(): Promise<Paginated<ILicense>> {
        return this.get<Paginated<ILicense>>({ url: `/assigned/`, shouldAuthenticate: true });
    }
}
