import { IApp, IAppVersion, IJob } from '@biolibtech/biolib-js';
import { ISettingValues } from './components/tabs/InputTab/types';

export enum JobStatus {
    acceptTerms,
    input,
    computing,
    finished,
    error,
}

export interface ILogMessage {
    timestamp: string;
    message: string;
}

export enum ErrorReportSendingState {
    notInitiated,
    failed,
    success,
    processing,
}

export interface IJobWrapper {
    app: IApp;
    error: Error | null;
    errorReportSendingState: ErrorReportSendingState;
    job: IJob;
    logMessages: ILogMessage[];
    streamedOutput: string;
    progressCompute: number;
    progressInitialization: number;
    status: JobStatus;
}

export interface IJobWrapperDict {
    [jobId: string]: IJobWrapper;
}

export interface IJobDataWrapper {
    settingValues: ISettingValues;
}

export interface IJobDataWrapperDict {
    [jobId: string]: IJobDataWrapper;
}

export interface IOpenAppPayload {
    app: IApp;
    license_token?: string;
    openInModal?: boolean;
    selectedAppVersion: IAppVersion;
}
