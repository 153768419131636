import React from 'react';
import clsx from 'clsx';

interface IProps {
    className?: string;
    children?: any;
}

export default function Container(props: IProps){
    return (
        <div className={clsx('container', props.className)}>
            {props.children}
        </div>
    );
};
