import { IFilesDict, IFileSimple } from '../types';
import { IFilesMapping } from '../../BioLib';
import { getFilesInMappings } from './utils';
import BioLibBinaryFormatBase from './BioLibBinaryFormatBase';
import CustomDataView from './CustomDataView';

export default abstract class BioLibBinaryFormatBaseWithFiles extends BioLibBinaryFormatBase {
    public abstract files: IFilesDict;

    protected textDecoder = new TextDecoder('utf-8', { fatal: true });
    protected textEncoder = new TextEncoder();

    public applyFilesMappings(filesMappings: IFilesMapping[], parameters: string[]): void {
        this.files = getFilesInMappings(
            filesMappings,
            parameters,
            this.getSingleFile.bind(this),
            this.getFilesInDirectory.bind(this),
            true
        );
    }

    public filterOnFilesMappings(filesMappings: IFilesMapping[], parameters: string[]): void {
        this.files = getFilesInMappings(
            filesMappings,
            parameters,
            this.getSingleFile.bind(this),
            this.getFilesInDirectory.bind(this),
            false
        );
    }

    protected* getFilesInDirectory(directoryPath: string): Generator<{ path: string; file: IFileSimple; }> {
        for (const path in this.files) {
            if (path.startsWith(directoryPath)) {
                yield { path, file: this.files[path] };
            }
        }
    }

    protected getSingleFile(path: string): IFileSimple | null {
        if (this.files[path]) {
            return this.files[path];
        }
        return null;
    };

    protected getFilesSerializedLength(files: IFilesDict): number {
        let filesSerializedByteLength = 0;
        const serializedFileHeaderLength = 12;

        for (const path in files) {
            const file = files[path];
            const pathAsBytes = this.textEncoder.encode(path);
            filesSerializedByteLength += serializedFileHeaderLength + pathAsBytes.byteLength + file.data.byteLength;
        }

        return filesSerializedByteLength;
    }

    protected serializeFilesIntoByteArray(files: IFilesDict, byteArray: Uint8Array, startPosition: number): void {
        let pointer = startPosition;

        for (const path in files) {
            const file = files[path];
            const pathAsBytes = this.textEncoder.encode(path);

            const fileHeaderDataView = new CustomDataView(byteArray.buffer, pointer, 12);
            fileHeaderDataView.setUint32(0, pathAsBytes.byteLength);
            fileHeaderDataView.setBigUint64(4, BigInt(file.data.byteLength));
            pointer += 12;

            byteArray.set(pathAsBytes, pointer);
            pointer += pathAsBytes.byteLength;

            byteArray.set(file.data, pointer);
            pointer += file.data.byteLength;
        }
    }

    protected deserializeFiles(
        serialized: Uint8Array,
        filesStartPointer: number,
        filesSerializedByteLength: number,
    ): IFilesDict {
        const dataView = new CustomDataView(serialized.buffer, serialized.byteOffset, serialized.byteLength);

        const files: IFilesDict = {};

        let pointer = filesStartPointer;
        const filesEndLocation = filesStartPointer + filesSerializedByteLength;
        while (pointer < filesEndLocation) {
            const pathLength = dataView.getUint32(pointer);
            pointer += 4;

            const dataLengthBigUint = dataView.getBigUint64(pointer);
            pointer += 8;

            const dataLength = Number(dataLengthBigUint);
            const pathEncoded = serialized.slice(pointer, pointer + pathLength);
            pointer += pathLength;

            const path = this.textDecoder.decode(pathEncoded);
            const data = serialized.slice(pointer, pointer + dataLength);
            files[path] = { data };

            pointer += dataLength;
        }

        return files;
    }
}
