import * as React from 'react'
import { Callout } from '@blueprintjs/core';
import HelpButtons from './HelpButtons';

interface IRemoteComputeErrorProps {
    errorName: string;
}

const RemoteComputeError: React.FC<IRemoteComputeErrorProps> = (props) => (
    <>
        <Callout intent='danger' title={`Error: ${props.errorName}`}/>
        <div className='my-3 p-1 max-w-xl text-large text-gray-700'>
            {props.children}
        </div>
        <hr className='my-4'/>
        <HelpButtons/>
    </>
)

export const JobRuntimeLimitExceeded: React.FC = () => (
    <RemoteComputeError errorName='The compute job exceeded its time limit'>
        <p>
            Sorry, it looks like the application was taking too long and hit the time limit.
            <br/>
            If you wish to increase this limit, or have any questions, reach out to us below.
            <br/>
            <br/>
            Alternatively, you can try re-running the application with less input data.
        </p>
    </RemoteComputeError>
)

export const ContainerRanOutOfMemory: React.FC = () => (
    <RemoteComputeError errorName='The container ran out of memory'>
        <p>
            Sorry, it looks like the application exceeded the memory limit.
            <br/>
            If you wish to increase this limit, or have any questions, reach out to us below.
            <br/>
            <br/>
            Alternatively, you can try re-running the application with less input data.
        </p>
    </RemoteComputeError>
)
