import React, { useState, useRef } from 'react';

interface IProps {
    children: React.ReactNode;
}

export default function ResizeableDiv(props: IProps) {
    const minHeight = 100;
    const [height, setHeight] = useState(minHeight);
    const startY = useRef(0);
    const startHeight = useRef(0);
    const isResizing = useRef(false);

    const handleMouseDownResize = (event: React.MouseEvent) => {
        event.preventDefault();
        isResizing.current = true;
        startY.current = event.clientY;
        startHeight.current = height;
        document.addEventListener('mousemove', handleMouseMoveResize);
        document.addEventListener('mouseup', handleMouseUpResize);
    };

    const handleMouseMoveResize = (event: MouseEvent) => {
        if (isResizing.current) {
            const newHeight = startHeight.current + (event.clientY - startY.current);
            setHeight(newHeight > minHeight ? newHeight : minHeight);
        }
    };

    const handleMouseUpResize = () => {
        isResizing.current = false;
        document.removeEventListener('mousemove', handleMouseMoveResize);
        document.removeEventListener('mouseup', handleMouseUpResize);
    };

    return (
        <div className='relative w-full' style={{ height: `${height}px` }}>
            {props.children}
            <div
                className='absolute bottom-0 left-0 w-full h-3 cursor-ns-resize hover:border-b-4 border-cyan-800'
                onMouseDown={handleMouseDownResize}
            />
        </div>
    );
};
