import {
    BioLibSingleton,
    IApiToken,
    ISaml2SsoConnection,
    ISsoConnectionRequestData,
    ISsoConnectionResponse,
    Paginated
} from '@biolibtech/biolib-js';
import { put, takeLatest, fork, call, select, race, take, delay } from 'redux-saga/effects';
import { reFetchUser } from '../../sagas/userSaga';
import notificationActions from '../../state/notification/notificationActions';
import { handleError, prependMessageToError } from '../../utils';
import accountSettingsSlice from './accountSettingsSlice';
import { IRootState } from '../../state/rootReducer';
import { navigate } from 'gatsby';
import userConstants from '../../state/user/userConstants';
import config from '../../config';
import { IPaginationState } from '../../state';
import { readFileAsText } from './utils';

function* watchTeamAcceptInvitation() {
    yield takeLatest(accountSettingsSlice.actions.teamAcceptInvitation, function* (action) {
        if (accountSettingsSlice.actions.teamAcceptInvitation.match(action)) {
            try {
                let isSignedIn: boolean = yield select((state: IRootState) => state.user.isSignedIn);

                if (!isSignedIn) {
                    const { signInSuccess } = yield race({
                        signInSuccess: take(userConstants.SIGN_IN_SUCCESS),
                        timeout: delay(3000),
                    })
                    if (signInSuccess) {
                        isSignedIn = true;
                    }
                }

                if (!isSignedIn) {
                    yield put(notificationActions.addInfo('Sign in to accept the invitation'));
                    // TODO: Implement sign in action that takes a callback url with parameters that should be
                    //  returned to after sign in instead of using local storage
                    localStorage.setItem(config.localStorageKey.teamInvitation, JSON.stringify(action.payload));
                    yield navigate('/sign-in/');
                    return;
                }

                yield navigate('/');
                const biolib = BioLibSingleton.get();
                yield biolib.account.acceptInvitation(action.payload);
                yield put(accountSettingsSlice.actions.teamAcceptInvitationSuccess());
                yield call(reFetchUser);
                yield put(notificationActions.addSuccess('Successfully joined the team'));
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: accountSettingsSlice.actions.teamAcceptInvitationFailure,
                    friendlyErrorMessageToPrepend: 'Failed to accept team invitation',
                    showToast: true,
                })
            }
        }
    })
}

function* watchSignInSuccess() {
    yield takeLatest(userConstants.SIGN_IN_SUCCESS, function* () {
        try {
            const teamInvitationJsonString = localStorage.getItem(config.localStorageKey.teamInvitation);
            if (teamInvitationJsonString) {
                localStorage.removeItem(config.localStorageKey.teamInvitation);
                const teamInvitation = JSON.parse(teamInvitationJsonString);
                const biolib = BioLibSingleton.get();
                yield biolib.account.acceptInvitation(teamInvitation);
                yield put(accountSettingsSlice.actions.teamAcceptInvitationSuccess());
                yield call(reFetchUser);
                yield put(notificationActions.addSuccess('Successfully joined the team'));
            }
        } catch (error) {
            yield call(handleError, {
                error,
                actionToDispatch: accountSettingsSlice.actions.teamAcceptInvitationFailure,
                friendlyErrorMessageToPrepend: 'Failed to accept team invitation',
                showToast: true,
            })
        }
    })
}

function* watchTeamLeave() {
    yield takeLatest(accountSettingsSlice.actions.teamLeave, function* (action) {
        if (accountSettingsSlice.actions.teamLeave.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                yield biolib.account.leaveOrganization(action.payload.teamAccountHandle);
                yield put(accountSettingsSlice.actions.teamLeaveSuccess());
                yield call(reFetchUser);
                yield put(notificationActions.addSuccess('Successfully left the team'));
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: accountSettingsSlice.actions.teamLeaveFailure,
                    friendlyErrorMessageToPrepend: 'Unable to leave team',
                    showToast: true,
                })
            }
        }
    })
}

function* watchFetchRemoteHosts() {
    yield takeLatest(accountSettingsSlice.actions.fetchRemoteHosts, function* (action) {
        if (accountSettingsSlice.actions.fetchRemoteHosts.match(action)) {
            try {
                const { accountId, page } = action.payload;
                const biolib = BioLibSingleton.get();
                const params = {
                    page_size: 10,
                    page: page !== undefined ? page + 1 : 1,
                }
                const response = yield biolib.account.fetchRemoteHosts(accountId, params);
                const currentPage = params.page - 1;
                const remoteHostsPaginated = {
                    currentPage,
                    countPages: Math.ceil(response.count / params.page_size),
                    countPerPage: params.page_size,
                    countTotal: response.count,
                    pages: {
                        [currentPage]: response.results,
                    }
                };

                yield put(accountSettingsSlice.actions.fetchRemoteHostsSuccess({ accountId, remoteHostsPaginated }));
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: accountSettingsSlice.actions.fetchRemoteHostsFailure,
                    friendlyErrorMessageToPrepend: 'Failed to fetch custom remote hosts',
                    showToast: true,
                });
            }
        }
    })
}

function* watchAddRemoteHost() {
    yield takeLatest(accountSettingsSlice.actions.addRemoteHost, function* (action) {
        if (accountSettingsSlice.actions.addRemoteHost.match(action)) {
            try {
                const { accountId, remoteHost } = action.payload;
                const biolib = BioLibSingleton.get();
                yield biolib.account.addRemoteHost(accountId, remoteHost);
                yield put(accountSettingsSlice.actions.fetchRemoteHosts({ accountId }));
                yield put(accountSettingsSlice.actions.addRemoteHostSuccess());
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: accountSettingsSlice.actions.addRemoteHostFailure,
                    friendlyErrorMessageToPrepend: 'Failed to add remote host',
                    showToast: true,
                });
            }
        }
    })
}

function* watchDeleteRemoteHost() {
    yield takeLatest(accountSettingsSlice.actions.deleteRemoteHost, function* (action) {
        if (accountSettingsSlice.actions.deleteRemoteHost.match(action)) {
            try {
                const { accountId, remoteHostId } = action.payload;
                const biolib = BioLibSingleton.get();
                yield biolib.account.deleteRemoteHost(accountId, remoteHostId);
                yield put(accountSettingsSlice.actions.fetchRemoteHosts({ accountId }));
                yield put(accountSettingsSlice.actions.deleteRemoteHostSuccess());
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: accountSettingsSlice.actions.deleteRemoteHostFailure,
                    friendlyErrorMessageToPrepend: 'Failed to delete remote host',
                    showToast: true,
                });
            }
        }
    })
}

function* watchApiTokensFetch() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.apiTokensFetch, function* (action) {
        if (actions.apiTokensFetch.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                // @ts-ignore
                const pageIndex = action.payload.page ?? 0;
                const params = { page: pageIndex + 1, page_size: 10 };
                const response = yield biolib.apiToken.fetchAll(params);
                const apiTokensPaginated: IPaginationState<IApiToken> = {
                    currentPage: pageIndex,
                    countPages: Math.ceil(response.count / params.page_size),
                    countPerPage: params.page_size,
                    countTotal: response.count,
                    pages: {
                        [pageIndex]: response.results,
                    }
                }
                yield put(actions.apiTokensFetchSuccess({ apiTokensPaginated }));
            } catch (error) {
                yield put(actions.apiTokensFetchFailure());
                yield put(notificationActions.addError(prependMessageToError(error, 'Failed to load API token list')));
            }
        }
    })
}

function* watchApiTokenCreate() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.apiTokenCreate, function* (action) {
        if (actions.apiTokenCreate.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const apiToken = yield biolib.apiToken.createToken(action.payload);
                yield put(actions.apiTokenCreateSuccess({ apiToken }));
                yield put(actions.apiTokensFetch({}));
            } catch (error) {
                yield put(actions.apiTokenCreateFailure());
                yield put(notificationActions.addError(prependMessageToError(error, 'Failed create API token')));
            }
        }
    })
}

function* watchApiTokenDelete() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.apiTokenDelete, function* (action) {
        if (actions.apiTokenDelete.match(action)) {
            const { apiToken } = action.payload;
            try {
                const biolib = BioLibSingleton.get();
                yield biolib.apiToken.deleteToken(apiToken.public_id);
                yield put(actions.apiTokenDeleteSuccess());
                yield put(actions.apiTokensFetch({}));
            } catch (error) {
                yield put(actions.apiTokenDeleteFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    `Failed to delete API token: ${apiToken.name}`
                )));
            }
        }
    })
}

function* watchApiTokenUpdate() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.apiTokenUpdate, function* (action) {
        if (actions.apiTokenUpdate.match(action)) {
            const { tokenId, name } = action.payload;
            try {
                const biolib = BioLibSingleton.get();
                yield biolib.apiToken.updateToken(tokenId, { name });
                yield put(actions.apiTokenUpdateSuccess());
                const apiTokensPaginated: IPaginationState<IApiToken> =
                    yield select((state: IRootState) => state.accountSettings.apiTokensPaginated);
                yield put(actions.apiTokensFetch({ page: apiTokensPaginated.currentPage }));
            } catch (error) {
                yield put(actions.apiTokenUpdateFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    `Failed to save API token: ${name}`
                )));
            }
        }
    })
}

function* watchSsoConnectionCreate() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.ssoConnectionCreate, function* (action) {
        if (actions.ssoConnectionCreate.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const { accountId, saml2Connection } = action.payload;

                const ssoConnection: ISsoConnectionRequestData = {
                    saml2_sso_connection: {
                        attribute_mapping_yaml: saml2Connection.attribute_mapping_yaml,
                        identity_provider_entity_id: saml2Connection.identity_provider_entity_id,
                        identity_provider_metadata_xml: '',
                    }
                }

                if (saml2Connection.metadataXmlFileList instanceof FileList) {
                    const file = saml2Connection.metadataXmlFileList[0];
                    ssoConnection.saml2_sso_connection.identity_provider_metadata_xml = yield readFileAsText(file);
                }

                yield biolib.account.createSsoConnection(accountId, ssoConnection);
                yield put(actions.ssoConnectionCreateSuccess());
                yield put(actions.ssoConnectionsFetch({ accountId }));
            } catch (error) {
                yield put(actions.ssoConnectionCreateFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    'Failed to create SSO connection',
                )));
            }
        }
    })
}

function* watchSsoConnectionsFetch() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.ssoConnectionsFetch, function* (action) {
        if (actions.ssoConnectionsFetch.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const paginatedSsoConnections: Paginated<ISsoConnectionResponse> =
                    yield biolib.account.fetchSsoConnections(action.payload.accountId);

                yield put(actions.ssoConnectionsFetchSuccess({ ssoConnections: paginatedSsoConnections.results }));
            } catch (error) {
                yield put(actions.ssoConnectionsFetchFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    'Failed to fetch SSO connections',
                )));
            }
        }
    })
}

function* watchSsoConnectionUpdate() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.ssoConnectionUpdate, function* (action) {
        if (actions.ssoConnectionUpdate.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const { accountId, ssoConnectionId, saml2Connection } = action.payload;

                const saml2_sso_connection: Partial<ISaml2SsoConnection> = {
                    attribute_mapping_yaml: saml2Connection.attribute_mapping_yaml,
                    identity_provider_entity_id: saml2Connection.identity_provider_entity_id,
                }

                if (saml2Connection.metadataXmlFileList instanceof FileList) {
                    const file = saml2Connection.metadataXmlFileList[0];
                    saml2_sso_connection.identity_provider_metadata_xml = yield readFileAsText(file);
                }

                yield biolib.account.updateSsoConnection(accountId, ssoConnectionId, { saml2_sso_connection });
                yield put(actions.ssoConnectionUpdateSuccess());
                yield put(actions.ssoConnectionsFetch({ accountId }));
            } catch (error) {
                yield put(actions.ssoConnectionUpdateFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    'Failed to update SSO connection',
                )));
            }
        }
    })
}

function* watchSsoDomainCreate() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.ssoDomainCreate, function* (action) {
        if (actions.ssoDomainCreate.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const { accountId, ssoConnectionId, ssoDomain } = action.payload;
                yield biolib.account.createSsoDomain(accountId, ssoConnectionId, ssoDomain);
                yield put(actions.ssoDomainCreateSuccess());
                yield put(actions.ssoConnectionsFetch({ accountId }));
            } catch (error) {
                yield put(actions.ssoDomainCreateFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    'Failed to create SSO domain',
                )));
            }
        }
    })
}

function* watchSsoDomainDelete() {
    const { actions } = accountSettingsSlice;
    yield takeLatest(actions.ssoDomainDelete, function* (action) {
        if (actions.ssoDomainDelete.match(action)) {
            try {
                const biolib = BioLibSingleton.get();
                const { accountId, ssoConnectionId, ssoDomainId } = action.payload;
                yield biolib.account.deleteSsoDomain(accountId, ssoConnectionId, ssoDomainId);
                yield put(actions.ssoDomainDeleteSuccess());
                yield put(actions.ssoConnectionsFetch({ accountId }));
            } catch (error) {
                yield put(actions.ssoDomainDeleteFailure());
                yield put(notificationActions.addError(prependMessageToError(
                    error,
                    'Failed to delete SSO domain',
                )));
            }
        }
    })
}

export default function* accountSettingsSaga() {
    yield fork(watchAddRemoteHost);
    yield fork(watchApiTokenCreate);
    yield fork(watchApiTokenDelete);
    yield fork(watchApiTokensFetch);
    yield fork(watchApiTokenUpdate);
    yield fork(watchDeleteRemoteHost);
    yield fork(watchFetchRemoteHosts);
    yield fork(watchSignInSuccess);
    yield fork(watchSsoConnectionCreate);
    yield fork(watchSsoConnectionsFetch);
    yield fork(watchSsoConnectionUpdate);
    yield fork(watchSsoDomainCreate);
    yield fork(watchSsoDomainDelete);
    yield fork(watchTeamAcceptInvitation);
    yield fork(watchTeamLeave);
}
