import HttpClient from './HttpClient';
import Account from './resources/Account';
import App from './resources/App';
import Favorites from './resources/Favorites';
import Job from './resources/Job';
import License from './resources/License';
import DeprecatedTag from './resources/DeprecatedTag';
import User from './resources/User';
import Sso from './resources/Sso';
import ApiToken from './resources/ApiToken';
import Inquiries from './resources/Inquiries';

export type RefreshToken = string;
export type AccessToken = string;

export default class BioLib {
    public readonly httpClient: HttpClient;
    public readonly account: Account;
    public readonly apiToken: ApiToken;
    public readonly app: App;
    public readonly inquires: Inquiries;
    public readonly favorites: Favorites;
    public readonly job: Job;
    public readonly license: License;
    public readonly sso: Sso;
    public readonly tag: DeprecatedTag;
    public readonly user: User;

    constructor(baseURL: string, refreshToken?: string) {
        this.httpClient = new HttpClient({ baseURL, refreshToken });

        this.account = new Account(this.httpClient);
        this.apiToken = new ApiToken(this.httpClient);
        this.app = new App(this.httpClient);
        this.inquires = new Inquiries(this.httpClient);
        this.favorites = new Favorites(this.httpClient);
        this.job = new Job(this.httpClient);
        this.license = new License(this.httpClient);
        this.sso = new Sso(this.httpClient);
        this.tag = new DeprecatedTag(this.httpClient);
        this.user = new User(this.httpClient);
    }
}
