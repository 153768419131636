import React, { useEffect, useRef } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export enum PasteType {
    multiple = 'multiple',
    single = 'single',
}

interface IProps {
    isOpen: boolean;
    onClose: (value: PasteType | null) => void;
    columnName: string;
}

export default function PasteModal(props: IProps) {
    const primaryButtonRef = useRef<HTMLButtonElement>();
    useEffect(() => {
        setTimeout(() => {
            if (primaryButtonRef.current && props.isOpen) {
                primaryButtonRef.current.focus();
            }
        }, 100);
    }, [props.isOpen]);
    return (
        <Dialog open={props.isOpen} onClose={() => props.onClose(null)} className='relative z-10'>
            <DialogBackdrop
                transition
                className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
            />
            <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
                <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                    <DialogPanel
                        transition
                        className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'
                    >
                        <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                            <button
                                type='button'
                                onClick={() => props.onClose(null)}
                                tabIndex={3}
                                className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-800 focus:ring-offset-2'
                            >
                                <span className='sr-only'>Close</span>
                                <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                            </button>
                        </div>
                        <div>
                            <DialogTitle as='h3' className='text-base font-semibold text-gray-900'>
                                Pasting multiple {props.columnName}s?
                            </DialogTitle>
                            <p className='text-sm text-gray-500'>
                                Click "Multiple" to add separate rows for each line in the pasted text.
                            </p>
                        </div>
                        <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                            <button
                                className='inline-flex w-full justify-center rounded-md bg-cyan-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-800 sm:col-start-2'
                                onClick={() => props.onClose(PasteType.multiple)}
                                ref={primaryButtonRef}
                                type='button'
                                tabIndex={2}
                            >
                                Multiple
                            </button>
                            <button
                                className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:col-start-1 sm:mt-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-800'
                                onClick={() => props.onClose(PasteType.single)}
                                type='button'
                                tabIndex={1}
                            >
                                Single
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
