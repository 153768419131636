import * as queryString from 'query-string';
import { base64Encode, createRandomString } from './createRandomString';
import LocalStorageManager from './LocalStorageManager';

export const generateRandomStateString = (): string => encodeURIComponent(base64Encode(createRandomString()));

export function parseSsoCallbackUrlAndCheckState(url: string): { code: string; areTermsAccepted: boolean; } {
    const parsedUrl = queryString.parseUrl(url);
    const { code, state } = parsedUrl.query;

    const githubSsoState = LocalStorageManager.githubSsoState;
    LocalStorageManager.githubSsoState = null;
    const areTermsAccepted = githubSsoState ? githubSsoState.areTermsAccepted : false;

    // Ensure temporary localStorage keys are cleared before throwing any errors
    if (typeof code !== 'string' || typeof state !== 'string') {
        throw new Error('Could not parse callback parameters');
    }

    if (!githubSsoState) {
        // TODO: Make localStorage work in FireFox Incognito mode
        // throw new Error('No redirect state saved in local storage');
        console.error('No redirect state saved in local storage');
    } else {
        const savedStateDecoded = decodeURIComponent(githubSsoState.randomState);

        if (!savedStateDecoded || !state || state !== savedStateDecoded) {
            throw new Error('Invalid state in callback');
        }
    }

    return { code, areTermsAccepted };
}
