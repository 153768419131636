import * as React from "react";

interface ITableElementProps {
    children?: JSX.Element[] | JSX.Element | React.ReactNode;
    className?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
}

export const Row: React.FC<ITableElementProps> = (props) => (
    <div className={`row columns is-variable ${props.className || ''}`} onClick={props.onClick} style={props.style}>
        {props.children !== null ? props.children : ""}
    </div>
);

export const Col: React.FC<ITableElementProps> = (props) => (
    <div className={`column ${props.className || ''}`} onClick={props.onClick} style={props.style}>
        {props.children !== null ? props.children : ""}
    </div>
);
