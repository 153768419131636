import * as React from 'react';
import { Button, Dialog, Intent } from '@blueprintjs/core';

interface IProps {
    isOpen: boolean;
    handleConfirmQuit: () => void;
    handleAbortQuit: () => void;
    message: string;
    title: string;
}

export default class ConfirmClose extends React.Component<IProps> {
    public render() {
        return (
            <Dialog isOpen={this.props.isOpen} title={this.props.title} onClose={this.props.handleAbortQuit}>
                <div className='bp4-dialog-body'>
                    <TextAsParagraphs text={this.props.message}/>
                </div>
                <div className='bp4-dialog-footer'>
                    <div className='bp4-dialog-footer-actions'>
                        <Button
                            intent={Intent.NONE}
                            onClick={this.props.handleAbortQuit}
                            large={true}
                        >
                            Cancel
                        </Button>
                        <Button
                            intent={Intent.DANGER}
                            onClick={this.props.handleConfirmQuit}
                            large={true}
                        >
                            Confirm Quit
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

const TextAsParagraphs: React.FC<{ text: string }> = (props) => (
    <>{props.text.split('\n').map((item, i) => <p key={i}>{item}</p>)}</>
);
