export class BioLibExecutionError extends Error {
    public readonly date: Date;
    protected chainedErrors: BioLibExecutionError[] = [];

    constructor(error: string | Error) {
        super('');
        if (typeof error === "string") {
            this.message = error;
        } else if (error && error.message !== undefined) {
            this.message = error.message;
        }

        this.name = "BioLibError";
        this.date = new Date();
    }

    public static chainErrors(
        newError: string | Error,
        oldError: string | Error | BioLibExecutionError,
    ): BioLibExecutionError {
        const tmpError = new BioLibExecutionError(newError);

        // TODO: the below is temporary work around that serializes the chained errors to string
        // This is needed since passing an error between workers currently only pass .message

        /*
            let tmpErrorArray: BioLibError[] = [];
            if (oldError instanceof BioLibError) {
                tmpErrorArray = oldError.chainedErrors;
                oldError.chainedErrors = [];
                tmpErrorArray.push(oldError);
            } else {
                tmpErrorArray.push(new BioLibError(oldError));
            }
            tmpError.chainedErrors = tmpErrorArray;
        */

        let oldErrorMessage;
        if (oldError instanceof Error) {
            const stackStr = (oldError.stack ? oldError.stack.toString() : "");
            // for some errors, .stack include .message, so check if that's the case
            // to avoid duplication of information
            if (stackStr.includes(oldError.message)) {
                oldErrorMessage = oldError.stack;
            } else {
                oldErrorMessage = `${oldError.message}: ${oldError.stack}`;
            }
        } else {
            oldErrorMessage = oldError.toString();
        }

        tmpError.message = `${tmpError.message}\n\n${oldErrorMessage}`;

        if (typeof newError === 'string' && typeof oldError === 'object') {
            tmpError.name = oldError.name;
        }

        // end of work-around

        return tmpError;
    }

    public toString(): string {
        let errorString = `${this.message}\n`;
        for (let i = this.chainedErrors.length - 1; i >= 0; i -= 1) {
            errorString = `${errorString}\n${this.chainedErrors[i].message}\n`;
        }
        return errorString;
    }
}

export class AppClientError extends BioLibExecutionError {
    constructor(error: string | Error) {
        super(error);
        this.name = "AppClientError";
    }
}

