import enterpriseReplace from './enterpriseReplace.json';

// GATSBY_ is important prefix for the environment variable to become available in the compiled JavaScript
const isEnterprise = (process.env.GATSBY_BIOLIB_IS_ENTERPRISE ?? '').toUpperCase() === 'TRUE';
const frontendVersion = process.env.GATSBY_BIOLIB_FRONTEND_VERSION ?? 'development';

const buildInfo = `${isEnterprise ? 'Enterprise ' : ''}Build v${frontendVersion}`;
const isDev = frontendVersion === 'development';

let baseURL: string;
let domainForCookies: string;
let githubSsoClientId: string | null = null;
let isTeamSubdomain = false;

if (typeof location !== 'undefined') {
    baseURL = location.origin;
    domainForCookies = location.hostname;

    if (!isEnterprise || isDev) {
        if (isDev) {
            githubSsoClientId = 'ea0bf848d182728e85e9'; // Dev GitHub oauth app client id
            const hostnameParts = location.hostname.split('.');

            if (hostnameParts[hostnameParts.length - 1] === 'localhost' && hostnameParts.length > 1) {
                isTeamSubdomain = true;
            } else if (hostnameParts.length > 2) {
                isTeamSubdomain = true;
            }
        } else {
            const biolibComStagingHostname = 'biolib.io';
            const biolibComProductionHostname = 'biolib.com';

            if (location.hostname.endsWith(biolibComStagingHostname)) {
                domainForCookies = biolibComStagingHostname;
                githubSsoClientId = 'fb3b272bca90de557547'; // Staging GitHub oauth app client id
                if (location.hostname.split('.').length > 3) {
                    isTeamSubdomain = true;
                }
            } else if (location.hostname.endsWith(biolibComProductionHostname)) {
                domainForCookies = biolibComProductionHostname;
                githubSsoClientId = 'fe28c335660973b8ff74'; // Production GitHub oauth app client id
                if (location.hostname !== biolibComProductionHostname) {
                    isTeamSubdomain = true;
                }
            } else {
                throw new Error(`Unrecognized hostname "${location.hostname}"`);
            }
        }
    }
} else {
    // We are in Gatsby build phase and need to set variables though they will be updated at runtime
    baseURL = '';
    domainForCookies = '';
}

function isTeamSubdomainPage(locationFromComponentProps?: Location): boolean {
    const teamSubdomainPages = ['/client-routes/team-subdomain/', '/event/'];
    if (isEnterprise || (!locationFromComponentProps && typeof window === 'undefined')) {
        return false;
    }
    const location = locationFromComponentProps ? locationFromComponentProps : window.location;
    return isTeamSubdomain || (location && teamSubdomainPages.some((page) => location.pathname.startsWith(page)));
}

function isAuthenticatedPreBuiltPage(locationFromComponentProps?: Location): boolean {
    const authenticatedPages = ['/settings/', '/client-routes/authenticated/'];
    if (!locationFromComponentProps && typeof window === 'undefined') {
        return false;
    }
    const location = locationFromComponentProps ? locationFromComponentProps : window.location;
    return location && authenticatedPages.some((page) => location.pathname.startsWith(page));
}

enum localStorageKey {
    refreshToken = 'refreshToken',
    teamInvitation = 'teamInvitation',
}

const resourcePrefix = !isEnterprise ? '' : `@${enterpriseReplace.resourceHostnamePrefix}/`;

const allowedResourcePrefixes: string[] = [
    `%40${enterpriseReplace.resourceHostnamePrefix}`,
    `@${enterpriseReplace.resourceHostnamePrefix}`,
];

export default {
    allowedResourcePrefixes,
    baseURL,
    buildInfo,
    domainForCookies,
    frontendVersion,
    githubSsoClientId,
    isAuthenticatedPreBuiltPage,
    isDev,
    isEnterprise,
    isTeamSubdomain,
    isTeamSubdomainPage,
    localStorageKey,
    resourcePrefix,
    placeholderProfileImage: 'https://blbcdn.com/profile-pictures/default-profile-picture.png',
    biolibCommunitySlackInviteUrl:
        'https://join.slack.com/t/biolibcommunity/shared_invite/zt-k9m6xqbp-Gz84qaKL3Of8P7FRWQ3KIQ',
    githubSsoScope: 'user:email',
    itemsPerPage: 10,
    stackName: isEnterprise && enterpriseReplace.stackName ? enterpriseReplace.stackName : 'BioLib',
    sharingBaseUrl: isEnterprise && enterpriseReplace.sharingBaseUrl ? enterpriseReplace.sharingBaseUrl : baseURL,
    fileRendererHtmlPaths: {
        generic: process.env.GATSBY_BIOLIB_GENERIC_FILE_RENDERER_HTML_PATH,
        structure: process.env.GATSBY_BIOLIB_STRUCTURE_FILE_RENDERER_HTML_PATH,
    }
};
