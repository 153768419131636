import { ArgumentType, IFilesDict, ISetting, ISettingOption } from '@biolibtech/biolib-js';
import { ISettingValues, SettingValue } from '../components/tabs/InputTab/types';
import { getRandomId } from '@utils';

const BIOLIB_INPUT_DIR_PATH = '/';

export default async function prepareParametersAndFiles(
    settings: ISetting[],
    settingValues: ISettingValues,
    files: IFilesDict,
    argumentList: string[],
    parentSettingValue?: SettingValue,
): Promise<void> {
    // Assume settings are sorted at this point
    for (const setting of settings) {
        // Skip setting if it is a sub_argument and the parent value does not match condition
        if (setting.sub_argument_condition !== null && setting.sub_argument_condition !== parentSettingValue) {
            continue;
        }

        const settingValue = settingValues[setting.public_id];

        if (setting.render_type !== ArgumentType.hidden && !setting.required
            && !setting.default_value && (settingValue === null || settingValue === undefined)
        ) {
            continue;
        }

        if (!settingValue && !setting.default_value && setting.do_not_pass_if_value_empty) {
            continue;
        }

        let argumentValueString: string = setting.default_value;
        const filenames: string[] = [];

        if (setting.render_type === ArgumentType.sequence || setting.render_type === ArgumentType.sequenceBeta) {
            const fastaLines: string[] = [];
            const rowsSorted = Object.entries(settingValue).sort((a, b) => Number(a[0]) - Number(b[0]));
            for (const [rowIndex, cols] of rowsSorted) {
                const { 0: name, 1: sequence } = cols;
                if (sequence) {
                    const seqId = (name || `sequence_${Number(rowIndex) + 1}`).trim().replace('>', '');
                    fastaLines.push(`>${seqId}\n${sequence}`);
                }
            }
            if (fastaLines.length > 0) {
                fastaLines.push(''); // end file with new line
                const fastaString = fastaLines.join('\n');
                const defaultFilename: string = setting.default_value.split('/').pop() || '';
                const randomFilename = `input_${getRandomId(7)}${defaultFilename ? '_' : ''}${defaultFilename}`;
                argumentValueString = randomFilename;
                files[BIOLIB_INPUT_DIR_PATH.concat(randomFilename)] = { data: new TextEncoder().encode(fastaString) };
            } else {
                argumentValueString = setting.default_value;
            }
        } else if (setting.render_type === ArgumentType.group) {
            if (!Array.isArray(settingValue)) {
                throw new Error(`Failed to prepare argument ${setting.key} value was not an array`);
            }
            const groupArguments: string[] = [];
            for (const groupValues of settingValue as ISettingValues[]) {
                const argumentValues: string[] = [];
                for (const groupSetting of setting.group_arguments) {
                    const value = groupValues[groupSetting.public_id] || groupSetting.default_value;
                    if (typeof value === 'string') {
                        if (groupSetting.render_type === ArgumentType.textFile) {
                            const defaultFilename: string = setting.default_value.split('/').pop() || '';
                            const randomFilename =
                                `input_${getRandomId(7)}${defaultFilename ? '_' : ''}${defaultFilename}`;
                            const data = await getFileAsUint8Array(value);
                            files[BIOLIB_INPUT_DIR_PATH.concat(randomFilename)] = { data };
                            argumentValues.push(randomFilename);
                        } else {
                            argumentValues.push(value);
                        }
                    } else if (value instanceof FileList) {
                        const filenames: string[] = [];
                        for (const file of Object.values(value)) {
                            const data = await getFileAsUint8Array(file);
                            files[BIOLIB_INPUT_DIR_PATH.concat(file.name)] = { data };
                            filenames.push(file.name);
                        }
                        argumentValues.push(filenames.join(','));
                    } else if (Array.isArray(value)) {
                        for (const option of value as ISettingOption[]) {
                            argumentValues.push(option.value);
                        }
                    }
                }
                groupArguments.push(argumentValues.join(setting.group_argument_separator));
            }
            argumentValueString = groupArguments.join(setting.group_separator);
        } else if (setting.render_type === ArgumentType.multiselect) {
            if (!Array.isArray(settingValue)) {
                throw new Error(`Failed to prepare argument ${setting.key} value was not an array`);
            }
            const optionValues = (settingValue as ISettingOption[]).reduce(
                (previous: string[], option) => [...previous, option.value], []
            );
            argumentValueString = optionValues.join(',');

        } else if (
            setting.render_type !== ArgumentType.file &&
            setting.render_type !== ArgumentType.textFile &&
            setting.render_type !== ArgumentType.multifile &&
            setting.render_type !== ArgumentType.dragAndDropFile
        ) {
            if (typeof settingValue !== 'string' && typeof settingValue !== 'number') {
                throw new Error(`Failed to prepare argument ${setting.key} value was not a string or number`);
            }
            if (settingValue !== '') {
                argumentValueString = settingValue;
            }

            // Only add file argument if there is a file to add
        } else if (setting.default_file || settingValue !== '') {

            const defaultFilename: string = setting.default_value.split('/').pop() || '';
            const randomFilename =
                `input_${getRandomId(7)}${defaultFilename ? '_' : ''}${defaultFilename}`;

            argumentValueString = randomFilename;

            // Handle legacy default_files
            if (setting.default_file && settingValues[setting.public_id] === undefined) {
                const fetchResponse = await fetch(setting.default_file);
                const arrayBuffer = await fetchResponse.arrayBuffer();
                const data = new Uint8Array(arrayBuffer);
                files[BIOLIB_INPUT_DIR_PATH.concat(randomFilename)] = { data };

            } else if (typeof settingValue === 'string') {
                const data = await getFileAsUint8Array(settingValue);
                files[BIOLIB_INPUT_DIR_PATH.concat(randomFilename)] = { data };

            } else if (settingValue instanceof FileList) {
                argumentValueString = '';
                for (const file of Object.values(settingValue)) {
                    filenames.push(file.name);
                    const data = await getFileAsUint8Array(file);
                    files[BIOLIB_INPUT_DIR_PATH.concat(file.name)] = { data };
                }
            }
        }

        // Add the argument
        if (setting.key_value_separator.trim() !== '') {
            if (filenames.length > 0) {
                argumentValueString = filenames.join(',');
            }
            if (setting.exclude_value) {
                argumentValueString = '';
            }
            argumentList.push(`${setting.key}${setting.key_value_separator}${argumentValueString}`);
        } else {
            if (setting.key !== null && setting.key !== '') {
                argumentList.push(setting.key);
            }
            if (!setting.exclude_value) {
                if (filenames.length > 0) {
                    for (const filename of filenames) {
                        argumentList.push(filename);
                    }
                } else {
                    // TODO: For legacy reasons, we filter empty values away for the toggle type. Let's fix this
                    //       by allowing the user to set null values for argumentValueString (and also setting.key)
                    if (setting.render_type !== ArgumentType.toggle || argumentValueString !== '') {
                        argumentList.push(argumentValueString);
                    }
                }
            }
        }

        if (setting.sub_arguments.length > 0 && !Array.isArray(settingValue)) {
            await prepareParametersAndFiles(setting.sub_arguments, settingValues, files, argumentList, settingValue);
        }
    }
}

async function getFileAsUint8Array(data: File | string): Promise<Uint8Array> {
    return new Promise(async (resolve, reject) => {
        try {
            if (typeof data === 'string') {
                resolve(new Uint8Array(new TextEncoder().encode(data)));
            } else {
                const fileReader = new FileReader();
                fileReader.onerror = () => reject(new Error('Failed to read file'));
                fileReader.onload = () => resolve(new Uint8Array(fileReader.result as ArrayBuffer));
                fileReader.readAsArrayBuffer(data);
            }
        } catch (error) {
            reject(error);
        }
    });
}
