import accountSettingsSaga from '../features/accountSettings/accountSettingsSaga';
import appRunSaga from '../features/appRun/appRunSaga';
import accountsSaga from './accountsSaga';
import appPageSaga from '../features/appPage/appPageSaga';
import appsSaga from './appsSaga';
import contactSaga from './contactSaga';
import initializationSaga from './initializationSaga';
import userFlowSaga from '../features/userFlow/userFlowSaga';
import userSaga from './userSaga';
import { all } from 'redux-saga/effects';
import accountProfileSaga from '../features/accountProfile/accountProfileSaga';
import appCreateSaga from '../features/appCreate/saga';

export function* rootSaga() {
    yield all([
        accountProfileSaga(),
        accountSettingsSaga(),
        accountsSaga(),
        appCreateSaga(),
        appPageSaga(),
        appRunSaga(),
        appsSaga(),
        contactSaga(),
        initializationSaga(),
        userFlowSaga(),
        userSaga(),
    ]);
}
