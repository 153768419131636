import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

export interface IProps {
    className?: string;
    isBgDark?: boolean;
    isCallToAction?: boolean;
    isSmall?: boolean;
    path: string;
    text: string;
    useFullPath?: boolean;
    onClick?: () => void;
    onClickDisabled?: boolean;
}

export default function NavbarLink(props: IProps) {
    const className = clsx(
        props.className,
        'rounded-md text-base hover:no-underline hover:text-cyan-700',
        'hover:bg-gray-100 whitespace-nowrap',
        {
            'px-4 py-2': !props.isSmall,
            'px-2 py-1': props.isSmall,
            'text-gray-50 font-bold': props.isBgDark,
            'text-current font-medium': !props.isBgDark,
            'bg-white bg-opacity-10': props.isCallToAction && props.isBgDark,
            'border border-gray-600': props.isCallToAction && !props.isBgDark,
        },
    );

    if (!props.path) {
        return (
            <button onClick={props.onClick} className={className} disabled={props.onClickDisabled}>
                {props.text}
            </button>
        );
    }

    if (props.useFullPath) {
        return (
            <a href={`https://biolib.com${props.path}`} onClick={props.onClick} className={className}>{props.text}</a>
        );
    }

    return (<Link to={props.path} onClick={props.onClick} className={className}>{props.text}</Link>);
}
