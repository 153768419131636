import {
    AccountUuid,
    IAccount,
    IAccountAsAdmin, IAccountAsMember,
    IAccountUpdate,
    ISlimApp,
    OrganizationRole
} from "@biolibtech/biolib-js";
import { Action } from "redux";
import { ErrorAction, IPaginationState } from "../common.types";
import { AccountsConstants } from "./accountsConstants";

export interface IAccountMemberUpdate {
    org_account_handle: string;
    member_account_handle: string;
    role: OrganizationRole;
}

export interface IAccountInvitePayload {
    emailOrAccountHandleToInvite: string;
    role: OrganizationRole;
    teamAccountHandle: string;
}

export interface IAccountMemberDelete {
    org_account_handle: string;
    member_account_handle: string;
}

export interface AccountFetchAction extends Action {
    type: AccountsConstants.ACCOUNT_FETCH;
    payload: { account_handle: string }
}

export interface AccountFetchSuccessAction extends Action {
    type: AccountsConstants.ACCOUNT_FETCH_SUCCESS;
    payload: { account: IAccount }
}

export interface AccountFetchFailureAction extends ErrorAction {
    type: AccountsConstants.ACCOUNT_FETCH_FAILURE;
}

export interface ClearAccountLoadingState extends Action {
    type: AccountsConstants.CLEAR_ACCOUNT_LOADING_STATE;
}

export interface AppFeedFetchAction extends Action {
    type: AccountsConstants.APP_FEED_FETCH;
    payload: { accountHandles: string[] }
}

export interface AppFeedFetchSuccessAction extends Action {
    type: AccountsConstants.APP_FEED_FETCH_SUCCESS;
    payload: { accountHandles: string[]; appFeed: IPaginationState<ISlimApp> }
}

export interface AppFeedFetchFailureAction extends ErrorAction {
    type: AccountsConstants.APP_FEED_FETCH_FAILURE;
}

export interface AppFeedChangePageAction extends Action {
    type: AccountsConstants.APP_FEED_CHANGE_PAGE;
    payload: { newPageId: number }
}

export interface AppFeedChangePageSuccessAction extends Action {
    type: AccountsConstants.APP_FEED_CHANGE_PAGE_SUCCESS;
    payload: { newPageId: number; newPage?: ISlimApp[] }
}

export interface AppFeedChangePageFailureAction extends ErrorAction {
    type: AccountsConstants.APP_FEED_CHANGE_PAGE_FAILURE
}

export interface AccountFetchAppsAction extends Action {
    type: AccountsConstants.FETCH_APPS;
    payload: { account_handle: string; }
}

export interface AccountFetchAppsSuccessAction extends Action {
    type: AccountsConstants.FETCH_APPS_SUCCESS;
    payload: { accountId: AccountUuid; accountApps: IPaginationState<ISlimApp>; }
}

export interface AccountFetchAppsFailureAction extends ErrorAction {
    type: AccountsConstants.FETCH_APPS_FAILURE;
}

export interface AccountAppsChangePageAction extends Action {
    type: AccountsConstants.APPS_CHANGE_PAGE;
    payload: { accountId: AccountUuid; newPageId: number };
}

export interface AccountAppsChangePageSuccessAction extends Action {
    type: AccountsConstants.APPS_CHANGE_PAGE_SUCCESS;
    payload: {
        accountId: AccountUuid;
        newPageId: number;
        newPage?: ISlimApp[];
    }
}

export interface AccountAppsChangePageFailureAction extends ErrorAction {
    type: AccountsConstants.APPS_CHANGE_PAGE_FAILURE;
}

export interface AccountCreateAction extends Action {
    type: AccountsConstants.CREATE;
    payload: { account: IAccountUpdate }
}

export interface AccountCreateSuccessAction extends Action {
    type: AccountsConstants.CREATE_SUCCESS;
    payload: { account: IAccount };
}

export interface AccountCreateFailureAction extends ErrorAction {
    type: AccountsConstants.CREATE_FAILURE;
}

export interface AccountDeleteMemberAction extends Action {
    type: AccountsConstants.DELETE_MEMBER;
    payload: IAccountMemberDelete;
}

export interface AccountDeleteMemberSuccessAction extends Action {
    type: AccountsConstants.DELETE_MEMBER_SUCCESS;
}

export interface AccountDeleteMemberFailureAction extends ErrorAction {
    type: AccountsConstants.DELETE_MEMBER_FAILURE;
}

export interface AccountUpdateMemberRoleAction extends Action {
    type: AccountsConstants.UPDATE_MEMBER_ROLE;
    payload: IAccountMemberUpdate;
}

export interface AccountUpdateMemberRoleSuccessAction extends Action {
    type: AccountsConstants.UPDATE_MEMBER_ROLE_SUCCESS;
}

export interface AccountUpdateMemberRoleFailureAction extends ErrorAction {
    type: AccountsConstants.UPDATE_MEMBER_ROLE_FAILURE;
}

export interface AccountFetchMembersAction extends Action {
    type: AccountsConstants.FETCH_MEMBERS;
    payload: { org_account_handle: string };
}

export interface AccountFetchMembersSuccessAction extends Action {
    type: AccountsConstants.FETCH_MEMBERS_SUCCESS;
    payload: { org_account_handle: string; member_accounts: (IAccountAsMember | IAccountAsAdmin)[] };
}

export interface AccountFetchMembersFailureAction extends ErrorAction {
    type: AccountsConstants.FETCH_MEMBERS_FAILURE;
}

export interface AccountInviteAction extends Action {
    type: AccountsConstants.INVITE;
    payload: IAccountInvitePayload;
}

export interface AccountInviteSuccessAction extends Action {
    type: AccountsConstants.INVITE_SUCCESS;
}

export interface AccountInviteFailureAction extends ErrorAction {
    type: AccountsConstants.INVITE_FAILURE;
}

export interface ClearAccountInviteLoadingStateAction extends Action {
    type: AccountsConstants.INVITE_CLEAR_LOADING_STATE;
}

export type AccountsActions =
    | AccountFetchAction
    | AccountFetchSuccessAction
    | AccountFetchFailureAction
    | AppFeedFetchAction
    | AppFeedFetchSuccessAction
    | AppFeedFetchFailureAction
    | AppFeedChangePageAction
    | AppFeedChangePageSuccessAction
    | AppFeedChangePageFailureAction
    | ClearAccountLoadingState
    | AccountFetchAppsAction
    | AccountFetchAppsSuccessAction
    | AccountFetchAppsFailureAction
    | AccountAppsChangePageAction
    | AccountAppsChangePageSuccessAction
    | AccountAppsChangePageFailureAction
    | AccountCreateAction
    | AccountCreateSuccessAction
    | AccountCreateFailureAction
    | AccountFetchMembersAction
    | AccountFetchMembersSuccessAction
    | AccountFetchMembersFailureAction
    | AccountUpdateMemberRoleAction
    | AccountUpdateMemberRoleSuccessAction
    | AccountUpdateMemberRoleFailureAction
    | AccountDeleteMemberAction
    | AccountDeleteMemberSuccessAction
    | AccountDeleteMemberFailureAction
    | AccountInviteAction
    | AccountInviteSuccessAction
    | AccountInviteFailureAction
    | ClearAccountInviteLoadingStateAction

export const accountsActions = {
    accountFetch: (account_handle: string): AccountFetchAction => ({
        type: AccountsConstants.ACCOUNT_FETCH,
        payload: { account_handle }
    }),

    accountFetchSuccess: (account: IAccount): AccountFetchSuccessAction => ({
        type: AccountsConstants.ACCOUNT_FETCH_SUCCESS,
        payload: { account }
    }),

    accountFetchFailure: (errorMessage: string): AccountFetchFailureAction => ({
        type: AccountsConstants.ACCOUNT_FETCH_FAILURE,
        errorMessage,
    }),

    clearAccountLoadingState: (): ClearAccountLoadingState => ({
        type: AccountsConstants.CLEAR_ACCOUNT_LOADING_STATE,
    }),

    appFeedFetch: (accountHandles: string[]): AppFeedFetchAction => ({
        type: AccountsConstants.APP_FEED_FETCH,
        payload: { accountHandles }
    }),

    appFeedFetchSuccess: (
        accountHandles: string[],
        appFeed: IPaginationState<ISlimApp>
    ): AppFeedFetchSuccessAction => ({
        type: AccountsConstants.APP_FEED_FETCH_SUCCESS,
        payload: { accountHandles, appFeed }
    }),

    appFeedFetchFailure: (errorMessage: string): AppFeedFetchFailureAction => ({
        type: AccountsConstants.APP_FEED_FETCH_FAILURE,
        errorMessage,
    }),

    appFeedChangePage: (newPageId: number): AppFeedChangePageAction => ({
        type: AccountsConstants.APP_FEED_CHANGE_PAGE,
        payload: { newPageId }
    }),

    appFeedChangePageSuccess: (newPageId: number, newPage?: ISlimApp[]): AppFeedChangePageSuccessAction => ({
        type: AccountsConstants.APP_FEED_CHANGE_PAGE_SUCCESS,
        payload: { newPageId, newPage }
    }),

    appFeedChangePageFailure: (errorMessage: string): AppFeedChangePageFailureAction => ({
        type: AccountsConstants.APP_FEED_CHANGE_PAGE_FAILURE,
        errorMessage
    }),

    fetchApps: (account_handle: string): AccountFetchAppsAction => ({
        type: AccountsConstants.FETCH_APPS,
        payload: { account_handle }
    }),

    fetchAppsSuccess: (
        accountId: AccountUuid,
        accountApps: IPaginationState<ISlimApp>
    ): AccountFetchAppsSuccessAction => ({
        type: AccountsConstants.FETCH_APPS_SUCCESS,
        payload: { accountId, accountApps }
    }),

    fetchAppsFailure: (errorMessage: string): AccountFetchAppsFailureAction => ({
        type: AccountsConstants.FETCH_APPS_FAILURE,
        errorMessage,
    }),

    appsChangePage: (accountId: AccountUuid, newPageId: number): AccountAppsChangePageAction => ({
        type: AccountsConstants.APPS_CHANGE_PAGE,
        payload: { accountId, newPageId },
    }),

    appsChangePageSuccess: (
        accountId: AccountUuid,
        newPageId: number,
        newPage?: ISlimApp[]
    ): AccountAppsChangePageSuccessAction => ({
        type: AccountsConstants.APPS_CHANGE_PAGE_SUCCESS,
        payload: { accountId, newPageId, newPage }
    }),

    appsChangePageFailure: (errorMessage: string): AccountAppsChangePageFailureAction => ({
        type: AccountsConstants.APPS_CHANGE_PAGE_FAILURE,
        errorMessage,
    }),

    create: (account: IAccountUpdate): AccountCreateAction => ({
        type: AccountsConstants.CREATE,
        payload: { account }
    }),

    createSuccess: (account: IAccount): AccountCreateSuccessAction => ({
        type: AccountsConstants.CREATE_SUCCESS,
        payload: { account }
    }),

    createFailure: (errorMessage: string): AccountCreateFailureAction => ({
        type: AccountsConstants.CREATE_FAILURE,
        errorMessage,
    }),

    fetchMembers: (org_account_handle: string): AccountFetchMembersAction => ({
        type: AccountsConstants.FETCH_MEMBERS,
        payload: { org_account_handle },
    }),

    fetchMembersSuccess: (
        org_account_handle: string,
        member_accounts: (IAccountAsMember | IAccountAsAdmin)[]
    ): AccountFetchMembersSuccessAction => ({
        type: AccountsConstants.FETCH_MEMBERS_SUCCESS,
        payload: { org_account_handle, member_accounts }
    }),

    fetchMembersFailure: (errorMessage: string): AccountFetchMembersFailureAction => ({
        type: AccountsConstants.FETCH_MEMBERS_FAILURE,
        errorMessage,
    }),

    deleteMember: (payload: IAccountMemberDelete): AccountDeleteMemberAction => ({
        type: AccountsConstants.DELETE_MEMBER,
        payload,
    }),

    deleteMemberSuccess: (): AccountDeleteMemberSuccessAction => ({
        type: AccountsConstants.DELETE_MEMBER_SUCCESS,
    }),

    deleteMemberFailure: (errorMessage: string): AccountDeleteMemberFailureAction => ({
        type: AccountsConstants.DELETE_MEMBER_FAILURE,
        errorMessage
    }),

    updateMemberRole: (payload: IAccountMemberUpdate): AccountUpdateMemberRoleAction => ({
        type: AccountsConstants.UPDATE_MEMBER_ROLE,
        payload,
    }),

    updateMemberRoleSuccess: (): AccountUpdateMemberRoleSuccessAction => ({
        type: AccountsConstants.UPDATE_MEMBER_ROLE_SUCCESS,
    }),

    updateMemberRoleFailure: (errorMessage: string): AccountUpdateMemberRoleFailureAction => ({
        type: AccountsConstants.UPDATE_MEMBER_ROLE_FAILURE,
        errorMessage
    }),

    invite: (payload: IAccountInvitePayload): AccountInviteAction => ({
        type: AccountsConstants.INVITE,
        payload,
    }),

    inviteSuccess: (): AccountInviteSuccessAction => ({
        type: AccountsConstants.INVITE_SUCCESS,
    }),

    inviteFailure: (errorMessage: string): AccountInviteFailureAction => ({
        type: AccountsConstants.INVITE_FAILURE,
        errorMessage,
    }),

    clearInviteLoadingState: (): ClearAccountInviteLoadingStateAction => ({
        type: AccountsConstants.INVITE_CLEAR_LOADING_STATE,
    }),
};
