import { isoTimeStampToHumanReadable } from './renderResults';
import { ILogMessage } from '../types';

export function stringifyLogMessages(logMessages: ILogMessage[]): string {
    return logMessages.map(({ timestamp, message }) =>
        `[${isoTimeStampToHumanReadable(timestamp)}] ${message}`
    ).join('\n');
}

export function processStreamedOutput(streamedOutput: string): string {
    const outputHistory: string[] = [];
    for (const line of streamedOutput.split('\n')) {
        const characters: string[] = [];
        let position: number = 0;
        for (let character of Array.from(line)) {
            if (character === '\r') {
                position = 0;
            } else {
                if (position < characters.length) {
                    characters[position] = character;
                } else {
                    characters.push(character);
                }
                position += 1;
            }
        }
        outputHistory.push(characters.join(''));
    }
    return outputHistory.join('\n');
}
