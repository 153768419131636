import * as React from 'react';
import config from '../../../../../config';
import { FaSlack } from 'react-icons/all';
import { Icon } from '@blueprintjs/core';

const HelpButtons: React.FC = () => {
    const buttonClasses = `inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm font-medium 
sm:text-sm rounded-md text-gray-700 bg-white hover:bg-cyan-700 hover:no-underline hover:text-gray-50`;
    return (
        <div className='my-2 p-1'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>Let us help you fix the error</h3>
            <div className='mt-2 max-w-md text-sm text-gray-500'>
                <p>
                    If you have any questions, please reach out to us below.
                </p>
            </div>
            <div className='mt-3 md:space-x-2 space-y-2 md:space-y-0'>
                {config.isEnterprise ? null :
                    <a
                        className={buttonClasses}
                        href={config.biolibCommunitySlackInviteUrl}
                        rel='noreferrer'
                        target='_blank'
                    >
                        <FaSlack className='text-lg mr-2'/> Write us on Slack
                    </a>
                }
                <a className={buttonClasses} href='/contact/' target='_blank'>
                    <Icon icon='chat' className='mr-2'/> Send us a message
                </a>
            </div>
        </div>
    )
}

export default HelpButtons;
