import * as React from 'react';
import { Card, Intent, ProgressBar } from '@blueprintjs/core';
import { IJobWrapper, JobStatus } from '../features/appRun/types';

interface IProps {
    jobWrapper: IJobWrapper;

    onClick: (jobId: string) => void;
}

const MinimizedAppCard: React.FC<IProps> = ({ jobWrapper, onClick }) => (
    <Card
        className='has-background-white-bis'
        style={{ marginBottom: '20px' }}
        onClick={() => onClick(jobWrapper.job.public_id)}
        interactive={true}
        elevation={1}
    >
        <p>
            <b>{jobWrapper.app.name}</b>
            <span style={{ marginLeft: 5 }}>Job ID: {jobWrapper.job.public_id.slice(0, 4)}...</span>
        </p>
        <p>{jobStatusToText(jobWrapper.status)}</p>
        <ProgressBar
            intent={jobStatusToIntent(jobWrapper.status)}
            animate={jobWrapper.status === JobStatus.computing}
            value={jobWrapper.progressCompute === 0 ? 1 : jobWrapper.progressCompute}
            stripes={jobWrapper.status !== JobStatus.finished}
        />
    </Card>
);

function jobStatusToIntent(status: JobStatus): Intent {
    switch (status) {
        case JobStatus.acceptTerms:
        case JobStatus.input:
            return Intent.NONE;
        case JobStatus.computing:
            return Intent.PRIMARY;
        case JobStatus.error:
            return Intent.DANGER;
        case JobStatus.finished:
            return Intent.SUCCESS;
    }
}

function jobStatusToText(status: JobStatus): string {
    switch (status) {
        case JobStatus.acceptTerms:
        case JobStatus.input:
            return 'Click to finish setup';
        case JobStatus.computing:
            return 'Computing';
        case JobStatus.finished:
            return 'Results are ready';
        case JobStatus.error:
            return 'Error occurred';
    }
}

export default MinimizedAppCard;
