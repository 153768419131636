import * as React from 'react';
import { HTMLTable } from "@blueprintjs/core";

const TableRenderer: React.FC<{ children: any; }> = (props) => (
    <HTMLTable bordered={true} striped={true} condensed={true}>
        {props.children}
    </HTMLTable>
);

export default TableRenderer;
