import React, { useEffect, useState } from 'react';

interface IProps {
    text: string;
}

export default function InputDescription(props: IProps) {
    const { text } = props;
    const [parsedParts, setParsedParts] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        const linkRegex = /\[(?<label>[^\]]+)]\((?<url>https?:\/\/\S+)\)/g;
        const parts: React.ReactNode[] = [];
        let lastIndex = 0;
        let match;

        while ((match = linkRegex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push(text.slice(lastIndex, match.index));
            }

            parts.push(
                <a key={match.index} href={match.groups?.url} target='_blank' rel='noopener nofollow noreferrer'>
                    {match.groups?.label}
                </a>
            );

            lastIndex = linkRegex.lastIndex;
        }

        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        setParsedParts(parts);
    }, [text]);

    return <span className='font-bold text-base'>{parsedParts}</span>;
}
