export enum ModuleEnvironment {
    biolibApp = 'biolib-app',
    biolibCustom = 'biolib-custom',
    biolibEcr = 'biolib-ecr',
    app_data = 'app_data',
}

export interface IFilesMapping {
    from_path: string;
    to_path: string;
}

export interface IModule {
    name: string;
    command: string;
    environment: ModuleEnvironment;
    image_uri: string;
    working_directory: string;
    input_files_mappings: IFilesMapping[];
    output_files_mappings: IFilesMapping[];
    source_files_mappings: IFilesMapping[];
}
