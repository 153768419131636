import { FormikErrors } from 'formik';

export class ApiUtils {

    public static parseApiResponseAndGetErrors(
        response: { error?: { status: number | string; data?: any; }; } | Record<string, any>,
        fieldKeys: string[],
    ): { fieldErrors: FormikErrors<Record<string, string>> | null; nonFieldErrorMessage: string | null; } {
        const { error } = response;
        if (error === undefined) {
            return { fieldErrors: null, nonFieldErrorMessage: null };
        }

        if (error.status === 400) {
            const validationErrors: Record<string, string | string[]> = error.data;
            const fieldErrors: FormikErrors<Record<string, string>> = {};
            for (const key of fieldKeys) {
                const value = validationErrors[key];
                if (value) {
                    fieldErrors[key] = Array.isArray(value) ? value.join(' ') : value;
                }
            }

            const nonFieldErrorMessage = Array.isArray(error.data?.non_field_errors) ?
                error.data.non_field_errors.join(' ') : null;

            return { fieldErrors, nonFieldErrorMessage };
        } else {
            try {
                return { fieldErrors: null, nonFieldErrorMessage: JSON.stringify(error.data) };
            } catch (error) {
                return { fieldErrors: null, nonFieldErrorMessage: 'Unknown error occurred when creating secret' };
            }
        }
    }
}
