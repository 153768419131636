import React from 'react';
import ReactMarkdown from 'react-markdown';
import TableRenderer from './TableRenderer';
import { Callout } from '@blueprintjs/core';
import remarkGfm from 'remark-gfm';
import clsx from 'clsx';
import { CodeProps } from 'react-markdown/lib/ast-to-react';

interface IMarkdownProps {
    linksTargetSelf?: boolean;
    string: string;
    className?: string;
}

interface IState {
    renderErrorMessage: string | null;
}

export default class RenderMarkdown extends React.Component<IMarkdownProps, IState> {
    public state = {
        renderErrorMessage: null,
    };

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ renderErrorMessage: error.message });
    }

    public render() {
        const { renderErrorMessage } = this.state;
        if (renderErrorMessage !== null) {
            return (<Callout intent='danger' title='Failed to render Markdown'>{renderErrorMessage}</Callout>);
        }

        return (
            <ReactMarkdown
                className={clsx('prose max-w-none print:prose-sm w-full', this.props.className)}
                children={this.props.string}
                disallowedElements={['linkReference', 'imageReference ']}
                remarkPlugins={[remarkGfm]}
                components={{
                    img: ImageRender,
                    a: LinkRender(!!this.props.linksTargetSelf),
                    table: TableRenderer,
                    code: CodeRenderer,
                }}
            />
        );
    }
}

const ImageRender: React.FC<{ src?: string; alt?: string; }> = (props) => {
    const src = props.src ?? '';
    const [objectUrlFileHash, base64Filename] = src.split(';');
    if (objectUrlFileHash && base64Filename) {
        return (<img src={`blob:${location.origin}/${objectUrlFileHash}`} alt={props.alt} crossOrigin='anonymous'/>);
    }
    return (<img src={props.src} alt={props.alt} crossOrigin='anonymous'/>);
};

const LinkRender = (linksTargetSelf: boolean): React.FC<{ href?: string; children?: any }> => (props) => {
    const href = props.href ?? '';
    const [objectUrlFileHash, base64Filename] = href.split(';');
    if (objectUrlFileHash && base64Filename) {
        const filename = atob(base64Filename);
        return (<a href={`blob:${location.origin}/${objectUrlFileHash}`} download={filename}>{props.children}</a>);
    }
    return (
        <a href={props.href} rel='nofollow noreferrer' target={linksTargetSelf ? '_self' : '_blank'}>
            {props.children}
        </a>
    );
};

function CodeRenderer(props: CodeProps) {
    return (<code className={clsx({ 'whitespace-pre-wrap': props.inline })}>{props.children}</code>);
}
