/*
    Safari does not support .getBigUint64() and .setBigUint64() on a DataView.
    As we need Uint64 for serializing byte length of files in the BioLib Binary Format
    we polyfill these methods if the are not available
*/

export default class CustomDataView extends DataView {

    public getBigUint64(byteOffset: number, littleEndian?: boolean): bigint {
        if (typeof super.getBigUint64 === 'function') {
            return super.getBigUint64(byteOffset, littleEndian);
        }

        const bigThirtyTwo = BigInt(32);
        // split 64-bit number into two 32-bit (4-byte) parts
        const left = BigInt(this.getUint32(byteOffset | 0, !!littleEndian) >>> 0);
        const right = BigInt(this.getUint32((byteOffset | 0) + 4 | 0, !!littleEndian) >>> 0);

        // combine the two 32-bit values and return
        if (littleEndian) {
            return (right << bigThirtyTwo) | left;
        }
        return (left << bigThirtyTwo) | right;
    }

    public setBigUint64(byteOffset: number, value: bigint, littleEndian?: boolean): void {
        if (typeof super.setBigUint64 === 'function') {
            return super.setBigUint64(byteOffset, value, littleEndian);
        }

        const byteArray = this.bigIntTo64BitByteArray(value);
        for (let i = 0; i < byteArray.length; i += 1) {
            const byteToSet = littleEndian ? byteArray[byteArray.length - 1 - i] : byteArray[i];
            this.setUint8(byteOffset + i, byteToSet);
        }
    }

    private bigIntTo64BitByteArray(value: bigint): Uint8Array {
        let hex = BigInt(value).toString(16);
        if (hex.length % 2 !== 0) {
            hex = '0'.concat(hex);
        }

        while (hex.length < 16) {
            hex = '00'.concat(hex);
        }

        const byteLength = hex.length / 2;
        if (byteLength !== 8) {
            throw new Error('BigInt does not fit in 64 bits');
        }

        const byteArray = new Uint8Array(byteLength);

        for (let i = 0, j = 0; i < byteLength; i += 1, j += 2) {
            byteArray[i] = parseInt(hex.slice(j, j + 2), 16);
        }
        return byteArray;
    }
}
