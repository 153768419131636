import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILoadingState, LoadingState } from '../../utils';
import { ISignInState } from './utils/LocalStorageManager';

export interface UserFlowState {
    enterpriseDeploymentSignInIsLoading: boolean;
    enterpriseSignInLoadingState: ILoadingState;
    gitHubSignInLoadingState: ILoadingState;
    isSignInPasswordFieldShown: boolean;
    isStateInitialized: boolean;
    signInState: ISignInState | null;
}

const initialState: UserFlowState = {
    enterpriseDeploymentSignInIsLoading: false,
    enterpriseSignInLoadingState: LoadingState.default,
    gitHubSignInLoadingState: LoadingState.default,
    isSignInPasswordFieldShown: false,
    isStateInitialized: false,
    signInState: null,
};

const userFlowSlice = createSlice({
    name: 'userFlow',
    initialState,
    reducers: {
        initialized(state) {
            state.isStateInitialized = true;
        },
        setSignInState(state, action: PayloadAction<{ signInState: ISignInState | null }>) {
            state.signInState = action.payload.signInState;
        },
        requireSignIn(state) {
            // no-op; Action is handled in saga
        },
        gitHubSignIn(state, action: PayloadAction<{ areTermsAcceptedForSignUp?: boolean; }>) {
            state.gitHubSignInLoadingState = LoadingState.begin;
        },
        gitHubHandleCallback(state, action: PayloadAction<{ url: string; }>) {
            // no-op; Action is handled in saga
        },
        gitHubSignInSuccess(state) {
            state.gitHubSignInLoadingState = LoadingState.success;
        },
        gitHubSignInFailure(state, action: PayloadAction<string>) {
            state.gitHubSignInLoadingState = LoadingState.failure(action.payload);
        },
        enterpriseSignIn(state, action: PayloadAction<{ email: string; }>) {
            state.enterpriseSignInLoadingState = LoadingState.begin;
        },
        enterpriseSignInSuccess(state) {
            state.enterpriseSignInLoadingState = LoadingState.success;
        },
        enterpriseSignInFailure(state, action: PayloadAction<string>) {
            state.enterpriseSignInLoadingState = LoadingState.failure(action.payload);
            state.isSignInPasswordFieldShown = true;
            state.enterpriseDeploymentSignInIsLoading = false;
        },
        enterpriseDeploymentSignIn(state, action: PayloadAction<{ ssoConnectionUuid: string }>) {
            state.enterpriseDeploymentSignInIsLoading = true;
        },
    }
});

export default userFlowSlice;
