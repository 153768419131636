import React from 'react';
import FooterContent from './FooterContent';

export default function Footer() {
    return (
        <footer className='container my-4 pb-3'>
            <FooterContent />
        </footer>
    );
}
