import * as React from 'react';
import SignInContainer from '../../../userFlow/containers/SignInContainer';
import SignUpContainer from '../../../userFlow/containers/SignUpContainer';
import { Button, Intent } from '@blueprintjs/core';
import { Col, Row } from '../../../../components/common';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { UserAgreementField } from '../../../../components/SignUp';

interface IProps {
    defaultViewIsSignIn: boolean;
    guestModeDescription: React.ReactNode;
    requireExplicitContentForGuestMode: boolean;

    onAcceptTerms: () => void;
    onQuitApp: () => void;
}

interface IState {
    isSignInShown: boolean;
}

const initialValuesGuestForm = { userAgreement: false };

export default class TermsTab extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { isSignInShown: props.defaultViewIsSignIn }
    }

    public render() {
        const { requireExplicitContentForGuestMode, guestModeDescription } = this.props;
        const { isSignInShown } = this.state;

        const columnGapStyle = { '--columnGap': 0, paddingLeft: '5px', paddingRight: '5px' };
        return (
            <div className='content' style={{ overflow: 'scroll', maxHeight: '90 vh' }}>
                <div className='body'>
                    <Row style={columnGapStyle}>
                        <Col>
                            <h3>Continue as Guest</h3>
                            <p>{guestModeDescription}</p>
                            <Formik
                                initialValues={initialValuesGuestForm}
                                validate={this.validate}
                                onSubmit={this.props.onAcceptTerms}
                            >
                                {({ errors, touched }) =>
                                    <Form noValidate={true} method={'POST'} autoComplete={'off'}>
                                        {!requireExplicitContentForGuestMode ? null :
                                            <Field name='userAgreement' component={UserAgreementField}/>
                                        }
                                        <Button
                                            disabled={requireExplicitContentForGuestMode &&
                                            touched.userAgreement && errors.userAgreement !== undefined}
                                            fill={true}
                                            intent={Intent.PRIMARY}
                                            large={true}
                                            type='submit'
                                        >
                                            <b>Continue as guest</b>
                                        </Button>
                                    </Form>
                                }
                            </Formik>
                        </Col>
                        <Col className='is-1 is-centered'>
                            <div
                                style={{
                                    borderLeft: '1px solid lightgrey',
                                    height: '100%',
                                    marginLeft: '50%',
                                    marginRight: '50%',
                                    width: '1 px',
                                }}
                            />
                        </Col>
                        <Col>
                            {isSignInShown ?
                                <SignInContainer
                                    onClickSignUp={this.toggleSignInTab}
                                    onNavigateAway={this.props.onQuitApp}
                                /> :
                                <SignUpContainer
                                    onNavigateAway={this.props.onQuitApp}
                                    onClickSignIn={this.toggleSignInTab}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    private validate = (values: typeof initialValuesGuestForm): FormikErrors<typeof initialValuesGuestForm> => {
        const errors: FormikErrors<typeof initialValuesGuestForm> = {};
        if (this.props.requireExplicitContentForGuestMode && !values.userAgreement) {
            errors.userAgreement = 'Check the box to continue';
        }
        return errors;
    }

    private toggleSignInTab = () => this.setState({ isSignInShown: !this.state.isSignInShown });
}
