import CustomDataView from './CustomDataView';

export default abstract class BioLibBinaryFormatBase {
    protected readonly VERSION = 1;
    protected abstract readonly TYPE: number;

    public abstract serialize(): Uint8Array;

    protected assertVersionAndType(dataView: CustomDataView): void {
        const version = dataView.getUint8(0);
        const type = dataView.getUint8(1);

        if (version !== this.VERSION) {
            throw Error(`Unsupported BioLib Binary Format version: Got ${version} expected ${this.VERSION}`);
        }
        if (type !== this.TYPE) {
            throw Error(`Unsupported BioLib Binary Format type: Got ${type} expected ${this.TYPE}`);
        }
    }
}
