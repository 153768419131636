import { Action } from "redux";
import notificationConstants from "./notificationConstants";
import { Intent } from "@blueprintjs/core";
import { IActionProps, ILinkProps } from "@blueprintjs/core/src/common/props";

export enum NotificationTypes {
    USER = "USER",
    APPS = "APPS",
    APP_CREATION = "APP_CREATION",
    UNDEFINED = "UNDEFINED",
}

export type IButtonAction = IActionProps & ILinkProps;

export interface INotification {
    timestamp: string;
    intent: Intent;
    type: NotificationTypes;
    message: string;
    action?: IButtonAction;
    timeout?: number; // Value in milliseconds. Less or equal to 0 will disable the timeout.
}

export interface AddAction extends Action {
    type: notificationConstants.ADD;
    payload: { notification: INotification };
}

export interface RemoveAction extends Action {
    type: notificationConstants.REMOVE;
    payload: { notificationIndex: number };
}

export interface ClearToastsAction extends Action {
    type: notificationConstants.CLEAR_TOASTS;
}

export type notificationActionTypes =
    | AddAction
    | RemoveAction
    | ClearToastsAction;
