import { IAccount, IAccountUpdate, IUserPost } from "@biolibtech/biolib-js";
import {
    AuthenticationRequiredAction,
    ChangeUserEmailAction,
    ChangeUserEmailClearLoadingStateAction,
    ChangeUserEmailFailureAction,
    ChangeUserEmailSuccessAction,
    ClearCreateUserErrorsAction,
    ClearSignInErrorsAction,
    ClearUserStateAction,
    CreateUserAction,
    CreateUserFailedAction,
    CreateUserSuccessAction,
    IUserWithAccountsDict,
    RequestVerificationEmail,
    ResetPasswordAction,
    ResetPasswordClearStateAction,
    ResetPasswordConfirmAction,
    ResetPasswordConfirmClearStateAction,
    ResetPasswordConfirmFailedAction,
    ResetPasswordConfirmSuccessAction,
    ResetPasswordFailedAction,
    ResetPasswordSuccessAction, 
    SetUserWithAccountsDictAction,
    SignInAction,
    SignInFailedAction,
    SignInSuccessAction,
    UpdateAccountAction,
    UpdateAccountClearLoadingStateAction,
    UpdateAccountFailedAction,
    UpdateAccountSuccessAction,
    VerifyEmailAction,
} from "./userActions.types";
import userConstants from "./userConstants";

export const userActions = {
    signIn: (email: string, password: string): SignInAction => ({
        type: userConstants.SIGN_IN,
        payload: { email, password }
    }),

    signInSuccess: (payload: IUserWithAccountsDict): SignInSuccessAction => ({
        type: userConstants.SIGN_IN_SUCCESS,
        payload,
    }),

    signInFailed: (errorMessage: string): SignInFailedAction => ({
        type: userConstants.SIGN_IN_FAILED,
        errorMessage
    }),

    setUserWithAccountsDict: (payload: IUserWithAccountsDict): SetUserWithAccountsDictAction => ({
        type: userConstants.SET_USER,
        payload,
    }),

    clearSignInErrors: (): ClearSignInErrorsAction => ({
        type: userConstants.CLEAR_SIGN_IN_ERRORS,
    }),

    clearUserState: (): ClearUserStateAction => ({
        type: userConstants.CLEAR_USER_STATE
    }),

    authenticationRequired: (message: string): AuthenticationRequiredAction => ({
        type: userConstants.AUTHENTICATION_REQUIRED,
        payload: { message }
    }),

    createUser: (payload: IUserPost): CreateUserAction => ({
        type: userConstants.CREATE_USER,
        payload,
    }),

    createAccountSuccess: (payload: IUserWithAccountsDict): CreateUserSuccessAction => ({
        type: userConstants.CREATE_USER_SUCCESS,
        payload,
    }),

    createAccountFailed: (errorMessage: string): CreateUserFailedAction => ({
        type: userConstants.CREATE_USER_FAILED,
        errorMessage
    }),

    clearCreateAccountErrors: (): ClearCreateUserErrorsAction => ({
        type: userConstants.CLEAR_CREATE_USER_ERRORS,
    }),

    resetPassword: (email: string): ResetPasswordAction => ({
        type: userConstants.RESET_PASSWORD,
        payload: { email }
    }),

    resetPasswordSuccess: (): ResetPasswordSuccessAction => ({
        type: userConstants.RESET_PASSWORD_SUCCESS
    }),

    resetPasswordFailed: (errorMessage: string): ResetPasswordFailedAction => ({
        type: userConstants.RESET_PASSWORD_FAILED,
        errorMessage
    }),

    resetPasswordClearState: (): ResetPasswordClearStateAction => ({
        type: userConstants.RESET_PASSWORD_CLEAR_LOADING_STATE
    }),

    resetPasswordConfirm: (token: string, password: string): ResetPasswordConfirmAction => ({
        type: userConstants.RESET_PASSWORD_CONFIRM,
        payload: { token, password }
    }),

    resetPasswordConfirmSuccess: (): ResetPasswordConfirmSuccessAction => ({
        type: userConstants.RESET_PASSWORD_CONFIRM_SUCCESS
    }),

    resetPasswordConfirmFailed: (errorMessage: string): ResetPasswordConfirmFailedAction => ({
        type: userConstants.RESET_PASSWORD_CONFIRM_FAILED,
        errorMessage
    }),

    resetPasswordConfirmClearState: (): ResetPasswordConfirmClearStateAction => ({
        type: userConstants.RESET_PASSWORD_CONFIRM_CLEAR_LOADING_STATE
    }),

    updateAccount: (account_handle: string, accountUpdate: IAccountUpdate): UpdateAccountAction => ({
        type: userConstants.UPDATE_ACCOUNT,
        payload: {
            account_handle,
            accountUpdate,
        }
    }),

    updateAccountSuccess: (account: IAccount): UpdateAccountSuccessAction => ({
        type: userConstants.UPDATE_ACCOUNT_SUCCESS,
        payload: { account }
    }),

    updateAccountFailure: (errorMessage: string): UpdateAccountFailedAction => ({
        type: userConstants.UPDATE_ACCOUNT_FAILURE,
        errorMessage
    }),

    updateAccountClearLoadingState: (): UpdateAccountClearLoadingStateAction => ({
        type: userConstants.UPDATE_ACCOUNT_CLEAR_LOADING_STATE
    }),

    changeUserEmail: (email: string): ChangeUserEmailAction => ({
        type: userConstants.CHANGE_EMAIL,
        payload: { email }
    }),

    changeUserEmailSuccess: (payload: IUserWithAccountsDict): ChangeUserEmailSuccessAction => ({
        type: userConstants.CHANGE_EMAIL_SUCCESS,
        payload,
    }),

    changeUserEmailFailure: (errorMessage: string): ChangeUserEmailFailureAction => ({
        type: userConstants.CHANGE_EMAIL_FAILURE,
        errorMessage,
    }),

    changeUserEmailClearLoadingState: (): ChangeUserEmailClearLoadingStateAction => ({
        type: userConstants.CHANGE_EMAIL_CLEAR_LOADING_STATE,
    }),

    requestVerificationEmail: (): RequestVerificationEmail => ({
        type: userConstants.REQUEST_VERIFICATION_EMAIL,
    }),

    verifyEmail: (uuid: string, token: string): VerifyEmailAction => ({
        type: userConstants.VERIFY_EMAIL,
        payload: { uuid, token }
    })
};

export default userActions;
