import * as React from 'react';
import MinimizedAppCard from './MinimizedAppCard';
import { Classes, Drawer, DrawerSize, Position } from '@blueprintjs/core';
import { IJobWrapper } from '../features/appRun/types';
import { JobUuid } from '@biolibtech/biolib-js';

interface IProps {
    isOpen: boolean;
    jobWrapperArray: IJobWrapper[];

    onClose: () => void;
    onOpenJob: (jobId: JobUuid) => void;
}

const MinimizedAppsDrawer: React.FC<IProps> = ({ isOpen, jobWrapperArray, onClose, onOpenJob }) => (
    <Drawer
        icon='applications'
        onClose={onClose}
        isOpen={isOpen}
        title='Minimized Apps'
        size={DrawerSize.SMALL}
        position={Position.LEFT}
    >
        <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>
                {jobWrapperArray.map((jobWrapper, index) => (
                    <MinimizedAppCard key={index} onClick={onOpenJob} jobWrapper={jobWrapper}/>
                ))}
            </div>
        </div>
        <div className={Classes.DRAWER_FOOTER}/>
    </Drawer>
);

export default MinimizedAppsDrawer;
