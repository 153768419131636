import * as React from 'react';
import GitHubSsoButton from './GitHubSsoButton';
import HrWithOr from './HrWithOr';
import userFlowSlice from '../features/userFlow/userFlowSlice';
import { CustomInput, CustomPasswordInput, SubmitButton, validateEmail } from './common/formElements';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { Icon, Spinner } from '@blueprintjs/core';
import { ILoadingState, navigateWindow } from '../utils';
import { Link } from 'gatsby';
import { userActions } from '../state/user/userActions';
import config from '../config';

interface ISignInProps {
    gitHubSignInLoadingState: ILoadingState;
    isSignedIn: boolean;
    isSignInPasswordFieldShown: boolean;
    signInLoadingState: ILoadingState;
    enterpriseSignInLoadingState: ILoadingState;

    gitHubSignIn: typeof userFlowSlice.actions.gitHubSignIn;
    clearSignInErrors: typeof userActions.clearSignInErrors;
    signIn: typeof userActions.signIn;
    enterpriseSignIn: typeof userFlowSlice.actions.enterpriseSignIn;

    // Callbacks for the AppOverlay
    onNavigateAway?: () => void;
    onClickSignUp?: () => void;
}

const initialValues = { email: '', password: '' };

export default class SignIn extends React.Component<ISignInProps> {

    public componentWillUnmount(): void {
        this.props.clearSignInErrors();
    }

    public componentDidMount(): void {
        if (this.props.isSignedIn && !this.props.onNavigateAway) {
            navigateWindow('/');
        }
    }

    public render(): React.ReactNode {
        const {
            isSignInPasswordFieldShown,
            signInLoadingState,
            enterpriseSignInLoadingState,
            gitHubSignInLoadingState,
        } = this.props;

        if (gitHubSignInLoadingState.isLoading) {
            return (
                <div style={{ paddingTop: 2 }}>
                    <h1 className='subtitle is-size-3 has-text-centered'>Sign in to BioLib</h1>
                    <Spinner/>
                </div>
            );
        }

        return (
            <Formik
                initialValues={initialValues}
                validate={this.validate}
                onSubmit={this.onSubmit}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({ values }) => (
                    <Form method='POST' noValidate={true}>
                        <h1 className='subtitle is-size-3 has-text-centered'>Sign in to BioLib</h1>
                        {config.isEnterprise ? null :
                            <>
                                <GitHubSsoButton githubSignIn={this.props.gitHubSignIn}/>
                                <HrWithOr/>
                            </>
                        }
                        <Field name='email' component={EmailInput}/>
                        {isSignInPasswordFieldShown ?
                            <>
                                <b>
                                    Password
                                    <Link
                                        className='is-pulled-right'
                                        onClick={this.props.onNavigateAway}
                                        tabIndex={-1}
                                        to={`/reset-password/?email=${encodeURIComponent(values.email)}`}
                                    >
                                        Forgot password?
                                    </Link>
                                </b>
                                <Field name='password' component={PasswordInput}/>
                            </> : null
                        }
                        <p className='has-text-danger is-size-7' style={{ marginBottom: '10px' }}>
                            {signInLoadingState.errorMessage}
                        </p>
                        <SubmitButton
                            isSubmitting={signInLoadingState.isLoading || enterpriseSignInLoadingState.isLoading}
                            text='Sign in with Email'
                            fill={true}
                        />
                        <hr/>
                        <p className='has-text-centered'>
                            New to BioLib?{' '}
                            {this.props.onClickSignUp ?
                                <a onClick={this.props.onClickSignUp}>
                                    Create an account <Icon icon='chevron-right'/>
                                </a> : <Link to='/sign-up/'>Create an account<Icon icon='chevron-right'/></Link>
                            }
                        </p>
                    </Form>
                )}
            </Formik>
        )
    }

    private validate = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<typeof initialValues> = {};
        const emailError = validateEmail(values.email);
        if (emailError) {
            errors.email = emailError;
        }
        return errors;
    }

    private onSubmit = ({ email, password }: typeof initialValues): void => {
        if (!this.props.isSignInPasswordFieldShown) {
            this.props.enterpriseSignIn({ email });
        } else {
            // TODO: Re-attempt saml2 sign in if email has changed
            this.props.signIn(email, password);
        }
    }
}

const EmailInput: React.FC<FieldProps> = CustomInput({
    autoFocus: true,
    label: 'Email',
    leftIcon: 'envelope',
    required: true,
    type: 'email',
});

const PasswordInput: React.FC<FieldProps> = CustomPasswordInput({ autoFocus: true });
