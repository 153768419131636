import React from 'react';
import clsx from 'clsx';
import BioLibLogoWhite from '../../images/logos/biolib_logo_light.svg';
import BioLibLogoColor from '../../images/logos/biolib_logo_color.svg';
import BioLibIcon from '../../images/logos/biolib_logo_color_icon.svg';
import enterpriseReplace from '../../enterpriseReplace.json';
import SearchField from '../../components/SearchField';
import { Link } from 'gatsby';
import { Icon, MenuDivider, Popover } from '@blueprintjs/core';
import { Container } from '../../components/common';
import { IAccount } from '@biolibtech/biolib-js';
import NavbarLink from './NavbarLink';
import MenuLink from './MenuLink';
import UserMenu from './UserMenu';
import config from '../../config';

interface IProps {
    account?: IAccount;
    isBgDark: boolean;
    isTeamSubdomain: boolean;
    isFrontPage: boolean;
    isMinimal: boolean;
    isSignedIn: boolean;
    isStateInitialized: boolean;
    isSearchDisabled: boolean;
}

interface IState {
    isMobileMenuOpen: boolean;
    isUserDropdownOpen: boolean;
}

export default class Navbar extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isMobileMenuOpen: false,
            isUserDropdownOpen: false,
        };
    }

    public render() {
        const {
            account,
            isBgDark,
            isFrontPage,
            isMinimal,
            isSearchDisabled,
            isSignedIn,
            isStateInitialized,
            isTeamSubdomain,
        } = this.props;
        const { isMobileMenuOpen, isUserDropdownOpen } = this.state;

        const handleResultsClick = () => {
            const customEvent = new Event('resultsTabClick');
            window.dispatchEvent(customEvent);
        };

        const SignInOrUpButton: React.FC = () => (
            <NavbarLink
                isSmall={isMinimal}
                className='hidden md:block'
                text={isFrontPage && !isBgDark && !config.isEnterprise ? 'Sign up' : 'Sign in'}
                path={isFrontPage && !isBgDark && !config.isEnterprise ? '/sign-up/' : '/sign-in/'}
                isBgDark={isBgDark}
                isCallToAction={true}
                useFullPath={isTeamSubdomain}
            />
        );

        const NavbarDesktopLinks: React.FC = () => (
            <div className='hidden md:block flex items-center md:ml-6'>
                <div className='flex items-baseline space-x-2'>
                    <NavbarLink text='Docs' path='/docs/' isBgDark={isBgDark} useFullPath={isTeamSubdomain}/>
                    <NavbarLink
                        text='Explore'
                        path='/explore/'
                        isBgDark={isBgDark}
                        useFullPath={isTeamSubdomain}
                    />
                    <NavbarLink
                        text='Results'
                        path='/results/'
                        isBgDark={isBgDark}
                        useFullPath={isTeamSubdomain}
                        onClick={handleResultsClick}
                    />
                </div>
            </div>
        );

        const ConditionalLink: React.FC<{ to: string; children: React.ReactNode; }> = (props) => {
            if (isTeamSubdomain) {
                return (<a href={`https://biolib.com${props.to}`}>{props.children}</a>);
            }

            if (props.to === '/' && !isSignedIn) {
                return (<a href='/'>{props.children}</a>);
            }

            return (<Link to={props.to}>{props.children}</Link>);
        };

        return (
            <nav className='fixed top-0 w-full z-30 bg-gradient-dark print:hidden'>
                <div className={clsx(
                    'transition duration-500 ease-in-out',
                    { 'bg-white shadow-md': !isBgDark }
                )}>
                    <Container className={clsx(
                        'flex items-center justify-between transition duration-500 ease-in-out',
                        { 'transform translate-y-4': isBgDark }
                    )}>
                        <div className={clsx('flex items-center space-x-4 h-16', { 'md:h-12': isMinimal })}>
                            <div className='flex-shrink-0'>
                                <ConditionalLink to='/'>
                                    <img
                                        alt={config.isEnterprise ? 'Logo' : 'BioLib Logo'}
                                        className='h-8'
                                        draggable={false}
                                        src={config.isEnterprise ? enterpriseReplace.logo :
                                            isMinimal ? BioLibIcon : isBgDark ? BioLibLogoWhite : BioLibLogoColor
                                        }
                                    />
                                </ConditionalLink>
                            </div>
                            {isBgDark ? null :
                                <div className='flex-auto hidden md:block'>
                                    <SearchField
                                        large={false}
                                        placeholder={!isMinimal ? 'Search' : `Search ${config.stackName}`}
                                        isSearchButtonHidden={true}
                                        isTeamSubdomain={isTeamSubdomain}
                                        isDisabled={isSearchDisabled}
                                        className={clsx(
                                            'transition-opacity duration-400 ease-in-out',
                                            {
                                                'opacity-100': !isBgDark,
                                                'opacity-0': isBgDark,
                                            },
                                        )}
                                    />
                                </div>
                            }
                            <NavbarDesktopLinks/>
                        </div>
                        <div className={clsx('flex items-center space-x-4 h-16', { 'md:h-12': isMinimal })}>
                            {isFrontPage && !isSignedIn ? <SignInOrUpButton/> : null}
                            {!isFrontPage && typeof window !== 'undefined' && !account && !isTeamSubdomain
                            && isStateInitialized ? <SignInOrUpButton/> : null}
                            {!account ? null :
                                <span className='hidden md:block ml-10 flex items-baseline space-x-4'>
                                    <Popover
                                        usePortal={false}
                                        position='bottom-right'
                                        isOpen={isUserDropdownOpen}
                                        canEscapeKeyClose={true}
                                        onClose={this.closeUserDropdown}
                                        content={
                                            <UserMenu
                                                isTeamSubdomain={isTeamSubdomain}
                                                account={account}
                                                onClick={this.closeUserDropdown}
                                            />
                                        }
                                    >
                                        <button
                                            className='flex items-center space-x-2 text-current font-medium px-3 py-2
                                        rounded-md text-base hover:no-underline hover:text-cyan-700 hover:bg-gray-100
                                        max-w-xs'
                                            aria-haspopup='true'
                                            onClick={() => this.setState({ isUserDropdownOpen: !isUserDropdownOpen })}
                                        >
                                            <Icon icon='user' iconSize={20}/>
                                            <span className='sr-only'>Open user menu</span>
                                            <span className='truncate'>{account.display_name.split(/\s+/)[0]}</span>
                                            <Icon
                                                icon='chevron-down'
                                                style={{ marginTop: 2 }}
                                                className={clsx('transition duration-200 ease-in-out',
                                                    { 'transform rotate-180': isUserDropdownOpen }
                                                )}
                                            />
                                        </button>
                                    </Popover>
                                </span>
                            }
                        </div>
                        <div className='-mr-2 flex md:hidden'>
                            <button
                                onClick={() => this.setState({ isMobileMenuOpen: !isMobileMenuOpen })}
                                className={clsx(
                                    'inline-flex items-center justify-center p-2 rounded-md',
                                    'bg-default bg-opacity-10 focus:outline-none focus:ring-2',
                                    {
                                        'text-gray-50 hover:text-cyan-700 hover:bg-gray-100 focus:ring-gray-50':
                                        isBgDark,
                                        'text-current hover:bg-cyan-700 hover:text-white focus:ring-gray-400':
                                            !isBgDark,
                                    },
                                )}
                            >
                                <span className='sr-only'>Open main menu</span>
                                {isMobileMenuOpen ?
                                    <Icon icon='cross' iconSize={28}/> : <Icon icon='menu' iconSize={28}/>}
                            </button>
                        </div>
                    </Container>
                    <div className={clsx(
                        'bg-default md:hidden origin-top-right absolute right-0 w-full sm:w-full -mt-1.5 z-30',
                        'rounded-md shadow-lg transition ease-out duration-200',
                        {
                            'transform scale-0 translate-x-400 translate-y-400 opacity-0': !isMobileMenuOpen,
                            'transform scale-100 translate-x-0 translate-y-0 opacity-100': isMobileMenuOpen,
                            'transform translate-y-4': isBgDark,
                        }
                    )}>
                        <Container className='font-sans space-y-2 py-6'>
                            <SearchField
                                clearStateToggle={isMobileMenuOpen}
                                isSearchButtonHidden={true}
                                isTeamSubdomain={isTeamSubdomain}
                                large={false}
                                onSearch={this.toggleMobileMenu}
                                placeholder='Search'
                                className='mx-2 mb-1'
                            />
                            <MenuLink
                                text='Explore'
                                to='/explore/'
                                isLarge={true}
                                onClick={this.toggleMobileMenu}
                                useFullPath={isTeamSubdomain}
                            />
                            <MenuLink
                                text='Docs'
                                to='/docs/'
                                isLarge={true}
                                onClick={this.toggleMobileMenu}
                                useFullPath={isTeamSubdomain}
                            />
                            <MenuLink
                                text='Results'
                                to='/results/'
                                isLarge={true}
                                onClick={this.toggleMobileMenu}
                                useFullPath={isTeamSubdomain}
                            />
                            <MenuDivider/>
                            {account ?
                                <>
                                    <p className='text-sm font-bold px-1 my-1 truncate'>
                                        {account ? account.display_name.split(/\s+/)[0] : ''}
                                    </p>
                                    <UserMenu
                                        account={account}
                                        isTeamSubdomain={isTeamSubdomain}
                                        isLarge={true}
                                        onClick={this.toggleMobileMenu}
                                    />
                                </> :
                                <MenuLink
                                    text='Sign in'
                                    to='/sign-in/'
                                    onClick={this.toggleMobileMenu}
                                    useFullPath={isTeamSubdomain}
                                />
                            }
                        </Container>
                    </div>
                </div>
            </nav>
        );
    }

    private toggleMobileMenu = () => {
        this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
    };

    private closeUserDropdown = () => {
        this.setState({ isUserDropdownOpen: false });
    };
}
