import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccount, IAccountAsAdmin, IAccountAsMember, ISlimApp } from '@biolibtech/biolib-js';
import { IPaginationState } from '../../state';

const EmptyPaginationState: IPaginationState<any> = {
    countPages: 0,
    countPerPage: 0,
    countTotal: 0,
    currentPage: 0,
    pages: {},
};

type AccountProfile = | IAccount | IAccountAsMember | IAccountAsAdmin;

export interface IAccountProfileState {
    account: null | AccountProfile;
    paginatedApps: IPaginationState<ISlimApp>;
    isLoading: {
        account: boolean;
        apps: boolean;
    }
}

const initialState: IAccountProfileState = {
    account: null,
    paginatedApps: EmptyPaginationState,
    isLoading: {
        account: true,
        apps: true,
    },
}

const accountProfileSlice = createSlice({
    name: 'accountProfile',
    initialState,
    reducers: {
        fetchAccount(state, action: PayloadAction<{ account_handle: string; }>) {
            state.isLoading.account = true;
        },
        fetchAccountSuccess(state, action: PayloadAction<{ account: AccountProfile }>) {
            state.isLoading.account = false;
            state.account = action.payload.account;
        },
        fetchAccountFailure(state) {
            state.isLoading.account = false;
        },
        fetchApps(state, action: PayloadAction<{ account_handle: string; }>) {
            state.isLoading.apps = true;
        },
        fetchAppsSuccess(state, action: PayloadAction<{ paginatedApps: IPaginationState<ISlimApp> }>) {
            state.isLoading.apps = false;
            state.paginatedApps = action.payload.paginatedApps;
        },
        fetchAppsFailure(state) {
            state.isLoading.apps = false;
        },
        appsChangePage(state, action: PayloadAction<{ newPageId: number }>) {
            // no operation here, handled in saga
        },
        setPaginatedApps(state, action: PayloadAction<{ paginatedApps: IPaginationState<ISlimApp> }>) {
            state.paginatedApps = action.payload.paginatedApps;
        },
        clearState() {
            return initialState;
        }
    }
});

export default accountProfileSlice;
