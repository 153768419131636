import { call, fork, put, takeLatest } from 'redux-saga/effects';
import appCreateSlice from './slice';
import { handleError } from '../../utils';
import { BioLibSingleton } from '@biolibtech/biolib-js';
import { navigate } from 'gatsby';

function* watchAppCreate() {
    yield takeLatest(appCreateSlice.actions.createApp, function* (action) {
        if (appCreateSlice.actions.createApp.match(action)) {
            try {
                const { uri } = yield BioLibSingleton.get().app.create(action.payload);
                yield navigate(`/${uri}/`);
                yield put(appCreateSlice.actions.createAppSuccess());
            } catch (error) {
                yield call(handleError, {
                    error,
                    actionToDispatch: appCreateSlice.actions.createAppFailure,
                    friendlyErrorMessageToPrepend: 'Failed to create application',
                    showToast: true,
                });
            }
        }
    });
}

export default function* appCreateSaga() {
    yield fork(watchAppCreate);
}
